import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'

const fetchProductCreateValidate = createAsyncThunk(
  'product/createValidate',
  async ({ data }, { rejectWithValue }) => {
    try {
      const form = new FormData()
      form.append('file', data[0])

      const response = await productAdminServices.validateCreate(form)
      const valid = response.ok ? response : await response.json()
      if (valid.error || !response.ok) {
        return rejectWithValue(valid?.error || 'Error al validar el excel')
      }

      return valid.ok
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchProductCreateValidate
