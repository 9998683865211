import { createSelector } from '@reduxjs/toolkit'

const getStateSeller = state => state.seller.detail

export const getSellerById = createSelector(
  [getStateSeller],
  seller => seller
)

export const isSellerKitchenCenter = createSelector(
  [getStateSeller],
  seller =>
    seller?.slug === 'seller-kitchen-center' ||
    seller?.name === 'Kitchen Center' ||
    seller?.tag === 'seller_Kitchen Center' ||
    seller?.tag === 'Kitchen Center' ||
    seller?.slug === 'kitchen-center' ||
    false
)
