import { useMemo } from 'react'
import { node, string } from 'prop-types'
import {
  ThemeProvider as ThemeProviderUI,
  StyledEngineProvider,
} from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import CssBaseline from '@mui/material/CssBaseline'

import theme from './theme'

const ThemeProvider = ({ children }) => {
  const defaultTheme = useMemo(() => theme('light'), [])

  return (
    <ThemeProviderUI theme={defaultTheme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <StyledThemeProvider theme={defaultTheme}>
          {children}
        </StyledThemeProvider>
      </StyledEngineProvider>
    </ThemeProviderUI>
  )
}
ThemeProvider.propTypes = {
  children: node.isRequired,
  mode: string,
}
export default ThemeProvider
