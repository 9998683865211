const normalizeAuth = ({
  displayName,
  email,
  photoURL,
  permissions,
  uid,
  firstLogin,
}) => {
  const userPermissionsAndRoles = permissions?.find(
    permission =>
      permission?.appId?.toString() ===
      process.env.REACT_APP_APP_ID.toString()
  )
  return {
    firstLogin,
    displayName,
    email,
    photoURL,
    uid,
    role: userPermissionsAndRoles?.role,
    slugSeller: userPermissionsAndRoles?.slugSeller,
    permission: userPermissionsAndRoles,
  }
}
export default normalizeAuth
