import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import fetchOrders from './fetchOrders'
import fetchUpdateDocument from './fetchUpdateDocument'
import fetchDownloadDocument from './fetchDownloadDocument'
import fetchDeleteDocument from './fetchDeleteDocument'

export const orderAdapter = createEntityAdapter({
  selectId: order => order?.id,
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
})

const getUpdateDocumentAction = state => state.order?.list?.updateDocument
export const getUpdateDocument = createSelector(
  [getUpdateDocumentAction],
  state => {
    return state
  }
)
const getDownloadDocumentAction = state =>
  state.order?.list?.downloadDocument
export const getDownloadDocument = createSelector(
  [getDownloadDocumentAction],
  state => state
)
const getDeleteDocumentAction = state => state.order?.list?.deleteDocument
export const getDeleteDocument = createSelector(
  [getDeleteDocumentAction],
  state => state
)
export const orderSlice = createSlice({
  name: 'orders',
  initialState: orderAdapter.getInitialState({
    pagination: {
      currentPagination: 0,
      perPagination: 25,
      paginationTotalCount: 0,
    },
    status: 'idle',
    id: [],
    entities: {},
    updateDocument: {
      status: 'idle',
    },
    downloadDocument: {
      status: 'idle',
    },
    deleteDocument: {
      status: 'idle',
    },
  }),
  reducers: {
    updateOrders: orderAdapter.setAll,
    deleteIdle: state => {
      state.deleteDocument.status = 'idle'
    },
    downloadIdle: state => {
      state.downloadDocument.status = 'idle'
    },
    updateDocumentList: (state, { payload }) => {
      orderAdapter.updateOne(state, {
        id: payload,
        changes: {
          documentName: payload,
        },
      })
    },
    deleteDocumentList: (state, { payload }) => {
      orderAdapter.updateOne(state, {
        id: payload,
        changes: {
          documentName: null,
        },
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOrders.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchOrders.rejected, (state, { meta }) => {
      state.status = meta.aborted ? 'loading' : 'error'
    })
    builder.addCase(fetchOrders.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.pagination = payload.pagination
      orderAdapter.setAll(state, payload.orders)
    })
    builder.addCase(fetchUpdateDocument.pending, state => {
      state.updateDocument.status = 'loading'
    })
    builder.addCase(fetchUpdateDocument.rejected, (state, { meta }) => {
      state.updateDocument.status = meta.aborted ? 'loading' : 'error'
    })
    builder.addCase(
      fetchUpdateDocument.fulfilled,
      (state, { payload }) => {
        state.updateDocument.status = 'finished'
        orderAdapter.updateOne(state, {
          id: payload.data,
          changes: {
            documentName: payload.data,
          },
        })
      }
    )

    builder.addCase(fetchDownloadDocument.pending, state => {
      state.downloadDocument.status = 'loading'
    })
    builder.addCase(fetchDownloadDocument.rejected, (state, { meta }) => {
      state.downloadDocument.status = meta.aborted ? 'loading' : 'error'
    })
    builder.addCase(fetchDownloadDocument.fulfilled, state => {
      state.downloadDocument.status = 'finished'
    })
    builder.addCase(fetchDeleteDocument.pending, state => {
      state.deleteDocument.status = 'loading'
    })
    builder.addCase(fetchDeleteDocument.rejected, (state, { meta }) => {
      state.deleteDocument.status = meta.aborted ? 'loading' : 'error'
    })
    builder.addCase(
      fetchDeleteDocument.fulfilled,
      (state, { payload }) => {
        state.deleteDocument.status = 'finished'
        orderAdapter.updateOne(state, {
          id: payload,
          changes: {
            documentName: null,
          },
        })
      }
    )
  },
})

const reducer = orderSlice.reducer
export const {
  deleteIdle,
  downloadIdle,
  updateDocumentList,
  deleteDocumentList,
} = orderSlice.actions
export default reducer
