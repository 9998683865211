import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchProductExport from './fetchProductExport'

export const productExportAdapter = createEntityAdapter()

export const productExportSlice = createSlice({
  name: 'product/export',
  initialState: productExportAdapter.getInitialState({
    status: 'idle',
    url: {},
    errors: null,
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProductExport.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchProductExport.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errors = payload
    })
    builder.addCase(fetchProductExport.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.url = payload
    })
  },
})

const reducer = productExportSlice.reducer
export default reducer
