import { createSlice } from '@reduxjs/toolkit'
import persistentStore from '../../../data/persistentStore'
import sortString from '../../utils/sortString'
import fetchSeller from './fetchSeller'

export const sellerSlice = createSlice({
  name: 'seller',
  initialState: {
    status: 'idle',
    data: persistentStore.isSeller()
      ? persistentStore.getSeller()
      : { brands: [], tagSeller: '' },
    brandOnlyNameList: persistentStore.isBrands()
      ? persistentStore.getBrands()
      : [],
    brandsAll: persistentStore.isSeller()
      ? persistentStore.getSeller()?.brandsAll
      : [],
    name: persistentStore.isSeller()
      ? persistentStore.getSeller()?.name
      : '',
    tag: persistentStore.isSeller()
      ? persistentStore.getSeller()?.tag
      : '',
    slug: persistentStore.isSeller()
      ? persistentStore.getSeller()?.slug
      : '',
  },
  reducers: {},
  extraReducers: {
    [fetchSeller.pending]: state => {
      state.status = 'loading'
    },
    [fetchSeller.rejected]: state => {
      state.status = 'error'
    },
    [fetchSeller.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      state.data = { ...payload, brandsAll: payload?.brands }

      state.brandOnlyNameList = payload?.brands
        ?.map(({ brandName }) => brandName)
        ?.sort(sortString)
      state.brandsAll = payload?.brands

      state.name = payload?.nameSeller
      state.tag = payload?.tagSeller
      state.slug = payload?.slugSeller

      persistentStore.setSeller(state?.data)
      persistentStore.setBrands(state?.brandOnlyNameList)
    },
  },
})

const reducer = sellerSlice.reducer
export default reducer
