import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import subOrderService from '../../../data/services/subOrderService'
import normalizeOrder from '../orders/normalizeOrder'

const fechOrderDetail = createAsyncThunk(
  'order/detail',
  async ({ parentId = undefined }, { signal, rejectWithValue }) => {
    if (!parentId) {
      return
    }

    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })

    try {
      const data = await subOrderService.orderDetail({
        parentId,
        cancelToken: source.token,
      })

      const orderDetailWithAfterSale = pipe(map(normalizeOrder))(data)

      return orderDetailWithAfterSale
    } catch (error) {
      return rejectWithValue(JSON.stringify(error))
    }
  }
)
export default fechOrderDetail
