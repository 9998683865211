import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Popover } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Text from '../../atoms/Typography/Text'
import Tooltip from '../ToolTip/ToolTip'

const ButtonDownloaStyled = styled(IconButton)`
  border: solid 1px #d1d0d0;
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 10px;
  height: 40px;
  &:disabled {
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'not-allowed'};
    pointer-events: auto;
  }
`

const ButtonOptionDropdown = styled(Button)`
  padding-left: 10%;
  color: ${({ theme }) => theme.palette.text.primary};
`
const Container = styled('div')`
  margin-left: 10px;
`

const DropdownContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 180px;
  padding-top: 7px;
`

const StyledText = styled(Text)`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-align: left;
  text-transform: none;
  padding-bottom: 7px;
`
const ButtonDownloadOptions = ({ options, disabled, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      {options.length > 0 ? (
        <Container>
          <Tooltip
            title={
              !disabled
                ? 'Descargar planilla con filtros aplicados'
                : 'Para descargar la planilla, aplica al menos un filtro.'
            }
          >
            <ButtonDownloaStyled
              aria-describedby={id}
              variant="contained"
              disabled={disabled}
              onClick={handleClick}
            >
              <FileDownloadOutlinedIcon />
            </ButtonDownloaStyled>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <DropdownContainer>
              {options.map(option => {
                return (
                  <ButtonOptionDropdown
                    variant="text"
                    size="large"
                    key={option.label}
                    onClick={option.onClick}
                  >
                    <StyledText>{option.label}</StyledText>
                  </ButtonOptionDropdown>
                )
              })}
            </DropdownContainer>
          </Popover>
        </Container>
      ) : (
        <ButtonDownloaStyled
          variant="contained"
          disabled={disabled}
          {...props}
        >
          <FileDownloadOutlinedIcon />
        </ButtonDownloaStyled>
      )}
    </>
  )
}

ButtonDownloadOptions.propTypes = {
  isViewer: PropTypes.bool,
  options: PropTypes.arrayOf({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  handleClickOptionOne: PropTypes.func,
  handleClickOptionTwo: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ButtonDownloadOptions
