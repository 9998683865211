import Typography from '@mui/material/Typography'
import styled from 'styled-components'

const Text = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  font-style: normal;
  font-weight: normal;
  .Mui-disabled {
    cursor: not-allowed;
  }
`

export default Text
