import { verifyPasswordResetCode } from 'firebase/auth'
import { useEffect, useCallback } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import auth from '../../../../data/firebase/auth'
import Logo from '../../../assets/logoWeb.webp'
import Title from '../../atoms/Typography/Title'
import PasswordForm from '../../organisms/PasswordForm'

const SpaceAround = styled('div')`
  align-self: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`
const Img = styled('img')`
  @media only screen and (max-width: 1100px) {
    width: 70%;
    margin-bottom: 20px;
  }
`

const TitleLogo = styled(Title)`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.04em;
  text-align: center;
  margin-bottom: 15px;
  align-self: flex-start;
  padding-left: 10%;
  @media only screen and (max-width: 1100px) {
    align-self: center;
    padding-left: 0;
  }
`

const Line = styled('div')`
  width: 80%;
  border: 0.5px solid ${({ theme }) => theme.palette.text.primary};
`

const LogoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.text.primary};
  flex: 1.5;
  height: 100vh;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`
const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  margin-top: -15%;
  padding-bottom: 20%;
  width: 100%;
  @media only screen and (max-width: 1100px) {
    margin-top: 0;
    padding-bottom: 42px;
  }
`

const NewPassword = () => {
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const code = searchParams.get('oobCode')

  const verifyCode = useCallback(async () => {
    try {
      verifyPasswordResetCode(auth, code)
    } catch (_error) {
      return <Navigate to={`/expirado?email=${email}`} replace />
    }
  }, [email, code])

  useEffect(() => {
    const subcribe = verifyCode()
    return subcribe
  }, [verifyCode])

  return (
    <SpaceAround>
      <LogoContainer>
        <TitleContainer>
          <TitleLogo variant="h1">Seller Center</TitleLogo>
          <Line />
        </TitleContainer>
        <Img alt="search" src={Logo} height="30%" />
      </LogoContainer>
      <PasswordForm />
    </SpaceAround>
  )
}

export default NewPassword
