import PropTypes from 'prop-types'
import TooltipMUI from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import styled from 'styled-components'

const TooltipStyled = styled(TooltipMUI)`
  width: auto;
  color: ${({ theme }) => theme.palette.text.primary};
  padding-right: 11px;
  text-transform: capitalize;
  justify-content: space-between;
  height: 35px;
  left: 5px;
  font-size: 14px;
  border-radius: 4px;
  margin: 0;
`

const Tooltip = ({ title, children, disabled, ...props }) => {
  return disabled ? (
    children
  ) : (
    <TooltipStyled
      TransitionComponent={Zoom}
      disabled={disabled}
      title={title}
      {...props}
    >
      {children}
    </TooltipStyled>
  )
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}
Tooltip.defaultProps = {
  disabled: false,
}
Tooltip.displayName = 'Tooltip'
export default Tooltip
