import styled from 'styled-components'

const TopPadding = styled('div')`
  padding-top: 30px;
  @media only screen and (max-width: 700px) {
    padding-top: 0.5rem;
  }
`

export default TopPadding
