import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import Cell from '../Cell/Cell'
import { forwardRef } from 'react'

// Crear un componente Link con forwardRef para usarlo con Material-UI
const LinkBehavior = forwardRef((props, ref) => (
  <RouterLink {...props} ref={ref} />
))
LinkBehavior.displayName = 'LinkBehavior'
const CellTooltipNavigate = ({ children, to, state }) => {
  return (
    <Cell
      component={LinkBehavior}
      to={to}
      state={state}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </Cell>
  )
}

CellTooltipNavigate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
  to: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.any,
  ]),
  state: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
}
CellTooltipNavigate.displayName = 'CellTooltipNavigate'
export default CellTooltipNavigate
