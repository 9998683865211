import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchProductCreateExport from './fetchProductCreateExport'

export const productCreateExportAdapter = createEntityAdapter()

export const productCreateExportSlice = createSlice({
  name: 'product/createExport',
  initialState: productCreateExportAdapter.getInitialState({
    status: 'idle',
    url: {},
    errors: null,
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProductCreateExport.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchProductCreateExport.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errors = payload
    })
    builder.addCase(fetchProductCreateExport.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.url = payload
    })
  },
})

const reducer = productCreateExportSlice.reducer
export default reducer
