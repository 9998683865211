import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchProductValidate from './fetchProductValidate'

export const productValidateAdpater = createEntityAdapter()

export const productValidateSlice = createSlice({
  name: 'product/validate',
  initialState: productValidateAdpater.getInitialState({
    status: 'idle',
    ok: false,
    errors: null,
  }),
  reducers: {
    resetProductValidate(state) {
      state.status = 'idle'
      state.errors = null
      state.ok = false
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProductValidate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(
      fetchProductValidate.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.errors = payload
      }
    )
    builder.addCase(
      fetchProductValidate.fulfilled,
      (state, { payload }) => {
        state.status = 'finished'
        state.ok = payload
      }
    )
  },
})
export const { resetProductValidate } = productValidateSlice.actions
const reducer = productValidateSlice.reducer
export default reducer
