import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'

const fetchProductImport = createAsyncThunk(
  'product/import',
  async ({ data }, { rejectWithValue }) => {
    try {
      const form = new FormData()
      form.append('file', data[0])
      const response = await productAdminServices.import(form)
      if (response.error) {
        return rejectWithValue('Planilla no validada')
      }

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchProductImport
