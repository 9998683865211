import { useEffect } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import { useDispatch, useSelector } from 'react-redux'
import Logo from '../../atoms/Logo'
import Carousel from '../../molecules/Carousel/Carousel'

import {
  Center,
  Container,
  DescriptionContainer,
  InfoText,
  ItemText,
  LeftContainer,
  LogoContainer,
  OnboardingImage,
  RightContainer,
  StyledButton,
  SubTitleText,
  TitleText,
} from './onboarding.styles'

import { formatPhoneNumber } from '../../../../domain/adapters/phone/formatPhone'
import { useAuth } from '../../../hooks/useAuth'
import { getSellerById } from '../../../../domain/features/seller/getSeller'
import fetchSeller from '../../../../domain/features/seller/fetchSeller'

const OnboardingImg = require('../../../assets/onboarding/onboarding2.png')

const Onboarding2 = ({ navigate }) => {
  const dispatch = useDispatch()
  const { user } = useAuth()

  useEffect(() => {
    const promise = dispatch(fetchSeller({ slugSeller: user.slugSeller }))
    return () => {
      promise.abort()
    }
  }, [dispatch, user.slugSeller])

  const handleNavigate = () => {
    navigate('/onboarding/3')
  }

  const seller = useSelector(getSellerById)

  return (
    <Center>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Container>
        <LeftContainer>
          <SubTitleText>Datos de tu empresa</SubTitleText>
          <TitleText>
            {seller?.name ||
              capitalize(
                String(user?.slugSeller)
                  .replace('seller-', '')
                  .replaceAll('-', ' ')
              )}
          </TitleText>
          <DescriptionContainer>
            {seller?.rut && <ItemText>{String(seller?.rut)}</ItemText>}

            {seller?.email && <ItemText>{String(seller?.email)}</ItemText>}

            {seller?.phoneNumber && (
              <ItemText>{formatPhoneNumber(seller?.phoneNumber)}</ItemText>
            )}
          </DescriptionContainer>
          <InfoText>
            * Si necesitas cambiar los datos de tu empresa, por favor
            contáctanos por mail.
          </InfoText>
        </LeftContainer>
        <RightContainer>
          <OnboardingImage src={OnboardingImg} alt="onboarding" />
        </RightContainer>
      </Container>
      <StyledButton variant="contained" onClick={handleNavigate}>
        Entendido
      </StyledButton>
      <Carousel />
    </Center>
  )
}

Onboarding2.propTypes = {
  navigate: PropTypes.func,
}

export default Onboarding2
