const countries = [
  { code: 'AD', label: 'Andorra', phone: '376', flag: '🇦🇩' },
  {
    code: 'AE',
    label: 'Emiratos Árabes Unidos',
    phone: '971',
    flag: '🇦🇪',
  },
  { code: 'AF', label: 'Afganistán', phone: '93', flag: '🇦🇫' },
  { code: 'AG', label: 'Antigua y Barbuda', phone: '1-268', flag: '🇦🇬' },
  { code: 'AI', label: 'Anguila', phone: '1-264', flag: '🇦🇮' },
  { code: 'AL', label: 'Albania', phone: '355', flag: '🇦🇱' },
  { code: 'AM', label: 'Armenia', phone: '374', flag: '🇦🇲' },
  { code: 'AO', label: 'Angola', phone: '244', flag: '🇦🇴' },
  { code: 'AQ', label: 'Antártida', phone: '672', flag: '🇦🇶' },
  { code: 'AR', label: 'Argentina', phone: '54', flag: '🇦🇷' },
  { code: 'AS', label: 'Samoa Americana', phone: '1-684', flag: '🇦🇸' },
  { code: 'AT', label: 'Austria', phone: '43', flag: '🇦🇹' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    flag: '🇦🇺',
    suggested: true,
  },
  { code: 'AW', label: 'Aruba', phone: '297', flag: '🇦🇼' },
  { code: 'AX', label: 'Islas Åland', phone: '358', flag: '🇦🇽' },
  { code: 'AZ', label: 'Azerbaiyán', phone: '994', flag: '🇦🇿' },
  { code: 'BA', label: 'Bosnia y Herzegovina', phone: '387', flag: '🇧🇦' },
  { code: 'BB', label: 'Barbados', phone: '1-246', flag: '🇧🇧' },
  { code: 'BD', label: 'Bangladesh', phone: '880', flag: '🇧🇩' },
  { code: 'BE', label: 'Bélgica', phone: '32', flag: '🇧🇪' },
  { code: 'BF', label: 'Burkina Faso', phone: '226', flag: '🇧🇫' },
  { code: 'BG', label: 'Bulgaria', phone: '359', flag: '🇧🇬' },
  { code: 'BH', label: 'Baréin', phone: '973', flag: '🇧🇭' },
  { code: 'BI', label: 'Burundi', phone: '257', flag: '🇧🇮' },
  { code: 'BJ', label: 'Benín', phone: '229', flag: '🇧🇯' },
  { code: 'BL', label: 'San Bartolomé', phone: '590', flag: '🇧🇱' },
  { code: 'BM', label: 'Bermudas', phone: '1-441', flag: '🇧🇲' },
  { code: 'BN', label: 'Brunéi', phone: '673', flag: '🇧🇳' },
  { code: 'BO', label: 'Bolivia', phone: '591', flag: '🇧🇴' },
  { code: 'BR', label: 'Brasil', phone: '55', flag: '🇧🇷' },
  { code: 'BS', label: 'Bahamas', phone: '1-242', flag: '🇧🇸' },
  { code: 'BT', label: 'Bután', phone: '975', flag: '🇧🇹' },
  { code: 'BV', label: 'Isla Bouvet', phone: '47', flag: '🇧🇻' },
  { code: 'BW', label: 'Botsuana', phone: '267', flag: '🇧🇼' },
  { code: 'BY', label: 'Bielorrusia', phone: '375', flag: '🇧🇾' },
  { code: 'BZ', label: 'Belice', phone: '501', flag: '🇧🇿' },
  { code: 'CA', label: 'Canadá', phone: '1', flag: '🇨🇦', suggested: true },
  { code: 'CC', label: 'Islas Cocos (Keeling)', phone: '61', flag: '🇨🇨' },
  { code: 'CD', label: 'República del Congo', phone: '242', flag: '🇨🇬' },
  {
    code: 'CF',
    label: 'República Centroafricana',
    phone: '236',
    flag: '🇨🇫',
  },
  {
    code: 'CG',
    label: 'República Democrática del Congo',
    phone: '243',
    flag: '🇨🇩',
  },
  { code: 'CH', label: 'Suiza', phone: '41', flag: '🇨🇭' },
  { code: 'CI', label: 'Costa de Marfil', phone: '225', flag: '🇨🇮' },
  { code: 'CK', label: 'Islas Cook', phone: '682', flag: '🇨🇰' },
  { code: 'CL', label: 'Chile', phone: '56', flag: '🇨🇱' },
  { code: 'CM', label: 'Camerún', phone: '237', flag: '🇨🇲' },
  { code: 'CN', label: 'China', phone: '86', flag: '🇨🇳' },
  { code: 'CO', label: 'Colombia', phone: '57', flag: '🇨🇴' },
  { code: 'CR', label: 'Costa Rica', phone: '506', flag: '🇨🇷' },
  { code: 'CU', label: 'Cuba', phone: '53', flag: '🇨🇺' },
  { code: 'CV', label: 'Cabo Verde', phone: '238', flag: '🇨🇻' },
  { code: 'CW', label: 'Curazao', phone: '599', flag: '🇨🇼' },
  { code: 'CX', label: 'Isla de Navidad', phone: '61', flag: '🇨🇽' },
  { code: 'CY', label: 'Chipre', phone: '357', flag: '🇨🇾' },
  { code: 'CZ', label: 'República Checa', phone: '420', flag: '🇨🇿' },
  {
    code: 'DE',
    label: 'Alemania',
    phone: '49',
    flag: '🇩🇪',
    suggested: true,
  },
  { code: 'DJ', label: 'Yibuti', phone: '253', flag: '🇩🇯' },
  { code: 'DK', label: 'Dinamarca', phone: '45', flag: '🇩🇰' },
  { code: 'DM', label: 'Dominica', phone: '1-767', flag: '🇩🇲' },
  {
    code: 'DO',
    label: 'República Dominicana',
    phone: '1-809',
    flag: '🇩🇴',
  },
  { code: 'DZ', label: 'Argelia', phone: '213', flag: '🇩🇿' },
  { code: 'EC', label: 'Ecuador', phone: '593', flag: '🇪🇨' },
  { code: 'EE', label: 'Estonia', phone: '372', flag: '🇪🇪' },
  { code: 'EG', label: 'Egipto', phone: '20', flag: '🇪🇬' },
  { code: 'EH', label: 'Sahara Occidental', phone: '212', flag: '🇪🇭' },
  { code: 'ER', label: 'Eritrea', phone: '291', flag: '🇪🇷' },
  { code: 'ES', label: 'España', phone: '34', flag: '🇪🇸' },
  { code: 'ET', label: 'Etiopía', phone: '251', flag: '🇪🇹' },
  { code: 'FI', label: 'Finlandia', phone: '358', flag: '🇫🇮' },
  { code: 'FJ', label: 'Fiyi', phone: '679', flag: '🇫🇯' },
  { code: 'FK', label: 'Islas Malvinas', phone: '500', flag: '🇫🇰' },
  { code: 'FM', label: 'Micronesia', phone: '691', flag: '🇫🇲' },
  { code: 'FO', label: 'Islas Feroe', phone: '298', flag: '🇫🇴' },
  {
    code: 'FR',
    label: 'Francia',
    phone: '33',
    flag: '🇫🇷',
    suggested: true,
  },
  { code: 'GA', label: 'Gabón', phone: '241', flag: '🇬🇦' },
  { code: 'GB', label: 'Reino Unido', phone: '44', flag: '🇬🇧' },
  { code: 'GD', label: 'Granada', phone: '1-473', flag: '🇬🇩' },
  { code: 'GE', label: 'Georgia', phone: '995', flag: '🇬🇪' },
  { code: 'GF', label: 'Guayana Francesa', phone: '594', flag: '🇬🇫' },
  { code: 'GG', label: 'Guernsey', phone: '44', flag: '🇬🇬' },
  { code: 'GH', label: 'Ghana', phone: '233', flag: '🇬🇭' },
  { code: 'GI', label: 'Gibraltar', phone: '350', flag: '🇬🇮' },
  { code: 'GL', label: 'Groenlandia', phone: '299', flag: '🇬🇱' },
  { code: 'GM', label: 'Gambia', phone: '220', flag: '🇬🇲' },
  { code: 'GN', label: 'Guinea', phone: '224', flag: '🇬🇳' },
  { code: 'GP', label: 'Guadalupe', phone: '590', flag: '🇬🇵' },
  { code: 'GQ', label: 'Guinea Ecuatorial', phone: '240', flag: '🇬🇶' },
  { code: 'GR', label: 'Grecia', phone: '30', flag: '🇬🇷' },
  {
    code: 'GS',
    label: 'Islas Georgia del Sur y Sandwich del Sur',
    phone: '500',
    flag: '🇬🇸',
  },
  { code: 'GT', label: 'Guatemala', phone: '502', flag: '🇬🇹' },
  { code: 'GU', label: 'Guam', phone: '1-671', flag: '🇬🇺' },
  { code: 'GW', label: 'Guinea-Bisáu', phone: '245', flag: '🇬🇼' },
  { code: 'GY', label: 'Guyana', phone: '592', flag: '🇬🇾' },
  { code: 'HK', label: 'Hong Kong', phone: '852', flag: '🇭🇰' },
  {
    code: 'HM',
    label: 'Islas Heard y McDonald',
    phone: '672',
    flag: '🇭🇲',
  },
  { code: 'HN', label: 'Honduras', phone: '504', flag: '🇭🇳' },
  { code: 'HR', label: 'Croacia', phone: '385', flag: '🇭🇷' },
  { code: 'HT', label: 'Haití', phone: '509', flag: '🇭🇹' },
  { code: 'HU', label: 'Hungría', phone: '36', flag: '🇭🇺' },
  { code: 'ID', label: 'Indonesia', phone: '62', flag: '🇮🇩' },
  { code: 'IE', label: 'Irlanda', phone: '353', flag: '🇮🇪' },
  { code: 'IL', label: 'Israel', phone: '972', flag: '🇮🇱' },
  { code: 'IM', label: 'Isla de Man', phone: '44', flag: '🇮🇲' },
  { code: 'IN', label: 'India', phone: '91', flag: '🇮🇳' },
  {
    code: 'IO',
    label: 'Territorio Británico del Océano Índico',
    phone: '246',
    flag: '🇮🇴',
  },
  { code: 'IQ', label: 'Irak', phone: '964', flag: '🇮🇶' },
  { code: 'IR', label: 'Irán', phone: '98', flag: '🇮🇷' },
  { code: 'IS', label: 'Islandia', phone: '354', flag: '🇮🇸' },
  { code: 'IT', label: 'Italia', phone: '39', flag: '🇮🇹' },
  { code: 'JE', label: 'Jersey', phone: '44', flag: '🇯🇪' },
  { code: 'JM', label: 'Jamaica', phone: '1-876', flag: '🇯🇲' },
  { code: 'JO', label: 'Jordania', phone: '962', flag: '🇯🇴' },
  { code: 'JP', label: 'Japón', phone: '81', flag: '🇯🇵', suggested: true },
  { code: 'KE', label: 'Kenia', phone: '254', flag: '🇰🇪' },
  { code: 'KG', label: 'Kirguistán', phone: '996', flag: '🇰🇬' },
  { code: 'KH', label: 'Camboya', phone: '855', flag: '🇰🇭' },
  { code: 'KI', label: 'Kiribati', phone: '686', flag: '🇰🇮' },
  { code: 'KM', label: 'Comoras', phone: '269', flag: '🇰🇲' },
  {
    code: 'KN',
    label: 'San Cristóbal y Nieves',
    phone: '1-869',
    flag: '🇰🇳',
  },
  { code: 'KP', label: 'Corea del Norte', phone: '850', flag: '🇰🇵' },
  { code: 'KR', label: 'Corea del Sur', phone: '82', flag: '🇰🇷' },
  { code: 'KW', label: 'Kuwait', phone: '965', flag: '🇰🇼' },
  { code: 'KY', label: 'Islas Caimán', phone: '1-345', flag: '🇰🇾' },
  { code: 'KZ', label: 'Kazajistán', phone: '7', flag: '🇰🇿' },
  { code: 'LA', label: 'Laos', phone: '856', flag: '🇱🇦' },
  { code: 'LB', label: 'Líbano', phone: '961', flag: '🇱🇧' },
  { code: 'LC', label: 'Santa Lucía', phone: '1-758', flag: '🇱🇨' },
  { code: 'LI', label: 'Liechtenstein', phone: '423', flag: '🇱🇮' },
  { code: 'LK', label: 'Sri Lanka', phone: '94', flag: '🇱🇰' },
  { code: 'LR', label: 'Liberia', phone: '231', flag: '🇱🇷' },
  { code: 'LS', label: 'Lesoto', phone: '266', flag: '🇱🇸' },
  { code: 'LT', label: 'Lituania', phone: '370', flag: '🇱🇹' },
  { code: 'LU', label: 'Luxemburgo', phone: '352', flag: '🇱🇺' },
  { code: 'LV', label: 'Letonia', phone: '371', flag: '🇱🇻' },
  { code: 'LY', label: 'Libia', phone: '218', flag: '🇱🇾' },
  { code: 'MA', label: 'Marruecos', phone: '212', flag: '🇲🇦' },
  { code: 'MC', label: 'Mónaco', phone: '377', flag: '🇲🇨' },
  { code: 'MD', label: 'Moldavia', phone: '373', flag: '🇲🇩' },
  { code: 'ME', label: 'Montenegro', phone: '382', flag: '🇲🇪' },
  {
    code: 'MF',
    label: 'San Martín (parte francesa)',
    phone: '590',
    flag: '🇲🇫',
  },
  { code: 'MG', label: 'Madagascar', phone: '261', flag: '🇲🇬' },
  { code: 'MH', label: 'Islas Marshall', phone: '692', flag: '🇲🇭' },
  { code: 'MK', label: 'Macedonia', phone: '389', flag: '🇲🇰' },
  { code: 'ML', label: 'Malí', phone: '223', flag: '🇲🇱' },
  { code: 'MM', label: 'Birmania', phone: '95', flag: '🇲🇲' },
  { code: 'MN', label: 'Mongolia', phone: '976', flag: '🇲🇳' },
  { code: 'MO', label: 'Macao', phone: '853', flag: '🇲🇴' },
  {
    code: 'MP',
    label: 'Islas Marianas del Norte',
    phone: '1-670',
    flag: '🇲🇵',
  },
  { code: 'MQ', label: 'Martinica', phone: '596', flag: '🇲🇶' },
  { code: 'MR', label: 'Mauritania', phone: '222', flag: '🇲🇷' },
  { code: 'MS', label: 'Montserrat', phone: '1-664', flag: '🇲🇸' },
  { code: 'MT', label: 'Malta', phone: '356', flag: '🇲🇹' },
  { code: 'MU', label: 'Mauricio', phone: '230', flag: '🇲🇺' },
  { code: 'MV', label: 'Maldivas', phone: '960', flag: '🇲🇻' },
  { code: 'MW', label: 'Malaui', phone: '265', flag: '🇲🇼' },
  { code: 'MX', label: 'México', phone: '52', flag: '🇲🇽' },
  { code: 'MY', label: 'Malasia', phone: '60', flag: '🇲🇾' },
  { code: 'MZ', label: 'Mozambique', phone: '258', flag: '🇲🇿' },
  { code: 'NA', label: 'Namibia', phone: '264', flag: '🇳🇦' },
  { code: 'NC', label: 'Nueva Caledonia', phone: '687', flag: '🇳🇨' },
  { code: 'NE', label: 'Níger', phone: '227', flag: '🇳🇪' },
  { code: 'NF', label: 'Isla Norfolk', phone: '672', flag: '🇳🇫' },
  { code: 'NG', label: 'Nigeria', phone: '234', flag: '🇳🇬' },
  { code: 'NI', label: 'Nicaragua', phone: '505', flag: '🇳🇮' },
  { code: 'NL', label: 'Países Bajos', phone: '31', flag: '🇳🇱' },
  { code: 'NO', label: 'Noruega', phone: '47', flag: '🇳🇴' },
  { code: 'NP', label: 'Nepal', phone: '977', flag: '🇳🇵' },
  { code: 'NR', label: 'Nauru', phone: '674', flag: '🇳🇷' },
  { code: 'NU', label: 'Niue', phone: '683', flag: '🇳🇺' },
  { code: 'NZ', label: 'Nueva Zelanda', phone: '64', flag: '🇳🇿' },
  { code: 'OM', label: 'Omán', phone: '968', flag: '🇴🇲' },
  { code: 'PA', label: 'Panamá', phone: '507', flag: '🇵🇦' },
  { code: 'PE', label: 'Perú', phone: '51', flag: '🇵🇪' },
  { code: 'PF', label: 'Polinesia Francesa', phone: '689', flag: '🇵🇫' },
  { code: 'PG', label: 'Papúa Nueva Guinea', phone: '675', flag: '🇵🇬' },
  { code: 'PH', label: 'Filipinas', phone: '63', flag: '🇵🇭' },
  { code: 'PK', label: 'Pakistán', phone: '92', flag: '🇵🇰' },
  { code: 'PL', label: 'Polonia', phone: '48', flag: '🇵🇱' },
  { code: 'PM', label: 'San Pedro y Miquelón', phone: '508', flag: '🇵🇲' },
  { code: 'PN', label: 'Islas Pitcairn', phone: '870', flag: '🇵🇳' },
  { code: 'PR', label: 'Puerto Rico', phone: '1', flag: '🇵🇷' },
  { code: 'PS', label: 'Palestina', phone: '970', flag: '🇵🇸' },
  { code: 'PT', label: 'Portugal', phone: '351', flag: '🇵🇹' },
  { code: 'PW', label: 'Palaos', phone: '680', flag: '🇵🇼' },
  { code: 'PY', label: 'Paraguay', phone: '595', flag: '🇵🇾' },
  { code: 'QA', label: 'Catar', phone: '974', flag: '🇶🇦' },
  { code: 'RE', label: 'Reunión', phone: '262', flag: '🇷🇪' },
  { code: 'RO', label: 'Rumanía', phone: '40', flag: '🇷🇴' },
  { code: 'RS', label: 'Serbia', phone: '381', flag: '🇷🇸' },
  { code: 'RU', label: 'Rusia', phone: '7', flag: '🇷🇺' },
  { code: 'RW', label: 'Ruanda', phone: '250', flag: '🇷🇼' },
  { code: 'SA', label: 'Arabia Saudita', phone: '966', flag: '🇸🇦' },
  { code: 'SB', label: 'Islas Salomón', phone: '677', flag: '🇸🇧' },
  { code: 'SC', label: 'Seychelles', phone: '248', flag: '🇸🇨' },
  { code: 'SD', label: 'Sudán', phone: '249', flag: '🇸🇩' },
  { code: 'SE', label: 'Suecia', phone: '46', flag: '🇸🇪' },
  { code: 'SG', label: 'Singapur', phone: '65', flag: '🇸🇬' },
  { code: 'SH', label: 'Santa Elena', phone: '290', flag: '🇸🇭' },
  { code: 'SI', label: 'Eslovenia', phone: '386', flag: '🇸🇮' },
  { code: 'SJ', label: 'Svalbard y Jan Mayen', phone: '47', flag: '🇸🇯' },
  { code: 'SK', label: 'Eslovaquia', phone: '421', flag: '🇸🇰' },
  { code: 'SL', label: 'Sierra Leona', phone: '232', flag: '🇸🇱' },
  { code: 'SM', label: 'San Marino', phone: '378', flag: '🇸🇲' },
  { code: 'SN', label: 'Senegal', phone: '221', flag: '🇸🇳' },
  { code: 'SO', label: 'Somalia', phone: '252', flag: '🇸🇴' },
  { code: 'SR', label: 'Surinam', phone: '597', flag: '🇸🇷' },
  { code: 'SS', label: 'Sudán del Sur', phone: '211', flag: '🇸🇸' },
  { code: 'ST', label: 'Santo Tomé y Príncipe', phone: '239', flag: '🇸🇹' },
  { code: 'SV', label: 'El Salvador', phone: '503', flag: '🇸🇻' },
  {
    code: 'SX',
    label: 'Sint Maarten (parte holandesa)',
    phone: '1-721',
    flag: '🇸🇽',
  },
  { code: 'SY', label: 'Siria', phone: '963', flag: '🇸🇾' },
  { code: 'SZ', label: 'Suazilandia', phone: '268', flag: '🇸🇿' },
  {
    code: 'TC',
    label: 'Islas Turcas y Caicos',
    phone: '1-649',
    flag: '🇹🇨',
  },
  { code: 'TD', label: 'Chad', phone: '235', flag: '🇹🇩' },
  {
    code: 'TF',
    label: 'Territorios Australes Franceses',
    phone: '262',
    flag: '🇹🇫',
  },
  { code: 'TG', label: 'Togo', phone: '228', flag: '🇹🇬' },
  { code: 'TH', label: 'Tailandia', phone: '66', flag: '🇹🇭' },
  { code: 'TJ', label: 'Tayikistán', phone: '992', flag: '🇹🇯' },
  { code: 'TK', label: 'Tokelau', phone: '690', flag: '🇹🇰' },
  { code: 'TL', label: 'Timor Oriental', phone: '670', flag: '🇹🇱' },
  { code: 'TM', label: 'Turkmenistán', phone: '993', flag: '🇹🇲' },
  { code: 'TN', label: 'Túnez', phone: '216', flag: '🇹🇳' },
  { code: 'TO', label: 'Tonga', phone: '676', flag: '🇹🇴' },
  { code: 'TR', label: 'Turquía', phone: '90', flag: '🇹🇷' },
  { code: 'TT', label: 'Trinidad y Tobago', phone: '1-868', flag: '🇹🇹' },
  { code: 'TV', label: 'Tuvalu', phone: '688', flag: '🇹🇻' },
  { code: 'TW', label: 'Taiwán', phone: '886', flag: '🇹🇼' },
  { code: 'TZ', label: 'Tanzania', phone: '255', flag: '🇹🇿' },
  { code: 'UA', label: 'Ucrania', phone: '380', flag: '🇺🇦' },
  { code: 'UG', label: 'Uganda', phone: '256', flag: '🇺🇬' },
  {
    code: 'US',
    label: 'Estados Unidos',
    phone: '1',
    flag: '🇺🇸',
  },
  { code: 'UY', label: 'Uruguay', phone: '598', flag: '🇺🇾' },
  { code: 'UZ', label: 'Uzbekistán', phone: '998', flag: '🇺🇿' },
  { code: 'VA', label: 'Ciudad del Vaticano', phone: '379', flag: '🇻🇦' },
  {
    code: 'VC',
    label: 'San Vicente y las Granadinas',
    phone: '1-784',
    flag: '🇻🇨',
  },
  { code: 'VE', label: 'Venezuela', phone: '58', flag: '🇻🇪' },
  {
    code: 'VG',
    label: 'Islas Vírgenes Británicas',
    phone: '1-284',
    flag: '🇻🇬',
  },
  {
    code: 'VI',
    label: 'Islas Vírgenes de los Estados Unidos',
    phone: '1-340',
    flag: '🇻🇮',
  },
  { code: 'VN', label: 'Vietnam', phone: '84', flag: '🇻🇳' },
  { code: 'VU', label: 'Vanuatu', phone: '678', flag: '🇻🇺' },
  { code: 'WF', label: 'Wallis y Futuna', phone: '681', flag: '🇼🇫' },
  { code: 'WS', label: 'Samoa', phone: '685', flag: '🇼🇸' },
  { code: 'XK', label: 'Kosovo', phone: '383', flag: '🇽🇰' },
  { code: 'YE', label: 'Yemen', phone: '967', flag: '🇾🇪' },
  { code: 'YT', label: 'Mayotte', phone: '262', flag: '🇾🇹' },
  { code: 'ZA', label: 'Sudáfrica', phone: '27', flag: '🇿🇦' },
  { code: 'ZM', label: 'Zambia', phone: '260', flag: '🇿🇲' },
  { code: 'ZW', label: 'Zimbabue', phone: '263', flag: '🇿🇼' },
]

export const getFlag = label => {
  const country = countries.find(c => c.label === label)
  return country ? country.flag : null
}
export default countries
