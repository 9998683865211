import { useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import {
  allOrders,
  getOrder,
} from '../../../../domain/features/orders/getOrder'
import { getCurrentDate } from '../../../../domain/features/ui/rangeOfDaysSlice'
import { allTypes } from '../../../../domain/features/ui/typeSlice'
import { allStatus } from '../../../../domain/features/ui/dispatchedSlice'
import { allAccountingStatement } from '../../../../domain/features/ui/accountingStatementSlice'
import fetchOrders from '../../../../domain/features/orders/fetchOrders'
import fetchOrderListDownload from '../../../../domain/features/orderListDownload/fetchOrderListDownload'
import { getOrderListDownload } from '../../../../domain/features/orderListDownload/getOrderListDownload'
import { getSellerById } from '../../../../domain/features/seller/getSeller'
import { allDocument } from '../../../../domain/features/ui/documentSlice'
import {
  deleteIdle,
  downloadIdle,
  getDeleteDocument,
  getDownloadDocument,
  getUpdateDocument,
} from '../../../../domain/features/orders/orderSlice'
import { getCurrentDatePayout } from '../../../../domain/features/ui/rangeOfDaysPayoutSlice'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'

import ButtonFilter from '../../atoms/ButtonFilter/ButtonFilter'
import TableTemplate from '../../template/TableTemplate'
import Title from '../../atoms/BaseTable/Typography/Title'
import CellNumber from '../../atoms/BaseTable/BaseCell/CellNumber/CellNumber'
import FilterContainer from '../../molecules/FilterContainer/FilterContainer'
import Search from '../../organisms/Search/Search'
import SelectType from '../../organisms/SelectType/SelectType'
import SelectDispatched from '../../organisms/SelectDispatched/SelectDispatched'
import SelectAccountingStatement from '../../organisms/SelectAccountingStatement/SelectAccountingStatement'
import RangeOfDays from '../../organisms/RangeOfDays/RangeOfDays'
import HeaderContainer from '../../atoms/HeaderContainer'
import ButtonDownload from '../../molecules/ButtonDownload/ButtonDownload'
import Alert, { typeAlert } from '../../atoms/Alert/Alert'
import RangeOfDaysPayout from '../../organisms/RangeOfDaysPayout'
import ChipManager from '../../organisms/ChipManager'
import LoadDocument from '../../organisms/LoadDocument/LoadDocument'

import SelectDocument from '../../organisms/SelectDocument'

const ILoadDocument = styled(LoadDocument)`
  padding: 5px;
  margin: 0px;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const FilterRightContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 700px) {
    margin-top: 5%;
    margin-bottom: 1%;
  }
`

const SearhContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  @media (max-width: 1100px) {
    margin-right: 3%;
    margin-left: 3%;
  }
  @media (max-width: 700px) {
    margin-right: 1%;
    margin-left: 1%;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const stateDownload = () =>
  new Map([
    ['finished', 'Descarga exitosa'],
    [
      'loading',
      'Descargando subpedidos, esto podria demorar varios minutos.',
    ],
    ['error', 'Error al descargar subpedidos'],
  ])

const Orders = () => {
  const dispatch = useDispatch()
  const { state, search } = useLocation()

  const { status: downloadDocumentStatus } = useSelector(
    getDownloadDocument
  )
  const { status: updateDocumentStatus } = useSelector(getUpdateDocument)
  const { status: deleteDocumentStatus } = useSelector(getDeleteDocument)

  const [showDocumentDownload, setShowDocumentDownload] = useState(false)
  const [showUpdateDocument, setShowUpdateDocument] = useState(false)
  const [showDeleteDocument, setShowDeleteDocument] = useState(false)
  const columns = useMemo(
    () => [
      {
        Header: 'Fecha creación',
        accessor: 'date',
      },
      {
        Header: 'Pedido marketplace',
        accessor: 'idOrder',
      },
      {
        Header: 'Tipo',
        accessor: 'type',
      },
      {
        Header: 'Estado despacho',
        accessor: 'isDispatched',
      },
      {
        Header: 'Estado contable',
        accessor: 'accountingStatement',
      },
      {
        Header: 'Venta',
        accessor: 'totalSale',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Venta despachada',
        accessor: 'dispatchedSale',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Comisión',
        accessor: 'commission',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Otros descuentos',
        accessor: 'otherDiscount',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Payout',
        accessor: 'payout',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Fecha liquidación',
        accessor: 'payoutDate',
      },
      {
        Header: 'Documento tributario',
        accessor: 'documentName',
        Cell: cell => {
          return (
            <ILoadDocument
              value={cell.row.original.documentName}
              name={cell.row.original.idOrder}
              label={cell.row.original.documentType}
              disabled={downloadDocumentStatus === 'loading'}
            />
          )
        },
      },
    ],
    [downloadDocumentStatus]
  )

  const date = useSelector(getCurrentDate)
  const datePayout = useSelector(getCurrentDatePayout)
  const typeList = useSelector(allTypes)
  const statusList = useSelector(allStatus)
  const accountingStatementList = useSelector(allAccountingStatement)
  const documentList = useSelector(allDocument)
  const { name: sellerName, status: statusSeller } =
    useSelector(getSellerById)
  const [query, setQuery] = useState(undefined)
  const [debouncedQuery] = useDebounce(query, 800)
  const [pageCurrentIndex, setPage] = useState(0)
  const [perPage, setPerPage] = useState(25)

  useEffect(() => {
    if (search) {
      return
    }
    if (statusSeller !== 'finished') {
      return
    }

    const promise = dispatch(
      fetchOrders({
        startDate: date[0],
        endDate: date[1],
        startDatePayout: datePayout[0],
        endDatePayout: datePayout[1],
        status: statusList,
        accountingStatement: accountingStatementList,
        orderId: debouncedQuery,
        skip: pageCurrentIndex,
        limit: perPage,
        typeList,
        document: documentList,
      })
    )

    return () => {
      promise.abort()
    }
  }, [
    dispatch,
    date,
    datePayout,
    statusList,
    accountingStatementList,
    debouncedQuery,
    typeList,
    documentList,
    pageCurrentIndex,
    perPage,
    search,
    statusSeller,
  ])
  const data = useSelector(allOrders)
  const { pagination, status } = useSelector(getOrder)
  const rows = useMemo(
    () =>
      status === 'loading'
        ? Array(perPage > 0 ? perPage : 25).fill({ id: '' })
        : state
          ? state
          : data,
    [status, data, state, perPage]
  )

  const validStatusList = ['Facturado', 'Cancelado', 'Devuelto']

  const handleClickOptionOne = useCallback(() => {
    dispatch(
      fetchOrderListDownload({
        startDate: date[0],
        endDate: date[1],
        startDatePayout: datePayout[0],
        endDatePayout: datePayout[1],
        status: statusList,
        accountingStatement: accountingStatementList,
        orderId: debouncedQuery,
        typeList,
        seller: sellerName,
        document: documentList,
      })
    )
  }, [
    accountingStatementList,
    date,
    datePayout,
    debouncedQuery,
    dispatch,
    sellerName,
    statusList,
    typeList,
    documentList,
  ])

  const handleClickOptionTwo = useCallback(() => {
    dispatch(
      fetchOrderListDownload({
        startDate: null,
        endDate: null,
        startDatePayout: null,
        endDatePayout: null,
        status: [],
        accountingStatement: [],
        orderId: null,
        typeList: [],
        seller: sellerName,
        document: [],
      })
    )
  }, [dispatch, sellerName])

  const { status: statusDownload } = useSelector(getOrderListDownload)
  const [openAlert, setOpenAlert] = useState(true)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }
  useEffect(
    () => (statusDownload !== 'idle' ? setOpenAlert(true) : null),
    [statusDownload]
  )

  useEffect(() => {
    if (
      updateDocumentStatus === 'loading' ||
      updateDocumentStatus === 'error'
    ) {
      setShowUpdateDocument(true)
    } else {
      setShowUpdateDocument(false)
    }
    if (downloadDocumentStatus === 'loading') {
      setShowDocumentDownload(true)
    } else if (downloadDocumentStatus === 'finished') {
      setShowDocumentDownload(true)
      setTimeout(() => {
        dispatch(downloadIdle())
        setShowDocumentDownload(false)
      }, 5000)
    } else {
      setShowDocumentDownload(false)
    }
  }, [updateDocumentStatus, downloadDocumentStatus, dispatch])
  useEffect(() => {
    if (deleteDocumentStatus === 'loading') {
      setShowDeleteDocument(true)
    } else if (deleteDocumentStatus === 'finished') {
      setShowDeleteDocument(true)
      setTimeout(() => {
        setShowDeleteDocument(false)
        dispatch(deleteIdle())
      }, 5000)
    } else if (deleteDocumentStatus === 'error') {
      setTimeout(() => {
        setShowDeleteDocument(false)
      }, 5000)
    }
  }, [deleteDocumentStatus, dispatch])

  const [isViewFilter, setViewFilter] = useState(true)

  return (
    <>
      <HeaderContainer>
        <Title>Pedidos</Title>
      </HeaderContainer>
      <Paper>
        <SearhContainer mr="25px" pt="15px" ml="25px">
          <Search
            type="text"
            value={query}
            onChange={e => setQuery(String(e.target.value))}
          />
          <FilterRightContainer>
            <ButtonFilter
              disabled={status === 'loading'}
              onClick={() => setViewFilter(!isViewFilter)}
              isfilter={isViewFilter}
            />
            <ButtonDownload
              type="select"
              disabled={status === 'loading'}
              handleClickOptionOne={handleClickOptionOne}
              handleClickOptionTwo={handleClickOptionTwo}
            />
          </FilterRightContainer>
        </SearhContainer>
        <Collapse in={isViewFilter}>
          <FilterContainer>
            <RangeOfDays />
            <SelectType />
            <SelectDispatched />
            <SelectAccountingStatement
              isallfilter={true}
              list={[
                'No Liquidado',
                'Facturado - No Pagado',
                'Pagado',
                'Contabilizado',
              ]}
            />
            <SelectDocument isallfilter={true} />
            <RangeOfDaysPayout />
          </FilterContainer>
        </Collapse>
        <ChipManager
          date={date}
          seller={sellerName}
          type={typeList}
          status={statusList}
          accountingStatement={accountingStatementList}
          payoutDate={datePayout}
          document={documentList}
        />
        <TableTemplate
          key="pedidos"
          pathname="/pedidos"
          isLoading={status === 'loading'}
          isError={status === 'error'}
          data={rows.map(
            ({ otherDiscount, status, id, ...row }, index) => ({
              ...row,
              id,
              key: `${id}-${index}`,
              status,
              otherDiscount: validStatusList.some(
                s => s === String(status)
              )
                ? otherDiscount
                : 0,
            })
          )}
          columns={columns}
          withPage
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          pageCurrentIndex={pageCurrentIndex || 0}
          totalPage={pagination?.paginationTotalCount || 0}
        />
      </Paper>
      <Alert
        key="status-download"
        open={openAlert}
        onClose={handleCloseAlert}
        title={stateDownload().get(statusDownload)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        isLoading={statusDownload === 'loading'}
        severity={typeAlert.get(statusDownload)}
        isError={statusDownload === 'error'}
      />
      <Alert
        key="upload-document"
        open={
          showUpdateDocument &&
          (updateDocumentStatus === 'error' ||
            updateDocumentStatus === 'loading')
        }
        onClose={() => {
          setShowUpdateDocument(false)
        }}
        title={
          updateDocumentStatus === 'loading'
            ? 'subiendo archivo'
            : updateDocumentStatus === 'error'
              ? 'Se ha producido un error'
              : 'Archivo subido exitosamente'
        }
        isLoading={updateDocumentStatus === 'loading'}
        isError={updateDocumentStatus === 'error'}
        severity={
          updateDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />
      <Alert
        key="download-document"
        open={
          showDocumentDownload &&
          (downloadDocumentStatus === 'finished' ||
            downloadDocumentStatus === 'error' ||
            downloadDocumentStatus === 'loading')
        }
        onClose={() => {
          dispatch(downloadIdle())
          setShowDocumentDownload(false)
        }}
        title={
          downloadDocumentStatus === 'error'
            ? 'Se ha producido un error'
            : downloadDocumentStatus === 'loading'
              ? 'Descargando archivo'
              : 'Archivo descargado exitosamente'
        }
        isLoading={downloadDocumentStatus === 'loading'}
        isError={downloadDocumentStatus === 'error'}
        severity={
          downloadDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />
      <Alert
        key="delete-document"
        open={
          showDeleteDocument &&
          (deleteDocumentStatus === 'finished' ||
            deleteDocumentStatus === 'error' ||
            deleteDocumentStatus === 'loading')
        }
        onClose={() => {
          setShowDeleteDocument(false)
          dispatch(deleteIdle())
        }}
        title={
          deleteDocumentStatus === 'error'
            ? 'Se ha producido un error'
            : deleteDocumentStatus === 'loading'
              ? 'Eliminando archivo'
              : deleteDocumentStatus === 'finished'
                ? 'Archivo eliminado exitosamente'
                : ''
        }
        isLoading={deleteDocumentStatus === 'loading'}
        isError={deleteDocumentStatus === 'error'}
        severity={
          deleteDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />
    </>
  )
}

export default Orders
