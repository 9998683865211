import Typography from '@mui/material/Typography'
import styled from 'styled-components'

const TitleProduct = styled(Typography)`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-bottom: 2%;
`
export default TitleProduct
