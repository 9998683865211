/* eslint-disable linebreak-style */

import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'

const LinkContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 965px) {
    margin-bottom: 20px;
    width: 100%;
  }
`

const StyledLink = styled(Link)`
  width: 65px;
  height: 8px;
  background-color: ${({ active }) =>
    active === 'false' ? '#e5e5e5' : '#c4c4c4'};
  margin: 0px 8px;
  &:active {
    background-color: #c4c4c4;
    outline: none;
  }
  &:focus {
    background-color: #c4c4c4;
    outline: none;
  }
  &:hover {
    background-color: #c4c4c4;
    outline: none;
  }
  @media (max-width: 965px) {
    width: 8%;
    margin: 0px 3px;
  }
`

const Carousel = () => {
  const selected = useParams()

  return (
    <LinkContainer>
      <StyledLink
        active={String(selected.page === '1')}
        to="/onboarding/1"
      />
      <StyledLink
        active={String(selected.page === '2')}
        to="/onboarding/2"
      />
      <StyledLink
        active={String(selected.page === '3')}
        to="/onboarding/3"
      />
      <StyledLink
        active={String(selected.page === '4')}
        to="/onboarding/4"
      />
      <StyledLink
        active={String(selected.page === '5')}
        to="/onboarding/5"
      />
    </LinkContainer>
  )
}

export default Carousel
