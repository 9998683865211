import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isArray from 'lodash/isArray'
import styled from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'

import Button from '@mui/material/Button'

import { schema } from '../../../../domain/schemas/importSchema'

import ExportImportTemplate from '../../molecules/ExportImportTemplate/ExportImportTemplate'
import ImportProductsControll from '../../organisms/ImportProductsControll'
import Spinner from '../../atoms/Spinner'
import Text from '../../atoms/Typography/Text'
import Title from '../../atoms/Typography/Title'
import Navigate from '../../atoms/Navigate/Navigate'
import Alert, { typeAlert } from '../../atoms/Alert/Alert'

import SelectCategory from '../../organisms/SelectCategory/SelectCategory'
import error from '../../../assets/error.png'
import download from '../../../assets/download.svg'
import one from '../../../assets/1.svg'
import two from '../../../assets/2.svg'
import three from '../../../assets/3.svg'
import four from '../../../assets/4.svg'
import fetchProductCreateExport from '../../../../domain/features/productCreateExport/fetchProductCreateExport'
import fetchProductCreateValidate from '../../../../domain/features/productCreateValidate/fetchProductCreateValidate'
import { getProductCreateValidate } from '../../../../domain/features/productCreateValidate/getProductCreateValidate'
import { resetProductCreateValidate } from '../../../../domain/features/productCreateValidate/productCreateValidateSlice'
import fetchProductCreateImport from '../../../../domain/features/productCreateImport/fetchProductCreateImport'
import { getProductCreateImport } from '../../../../domain/features/productCreateImport/getProductCreateImport'
import { getProductCreateExport } from '../../../../domain/features/productCreateExport/getProductCreateExport'
import { Grid } from '@mui/material'
import ChipCategories from '../../organisms/ChipCategories/ChipCategories'
import { getSellerById } from '../../../../domain/features/seller/getSeller'
import ButtonBack from '../../atoms/ButtonBack'

const StyledTitle = styled(Title)`
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 2%;
  padding-left: 1rem;
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`

const Margin = styled(Container)`
  margin-left: 15%;
  margin-top: 8%;
  padding-right: 10%;
`

const ExportImportContainer = styled(Container)`
  margin-top: 6%;
  margin-left: -2%;
`

const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
`

const ExportImportText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  align-items: center;
`

const StyledSpan = styled('span')`
  font-weight: 500;
`

const StyledButton = styled(Button)`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  width: 191.94px;
  height: 44.46px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  color: #00000066;
  align-self: flex-end;
`

const StyledStepList = styled('ul')`
  padding-right: 5%;
  padding-top: 2%;
`

const Li = styled('li')`
  margin-bottom: 2%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-align: left;
`

const Card = styled('div')`
  display: flex;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #ffffff;
  align-items: center;
  margin-top: 2%;
`

const ErrorBox = styled('div')`
  padding-top: 1%;
  margin-left: 2%;
  display: flex;
  flex-direction: column;
`

const ErrorBoxText = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #e72020;
`

const Icon = styled('img')`
  margin: 0;
  padding-right: 5px;
`

const ButtonSendStyled = styled(Button)`
  width: 191.94px;
  height: 44.46px;
  border-radius: 5px;
  margin-right: 2%;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  &:disabled {
    background-color: #00806080;
    color: white;
  }

  @media (max-width: 1100px) {
    margin-left: 0%;
  }
`

const ButtonContainer = styled('div')`
  display: flex;
  margin-top: 10%;
  justify-content: flex-end;
  margin-bottom: 15%;
  .MuiButtonBase-root:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const CategoryContainer = styled('div')`
  width: 80%;
  margin-top: 15px;
  background: white;
  max-width: 550px;
`

const SelectedCategoriesContainer = styled('div')`
  background-color: #ebebeb;
  height: 100vh;
  position: fixed;
  right: 0px;
  top: 60px;
  width: 300px;
  overflow: auto;
  display: flex;
  align-items: center;
`

const Row = styled('div')`
  display: flex;
  flex-direction: 'row';
  align-items: baseline;
`

const importAlert = () =>
  new Map([
    ['finished', 'Cambios guardados'],
    [
      'error',
      'Error de sistema. Por favor revisa en el Excel importado, que las variantes ingresadas hayan sido exportadas previamente. ',
    ],
  ])

const MultipleCreate = () => {
  const {
    formState: { errors },
    register,
    control,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { multiProducts: undefined },
    mode: 'all',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  })

  const [selectedCategory, setSelectedCategory] = useState([])
  const [errorCategories, setErrorCategories] = useState(null)

  const handleDeleteXlsx = () => {
    setValue('multiProducts', undefined)
    clearErrors()
    dispatch(resetProductCreateValidate())
  }

  const stateValidate = useSelector(getProductCreateValidate)
  const stateImport = useSelector(getProductCreateImport)
  const stateExport = useSelector(getProductCreateExport)
  const seller = useSelector(getSellerById)
  const dispatch = useDispatch()
  const fileWatch = useWatch({ name: 'multiProducts', control })
  const onSubmit = useCallback(
    e => {
      dispatch(fetchProductCreateValidate({ data: e.multiProducts }))
    },
    [dispatch]
  )
  const handleExport = useCallback(() => {
    if (selectedCategory.length === 0) {
      setErrorCategories('Debes seleccionar al menos una categoría')
    } else {
      setErrorCategories(null)
      dispatch(fetchProductCreateExport(selectedCategory))
    }
  }, [dispatch, selectedCategory])

  const handleSendDocument = useCallback(() => {
    dispatch(
      fetchProductCreateImport({
        data: fileWatch,
        seller: seller?.name,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fileWatch])

  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }
  useEffect(() => {
    if (
      stateImport.status === 'error' ||
      stateImport.status === 'finished'
    ) {
      setOpenAlert(true)
      if (stateImport.status === 'finished') {
        handleDeleteXlsx()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateImport])

  const setCategory = useCallback(
    categories => {
      setSelectedCategory(categories)
    },
    [setSelectedCategory]
  )

  return (
    <Grid container spacing={20}>
      <Grid item xs={selectedCategory.length > 0 ? 10 : 12}>
        <Margin>
          <Row>
            <ButtonBack color="inherit" />
            <StyledTitle>Crear productos</StyledTitle>
          </Row>

          <StyledText>
            Selecciona las categorías de los productos a crear, luego
            exporta la planilla editable, rellenala en Excel y vuelve a
            importarla.{' '}
            <StyledSpan>
              Es importante que seas preciso en cada columna para que tu
              planilla sea validada.
            </StyledSpan>
          </StyledText>
          <ExportImportContainer>
            <ExportImportText>
              <Icon src={one} alt="edit" />
              Selecciona las categorías a las que pertenecen los productos
              a crear.
            </ExportImportText>
            <CategoryContainer>
              <SelectCategory
                label="Seleccionar categoría(s)"
                value={selectedCategory}
                onChange={setCategory}
                isfilterleft
                width="100%"
              />
            </CategoryContainer>
            {errorCategories && (
              <ErrorBox>
                <ErrorBoxText>
                  <Icon src={error} height="14x" width="18.5px" />
                  {errorCategories}
                </ErrorBoxText>
              </ErrorBox>
            )}
          </ExportImportContainer>
          <ExportImportContainer>
            <ExportImportText>
              <Icon src={two} alt="edit" />
              Exporta la planilla excel editable con las categorías
              previamente seleccionadas.
            </ExportImportText>
            <ExportImportTemplate
              icon={download}
              text="Exportar planilla editable "
              handleExport={handleExport}
              exportStatus={stateExport.status}
            />
          </ExportImportContainer>
          <ExportImportContainer>
            <ExportImportText>
              <Icon src={three} />
              Edita la planilla en tu computador.
            </ExportImportText>
            <Card>
              <StyledStepList>
                <Li>
                  Cada una de las ventanas del Excel corresponde a la
                  planilla de una categoría.
                </Li>
                <Li>
                  Las variantes del producto deben ir en filas separadas en
                  el Excel.
                </Li>
                <Li>
                  Es importante que no agregues otros campos, muevas
                  columnas o cambies el formato de las celdas, ya que la
                  planilla no será validada.
                </Li>
                <Li>Recuerda que los cambos con (*) son obligatorios.</Li>
              </StyledStepList>
            </Card>
          </ExportImportContainer>
          <ExportImportContainer>
            <ExportImportText>
              <Icon src={four} />
              Importa la planilla editada anteriormente. Esta debe ser
              formato Excel (xlsx).
            </ExportImportText>
            <ExportImportTemplate>
              <ImportProductsControll
                register={register}
                icon={download}
                text="Importar planilla editada"
                reverseIcon
                value={fileWatch}
                errors={errors}
                handleDeleteXlsx={handleDeleteXlsx}
                onSubmit={handleSubmit(onSubmit)}
                disabled={fileWatch}
                status={stateValidate}
              />
            </ExportImportTemplate>
            {(isArray(stateValidate.errors) || errors['multiProducts']) &&
            stateValidate.status === 'error' ? (
              <ErrorBox>
                <ErrorBoxText>
                  <Icon src={error} height="14x" width="18.5px" />
                  {isArray(stateValidate.errors)
                    ? stateValidate.errors
                        .split('.')
                        .map((item, index) => {
                          return <Li key={`error-${index}`}>{item}</Li>
                        })
                    : errors['multiProducts'].message}
                </ErrorBoxText>
              </ErrorBox>
            ) : null}
          </ExportImportContainer>
          <ButtonContainer>
            <ButtonSendStyled
              onClick={handleSendDocument}
              variant="contained"
              type="submit"
              disabled={stateValidate.status !== 'finished'}
              endIcon={
                stateImport.status === 'loading' ? (
                  <Spinner size="1.2rem" />
                ) : null
              }
            >
              Finalizar
            </ButtonSendStyled>
            <Navigate to="/productos">
              <StyledButton>Cancelar</StyledButton>
            </Navigate>
          </ButtonContainer>
          <Alert
            open={openAlert}
            onClose={handleCloseAlert}
            title={importAlert().get(stateImport.status)}
            isLoading={stateImport.status === 'loading'}
            severity={typeAlert.get(stateImport.status)}
            isError={stateImport.status === 'error'}
          />
        </Margin>
      </Grid>
      {selectedCategory.length > 0 && (
        <Grid item xs={2} alignItems="center">
          <SelectedCategoriesContainer>
            <ChipCategories
              category={selectedCategory}
              setCategory={setSelectedCategory}
            />
          </SelectedCategoriesContainer>
        </Grid>
      )}
    </Grid>
  )
}

export default MultipleCreate
