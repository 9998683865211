import React from 'react'
import PropTypes from 'prop-types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Navigate from '../../atoms/Navigate/Navigate'
import styled from 'styled-components'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import useMediaQuery from '@mui/material/useMediaQuery'

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  padding: 5px 16px;
  height: 37px;
  border: 1px solid #d1d0d0;
  margin-left: 14px;
  text-transform: none;
  color: ${({ theme }) => theme.palette.text.primary};
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: #cccccc !important;
  }

  @media (max-width: 700px) {
    text-align: center;
    margin: 0px;
    padding: 0px;
    padding-right: 5px;
    padding-left: 5px;
    min-width: 20px;
    height: 40px;
    width: 40px;
    margin-left: 14px;
  }
`

const MenuDropdown = ({ title, options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const matches = useMediaQuery('(max-width:700px)')
  return (
    <>
      <StyledButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="text"
        color="primary"
        startIcon={<MoreHorizIcon />}
      >
        {!matches && title}
      </StyledButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options?.map((option, index) => {
          return (
            <Navigate key={`${option.label}-${index}`} to={option.to}>
              <MenuItem onClick={handleClose}>{option.label}</MenuItem>
            </Navigate>
          )
        })}
      </Menu>
    </>
  )
}

MenuDropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.any,
  ]),
}

export default MenuDropdown
