import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../../../assets/logoWeb.webp'
import Text from '../../atoms/Typography/Text'
import Title from '../../atoms/Typography/Title'

const SpaceAround = styled('div')`
  align-self: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 1010px) {
    flex-direction: column;
  }
`
const Img = styled('img')`
  @media only screen and (max-width: 1010px) {
    width: 70%;
    margin-bottom: 20px;
  }
`

const TitleLogo = styled(Title)`
  font-size: 30px;
  font-style: light;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.04em;
  text-align: center;
  margin-bottom: 15px;
  align-self: flex-start;
  padding-left: 10%;
  @media only screen and (max-width: 1010px) {
    align-self: center;
    padding-left: 0;
  }
`

const Line = styled('div')`
  width: 80%;
  border: 0.5px solid #000000;
`

const LogoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e5e5e5;
  color: black;
  flex: 1.5;
  height: 100vh;
  @media only screen and (max-width: 1010px) {
    width: 100%;
  }
`
const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  margin-top: -15%;
  padding-bottom: 20%;
  width: 100%;
  @media only screen and (max-width: 1010px) {
    margin-top: 0;
    padding-bottom: 42px;
  }
`

const LinkConfirmationContainer = styled('div')`
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 40%;
  @media only screen and (max-width: 1010px) {
    width: 100%;
  }
`

const TitleText = styled(Title)`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  white-space: nowrap;
  padding-bottom: 10%;
`

const Container = styled('div')`
  margin-left: 20%;
  display: flex;
  flex-direction: column;
  margin-top: -25%;
  width: 100%;
`

const InfoText = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.palette.text.primary};
  width: 80%;
`

const StyledSpan = styled('span')`
  margin-top: 2%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.palette.text.primary};
`

const NoteText = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #828487;
  margin-top: 6%;
  width: 80%;
`

const Confirmation = () => {
  const location = useLocation()
  const lettersToReplace = location.state.split('@')[0].length
  const email =
    lettersToReplace > 3
      ? `${location.state
          .split('')
          .map((letter, idx) => {
            if (idx < lettersToReplace - 3) {
              return '*'
            }
            return letter
          })
          .join('')}`
      : `***@${location.state.split('@')[1]}`
  return (
    <SpaceAround>
      <LogoContainer>
        <TitleContainer>
          <TitleLogo>Seller Center</TitleLogo>
          <Line />
        </TitleContainer>
        <Img alt="search" src={Logo} height="30%" />
      </LogoContainer>
      <LinkConfirmationContainer>
        <Container>
          <TitleText>Link enviado</TitleText>
          <InfoText>
            Por favor revisa tu buzón de entrada y haz click en el nuevo
            link que hemos enviado. El correo fue enviado a:
          </InfoText>
          <StyledSpan>{email}</StyledSpan>
          <NoteText>
            Si no has recibido el correo, por favor revisa tu carpeta de
            Spam (correos no deseados).
          </NoteText>
        </Container>
      </LinkConfirmationContainer>
    </SpaceAround>
  )
}

export default Confirmation
