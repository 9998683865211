/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import auth from '../../../../data/firebase/auth'
import KcLogo from '../../../assets/logoWeb.webp'
import Warning from '../../../assets/warning.svg'
import Text from '../../atoms/Typography/Text'
import Title from '../../atoms/Typography/Title'

const Container = styled('div')`
  display: flex;
  width: 100vw;
  height: 100%;
  background-color: ${({ theme }) => theme?.palette?.background?.default};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Logo = styled('img')`
  margin-top: 2%;
  margin-left: 3%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
`

const Wrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
`

const StyledTitle = styled(Title)`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #008060;
  margin-bottom: 1.5%;
`

const StyledText = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.04em;
  width: 38%;
  text-align: center;
  color: #828487;
  @media only screen and (max-width: 1010px) {
    width: 80%;
  }
`

const WarningIcon = styled('img')`
  margin-top: -8%;
`

const StyledButton = styled(Button)`
  border-radius: 5px;
  width: 172px;
  margin-top: 3%;
`

const Expiration = () => {
  const [isLoading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const navigate = useNavigate()

  const handleSendLink = useCallback(async () => {
    setLoading(true)
    sendPasswordResetEmail(auth, email).finally(() => {
      setLoading(false)
      navigate('/confirmacion', { state: email })
    })
  }, [email])

  return (
    <Wrapper>
      <Container>
        <Logo src={KcLogo} />
        <WarningIcon src={Warning} />
        <StyledTitle>¡Ups, el link ha expirado!</StyledTitle>
        <StyledText>
          Por motivos de seguridad, el link de acceso ha expirado.
        </StyledText>
        <StyledText>
          Haz click en ”reenviar link” para que te enviemos uno nuevo a tu
          correo.
        </StyledText>
        <StyledButton
          type="submit"
          onClick={handleSendLink}
          color="primary"
          variant="contained"
          endIcon={isLoading ? <CircularProgress size="1.2rem" /> : null}
        >
          Reenviar link
        </StyledButton>
      </Container>
    </Wrapper>
  )
}

export default Expiration
