import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'

import Button from '@mui/material/Button'

import { schema } from '../../../../domain/schemas/importSchema'
import fetchProductImport from '../../../../domain/features/productImport/fetchProductImport'
import { resetProductImport } from '../../../../domain/features/productImport/productImportSlice'
import fetchProductExport from '../../../../domain/features/productExport/fetchProductExport'
import fetchProductValidate from '../../../../domain/features/productValidate/fetchProductValidate'
import { resetProductValidate } from '../../../../domain/features/productValidate/productValidateSlice'
import { getProductValidate } from '../../../../domain/features/productValidate/getProductValidate'
import { getProductExport } from '../../../../domain/features/productExport/getProductExport'
import { getProductImport } from '../../../../domain/features/productImport/getProductImport'

import ExportImportTemplate from '../../molecules/ExportImportTemplate/ExportImportTemplate'
import ImportProductsControll from '../../organisms/ImportProductsControll'
import Spinner from '../../atoms/Spinner'
import Text from '../../atoms/Typography/Text'
import Title from '../../atoms/Typography/Title'
import Navigate from '../../atoms/Navigate/Navigate'
import Alert, { typeAlert } from '../../atoms/Alert/Alert'

import error from '../../../assets/error.png'
import download from '../../../assets/download.svg'
import one from '../../../assets/1.svg'
import two from '../../../assets/2.svg'
import three from '../../../assets/3.svg'

const StyledTitle = styled(Title)`
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 2%;
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`

const Margin = styled(Container)`
  margin-left: 15%;
  margin-top: 8%;
  padding-right: 10%;
`

const ExportImportContainer = styled(Container)`
  margin-top: 8%;
  margin-left: -2%;
`

const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
`

const ExportImportText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  align-items: center;
`

const StyledSpan = styled('span')`
  font-weight: 500;
`

const StyledButton = styled(Button)`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  width: 191.94px;
  height: 44.46px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  color: #00000066;
  align-self: flex-end;
`

const StyledStepList = styled('ul')`
  padding-right: 5%;
  padding-top: 2%;
`

const Li = styled('li')`
  margin-bottom: 2%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-align: left;
`

const Card = styled('div')`
  display: flex;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #ffffff;
  align-items: center;
  margin-top: 2%;
`

const ErrorBox = styled('div')`
  padding-top: 1%;
  margin-left: 2%;
  display: flex;
  flex-direction: column;
`

const ErrorBoxText = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #e72020;
`

const Icon = styled('img')`
  margin: 0;
  padding-right: 5px;
`

const ButtonSendStyled = styled(Button)`
  width: 191.94px;
  height: 44.46px;
  border-radius: 5px;
  margin-right: 2%;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  &:disabled {
    background-color: #00806080;
    color: white;
  }

  @media (max-width: 1100px) {
    margin-left: 0%;
  }
`

const ButtonContainer = styled('div')`
  display: flex;
  margin-top: 10%;
  justify-content: flex-end;
  margin-bottom: 15%;
  .MuiButtonBase-root:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const importAlert = () =>
  new Map([
    ['finished', 'Cambios guardados'],
    [
      'error',
      'Error de sistema. Por favor revisa en el Excel importado, que las variantes ingresadas hayan sido exportadas previamente. ',
    ],
  ])

const MultipleEdit = () => {
  const {
    formState: { errors },
    register,
    control,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { multiProducts: undefined },
    mode: 'all',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  })
  const handleDeleteXlsx = () => {
    setValue('multiProducts', undefined)
    clearErrors()
    dispatch(resetProductImport())
    dispatch(resetProductValidate())
  }

  const stateValidate = useSelector(getProductValidate)
  const stateImport = useSelector(getProductImport)
  const stateExport = useSelector(getProductExport)
  const dispatch = useDispatch()
  const fileWatch = useWatch({ name: 'multiProducts', control })
  const onSubmit = useCallback(
    e => {
      dispatch(fetchProductValidate({ data: e.multiProducts }))
    },
    [dispatch]
  )
  const handleExport = useCallback(() => {
    dispatch(fetchProductExport())
  }, [dispatch])

  const handleSendDocument = useCallback(() => {
    dispatch(
      fetchProductImport({
        data: fileWatch,
      })
    )
  }, [dispatch, fileWatch])

  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }
  useEffect(() => {
    if (
      stateImport.status === 'error' ||
      stateImport.status === 'finished'
    ) {
      setOpenAlert(true)
    }
  }, [stateImport])

  return (
    <Margin>
      <StyledTitle>Editar Productos</StyledTitle>
      <StyledText>
        Exporta planilla editable con listado completo de productos, luego
        editala en Excel y vuelve a importarla.{' '}
        <StyledSpan>
          Es importante que seas preciso en cada columna para que tu
          planilla sea validada.{' '}
        </StyledSpan>
      </StyledText>
      <ExportImportContainer>
        <ExportImportText>
          <Icon src={one} alt="edit" />
          Exporta planilla excel editable con productos seleccionados
        </ExportImportText>
        <ExportImportTemplate
          icon={download}
          text="Exportar planilla editable "
          handleExport={handleExport}
          exportStatus={stateExport.status}
        />
      </ExportImportContainer>
      <ExportImportContainer>
        <ExportImportText>
          <Icon src={two} />
          Edita la planilla en tu computador.
        </ExportImportText>
        <Card>
          <StyledStepList>
            <Li>
              Solo puedes editar los valores de las columnas{' '}
              <StyledSpan>“Precio oferta”</StyledSpan>,{' '}
              <StyledSpan>“Precio normal”</StyledSpan> y{' '}
              <StyledSpan>“Cantidad disponible”</StyledSpan>, cumpliendo
              con las validaciones según formulario de creación de
              producto.
            </Li>
            <Li>
              Es importante que no edites otros campos, muevas columnas o
              cambies el formato de las celdas, ya que pueden editarse las
              variantes equivocadas.
            </Li>
          </StyledStepList>
        </Card>
      </ExportImportContainer>
      <ExportImportContainer>
        <ExportImportText>
          <Icon src={three} />
          Importa la planilla editada anteriormente. Esta debe ser formato
          Excel (xlsx).
        </ExportImportText>
        <ExportImportTemplate>
          <ImportProductsControll
            register={register}
            icon={download}
            text="Importar planilla editada"
            reverseIcon
            value={fileWatch}
            errors={errors}
            handleDeleteXlsx={handleDeleteXlsx}
            onSubmit={handleSubmit(onSubmit)}
            disabled={fileWatch}
            status={stateValidate}
          />
        </ExportImportTemplate>
        {stateValidate.errors || errors['multiProducts'] ? (
          <ErrorBox>
            <ErrorBoxText>
              <Icon src={error} height="14x" width="18.5px" />
              {stateValidate.errors
                ? stateValidate?.errors
                : errors['multiProducts']?.message}
            </ErrorBoxText>
          </ErrorBox>
        ) : null}
      </ExportImportContainer>
      <ButtonContainer>
        <ButtonSendStyled
          onClick={handleSendDocument}
          variant="contained"
          type="submit"
          disabled={!stateValidate.ok}
          endIcon={
            stateImport.status === 'loading' ? (
              <Spinner size="1.2rem" />
            ) : null
          }
        >
          Finalizar
        </ButtonSendStyled>
        <Navigate to="/productos">
          <StyledButton>Cancelar</StyledButton>
        </Navigate>
      </ButtonContainer>
      <Alert
        open={openAlert}
        onClose={handleCloseAlert}
        title={importAlert().get(stateImport.status)}
        isLoading={stateImport.status === 'loading'}
        severity={typeAlert.get(stateImport.status)}
        isError={stateImport.status === 'error'}
      />
    </Margin>
  )
}

export default MultipleEdit
