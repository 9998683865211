import dayjs from 'dayjs'

const dateDetailOrder = date => {
  return dayjs(date).isValid()
    ? dayjs(date)
        .locale('es')
        .format('DD [de] MMMM [de] YYYY [a las] HH:mm')
    : ''
}
export default dateDetailOrder
