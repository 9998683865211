import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Tooltip from '../../atoms/ToolTip/ToolTip'
import PreviewIcon from '@mui/icons-material/PreviewOutlined'
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined'
import styled from 'styled-components'
import { useRef } from 'react'

const IconButtonBase = styled(IconButton)`
  color: ${({ theme, color }) => color || theme.palette.text.primary};
  z-index: 5;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const ButtonPreviewProduct = ({
  to,
  titleTooltip,
  color,
  isProduction = false,
  fontSize = 'large',
}) => {
  const iconbuttonRef = useRef()

  return titleTooltip ? (
    <Tooltip title={titleTooltip}>
      <IconButtonBase
        ref={iconbuttonRef}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        color={color}
      >
        {isProduction ? (
          <LocalMallOutlinedIcon fontSize={fontSize || 'large'} />
        ) : (
          <PreviewIcon fontSize={fontSize || 'large'} />
        )}
      </IconButtonBase>
    </Tooltip>
  ) : (
    <IconButtonBase
      ref={iconbuttonRef}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      color={color}
    >
      <PreviewIcon fontSize={fontSize || 'large'} />
    </IconButtonBase>
  )
}
ButtonPreviewProduct.propTypes = {
  to: PropTypes.string,
  titleTooltip: PropTypes.string,
  color: PropTypes.string,
  isProduction: PropTypes.bool,
  fontSize: PropTypes.string,
}
ButtonPreviewProduct.defaultProps = {
  to: '',
  titleTooltip: '',
  isProduction: false,
  color: 'inherit',
  fontSize: 'large',
}
export default ButtonPreviewProduct
