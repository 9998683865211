import { createSelector } from '@reduxjs/toolkit'
import { orderAdapter, orderSlice } from './orderSlice'
import pipe from 'lodash/fp/pipe'
import deburr from 'lodash/deburr'
import filter from 'lodash/fp/filter'

const getStateOrder = state => state.order.list

export const getOrder = createSelector([getStateOrder], sellers => sellers)

const orderSelectors = orderAdapter.getSelectors(getStateOrder)
export const allOrders = orderSelectors.selectAll

const defaultValues = values => values
const filterSellerName =
  selectList =>
  ({ sellerName }) =>
    selectList.some(
      sellerCurrent =>
        String(sellerCurrent).toLowerCase() ===
        String(sellerName).toLowerCase()
    )

const filterIsDipatched =
  _isDispatched =>
  ({ isDispatched }) => {
    return (
      deburr(isDispatched).toLowerCase() ===
      deburr(_isDispatched).toLowerCase()
    )
  }

const filterAccountingStatement =
  (_accountingStatement = ['No Liquidado']) =>
  ({ accountingStatement }) => {
    return _accountingStatement.some(
      acs =>
        deburr(acs).toLowerCase() ===
        deburr(accountingStatement).toLowerCase()
    )
  }

export const allOrdersWithFilters =
  (
    selectList = [],
    isDispatched = undefined,
    _accountingStatement = undefined
  ) =>
  state => {
    return pipe(
      allOrders,
      selectList ? filter(filterSellerName(selectList)) : defaultValues,
      isDispatched
        ? filter(filterIsDipatched(isDispatched))
        : defaultValues,
      _accountingStatement
        ? filter(filterAccountingStatement(_accountingStatement))
        : defaultValues
    )(state)
  }

export const { updateOrders } = orderSlice.actions
