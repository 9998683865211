/* eslint-disable linebreak-style */
import PropTypes from 'prop-types'

import Logo from '../../atoms/Logo'
import Carousel from '../../molecules/Carousel/Carousel'
import {
  Center,
  Container,
  DescriptionText,
  LeftContainer,
  LogoContainer,
  OnboardingImage,
  RightContainer,
  StyledButton,
  TitleTextNoWrap,
  TitleTextUser,
} from './onboarding.styles'
import { useAuth } from '../../../hooks/useAuth'

const OnboardingImg = require('../../../assets/onboarding/onboarding1.png')

const Onboarding1 = ({ navigate }) => {
  const handleNavigate = () => {
    navigate('/onboarding/2')
  }
  const { user } = useAuth()
  return (
    <Center>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Container>
        <LeftContainer>
          <OnboardingImage src={OnboardingImg} alt="onboarding2" />
        </LeftContainer>
        <RightContainer>
          <TitleTextUser>¡Hola {user?.displayName}!</TitleTextUser>
          <TitleTextNoWrap>Bienvenido a Seller Center</TitleTextNoWrap>
          <TitleTextNoWrap>KITCHEN CENTER</TitleTextNoWrap>
          <DescriptionText>
            Podrás gestionar tus ventas en un solo lugar.
          </DescriptionText>
        </RightContainer>
      </Container>
      <StyledButton variant="contained" onClick={handleNavigate}>
        continuar
      </StyledButton>
      <Carousel />
    </Center>
  )
}

Onboarding1.propTypes = {
  navigate: PropTypes.func,
}

export default Onboarding1
