import 'dayjs/locale/es'
import 'react-datepicker/dist/react-datepicker.css'

import DatePicker from 'react-datepicker'
import styled from 'styled-components'

const Wrapper = styled.div``
const DatePickerStyled = styled(props => (
  <DatePicker
    selectsRange={true}
    dateFormat="dd/MM/yyyy"
    maxDate={new Date()}
    monthsShown={2}
    peekNextMonth
    showMonthDropdown
    dropdownMode="scroll"
    {...props}
  />
))`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  border-top-left-radius: ${({ withborder }) =>
    withborder === 'true' ? '0px' : '5px'};
  border-bottom-left-radius: ${({ withborder }) =>
    withborder === 'true' ? '0px' : '5px'};
  border-top-right-radius: ${({ withborder }) =>
    withborder === 'true' ? '5px' : '0px'};
  border-bottom-right-radius: ${({ withborder }) =>
    withborder === 'true' ? '5px' : '0px'};
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 1rem;
  color: ${({ theme }) => theme.palette.text.primary};
  min-width: auto;
  max-width: 100%;
  width: 11rem;
`

const InputRangeDate = props => {
  return (
    <Wrapper>
      <DatePickerStyled {...props} />
    </Wrapper>
  )
}

InputRangeDate.displayName = 'InputRangeDate'
export default InputRangeDate
