/* eslint-disable linebreak-style */
import PropTypes from 'prop-types'

import Logo from '../../atoms/Logo'
import Carousel from '../../molecules/Carousel/Carousel'
import persistentStore from '../../../../data/persistentStore'
import userServices from '../../../../data/services/userServices'
import { useState } from 'react'

import {
  Center,
  Container,
  DescriptionContainer,
  DescriptionText,
  LeftContainer,
  LogoContainer,
  OnboardingImage,
  RightContainer,
  Spinner,
  StyledButton,
  TitleText,
} from './onboarding.styles'
import { useAuth } from '../../../hooks/useAuth'

const OnboardingImg = require('../../../assets/onboarding/onboarding5.png')

const Onboarding5 = ({ navigate }) => {
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()
  const handleNavigate = async () => {
    setLoading(true)
    const token = persistentStore.getToken()

    await userServices.firstLogin({
      uid: user.uid,
      token,
    })

    return navigate('/productos')
  }
  return (
    <Center>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Container>
        <LeftContainer>
          <OnboardingImage src={OnboardingImg} alt="onboarding4" />
        </LeftContainer>
        <RightContainer>
          <TitleText>¿Tienes dudas?</TitleText>
          <DescriptionContainer>
            <DescriptionText>
              Te podemos ayudar a resolver tus preguntas a través del
              correo de se te ha entregado en la capacitación inicial.
            </DescriptionText>
          </DescriptionContainer>
        </RightContainer>
      </Container>
      <StyledButton
        variant="contained"
        onClick={handleNavigate}
        disabled={loading}
        endIcon={loading ? <Spinner size="1.2rem" /> : null}
      >
        Continuar
      </StyledButton>
      <Carousel />
    </Center>
  )
}

Onboarding5.propTypes = {
  navigate: PropTypes.func,
}

export default Onboarding5
