import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import styled from 'styled-components'
import ButtonDeleteFile from '../../atoms/ButtonDeleteFile/ButtonDeleteFile'
import ButtonFile from '../../atoms/ButtonFile/ButtonFile'
import Tooltip from '../../atoms/ToolTip/ToolTip'
import TextError from '../../atoms/Typography/TextError'

const Column = styled('div')`
  display: flex;
  width: 100%;
`
const Container = styled(Column)`
  margin-bottom: 2%;
  justify-content: space-between;
`
const ColumnRight = styled(Column)`
  flex-direction: column;
`

const ButtonFileTooltip = ({ tooltipTitle, ...props }) => (
  <Tooltip title={tooltipTitle} followCursor={true}>
    <div>
      <ButtonFile {...props} />
    </div>
  </Tooltip>
)
ButtonFileTooltip.propTypes = {
  tooltipTitle: PropTypes.string,
}

const FieldFile = ({
  name,
  label,
  tooltipTitle,
  allFiles,
  handleDeleteFile,
  control,
  register,
  errors,
}) => {
  const watchFile = useWatch({
    control,
    name,
  })

  return (
    <>
      <Container>
        <Column>
          <ButtonFileTooltip
            title={label}
            tooltipTitle={
              watchFile && watchFile[0] ? 'modificar' : tooltipTitle
            }
            name={name}
            register={register}
          />
        </Column>
        <Column>
          <ColumnRight>
            {watchFile || (allFiles.length > 0 && allFiles[name]) ? (
              <ButtonDeleteFile
                title={allFiles[name]?.name || watchFile[0]?.name}
                url={allFiles[name]?.value || watchFile[0]?.name}
                name={name}
                onClick={handleDeleteFile}
              />
            ) : null}
            {errors && errors[name] ? (
              <TextError>{errors[name]?.message}</TextError>
            ) : null}
          </ColumnRight>
        </Column>
      </Container>
    </>
  )
}
FieldFile.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  allFiles: PropTypes.object,
  handleFileChange: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  register: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.object,
}
FieldFile.defaultProps = {
  tooltipTitle: 'Adjuntar archivo (PDF)',
}
export default FieldFile
