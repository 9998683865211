import { createAsyncThunk } from '@reduxjs/toolkit'
import categoryServices from '../../../data/services/categoryServices'
import axios from 'axios'

const fetchCategory = createAsyncThunk(
  'category',
  async (_next, { rejectWithValue, signal }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })

    try {
      const categories = await categoryServices.getCategories({
        cancelToken: source.token,
      })

      return categories
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState().category

      if (state?.status === 'finished') {
        return false
      }
    },
  }
)
export default fetchCategory
