import styled from 'styled-components'

const Card = styled('div')`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;

  &::-webkit-scrollbar-track {
    background-color: #f6f6f7;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #cbcbcb;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cbcbcb;
    border-radius: 3.62694px;
    width: 10px;
  }

  &::-webkit-slider-thumb {
    background-color: #cbcbcb;
    background-image: -webkit-linear-gradient(
      90deg,
      rgb(239, 240, 242, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgb(239, 240, 242, 0.2) 50%,
      rgb(239, 240, 242, 0.2) 75%,
      transparent 75%,
      transparent
    );
  }

  /**Hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #cbcbcb;
  }
  /**Active */
  ::-webkit-scrollbar-thumb:active {
    background-color: #cbcbcb;
  }

  ::-webkit-scrollbar-track:hover,
  ::-webkit-scrollbar-track:active {
    background: #f6f6f7;
  }
`

Card.displayName = 'Card'
export default Card
