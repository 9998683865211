import config from '../firebase/firebase.config'

const DB_NAME = 'firebaseLocalStorageDb'
const DB_VERSION = 1
const STORE_NAME = 'firebaseLocalStorage'
const firebaseKey = `firebase:authUser:${config?.apiKey}:[DEFAULT]`

export function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION)

    request.onerror = () => {
      reject('Error opening IndexedDB')
    }

    request.onsuccess = event => {
      resolve(event.target.result)
    }

    request.onupgradeneeded = event => {
      const db = event.target.result

      db.createObjectStore(STORE_NAME, { keyPath: 'fbase_key' })
    }
  })
}

export async function getFirebaseToken() {
  const db = await openDB()
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly')
    const store = transaction.objectStore(STORE_NAME)
    const request = store.get(firebaseKey)

    request.onsuccess = event => {
      const result = event.target.result

      if (result) {
        const { accessToken, expirationTime } =
          result.value.stsTokenManager

        resolve({ accessToken, expirationTime })
      } else {
        resolve(null)
      }
    }

    request.onerror = () => {
      reject('Error retrieving token')
    }
  })
}

export async function setToken(token) {
  const db = await openDB()
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite')
    const store = transaction.objectStore(STORE_NAME)
    const request = store.put({
      fbase_key: firebaseKey,
      value: { stsTokenManager: { accessToken: token } },
    })

    request.onsuccess = () => {
      resolve()
    }

    request.onerror = () => {
      reject('Error storing token')
    }
  })
}

export async function clearStoredAuth() {
  const db = await openDB()
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite')
    const store = transaction.objectStore(STORE_NAME)
    const request = store.delete(firebaseKey)

    request.onsuccess = () => {
      resolve()
    }

    request.onerror = () => {
      reject('Error clearing auth store indeDB')
    }
  })
}
