import { debounce } from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'
import fetchProductExportAllById from '../productExportAllById/fetchProductExportAllById'

const recursiveFetch = debounce(async (dispatch, bulkId) => {
  let rejected
  const data = await dispatch(fetchProductExportAllById({ bulkId }))
  rejected = data.meta.requestStatus
  if (rejected === 'rejected') {
    return await recursiveFetch(dispatch, bulkId)
  }
  return true
}, 2000)

const fetchProductListDownload = createAsyncThunk(
  'product/export',
  async (
    { brands, status, category, query = '' },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      let brandList = getState().seller.detail.brandOnlyNameList

      if (brands.length) {
        brandList = brands
      }

      const brandsAll = getState().seller.detail.brandsAll

      const response = await productAdminServices.exportProductAllData({
        brands: brandList,
        status,
        category,
        brandsAll,
        title: query,
        tag: getState().seller.detail.tag,
      })
      if (response.error) {
        return rejectWithValue('Operación bulk falló')
      }
      recursiveFetch(dispatch, response.id)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchProductListDownload
