import { initializeApp } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  setPersistence,
  indexedDBLocalPersistence,
  getIdTokenResult,
  getIdToken,
  signOut,
  signInWithCredential,
  GoogleAuthProvider,
  EmailAuthProvider,
  onIdTokenChanged,
} from 'firebase/auth'
import firebaseConfig from './firebase.config'

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
auth.languageCode = 'es'
setPersistence(auth, indexedDBLocalPersistence)

export {
  auth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getIdTokenResult,
  getIdToken,
  signOut,
  signInWithCredential,
  setPersistence,
  indexedDBLocalPersistence,
  GoogleAuthProvider,
  EmailAuthProvider,
  onIdTokenChanged,
}

export default auth
