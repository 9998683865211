export const normalizeVariantInventoryLevel = variant => {
  const { inventoryItem } = variant
  return {
    ...variant,
    currentAvailable: inventoryItem?.inventoryLevel?.available || 0,
  }
}

export const getVariantInventoryLevel = variants => {
  return variants?.map(normalizeVariantInventoryLevel)
}
