import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import {
  addOneStatus,
  removeOneStatus,
  removeAllStatus,
  allStatus,
} from '../../../../domain/features/ui/dispatchedSlice'

import SelectWithChips from '../../molecules/SelectWithChips'

const Select = styled(SelectWithChips)`
  max-width: 14rem;
  min-width: auto;
`

const SelectDispatched = () => {
  const value = useSelector(allStatus)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    event => {
      return event.target.value !== 'borrar'
        ? dispatch(addOneStatus(event.target.value))
        : dispatch(removeAllStatus())
    },
    [dispatch]
  )

  const handleDelete = useCallback(
    value => {
      dispatch(removeOneStatus(value))
    },
    [dispatch]
  )

  return (
    <>
      <Select
        label="Estado despacho"
        isCheckbox
        isallfilter={true}
        value={value || []}
        onChange={handleChange}
        list={[
          'No Despachado',
          'Despachado',
          'Ingresado',
          'Rechazado',
          'Devuelto',
          'Cancelado',
          'Facturado',
        ]}
        handleDeleteChip={handleDelete}
        handleDeleteAll={value => dispatch(removeAllStatus(value))}
      />
    </>
  )
}

export default SelectDispatched
