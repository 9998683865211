import client from '../client/client'

const locationServices = (baseUrl, endpoint) => ({
  getLocationList: async ({ cancelToken }) => {
    const response = await client(`${baseUrl}/${endpoint}`, {
      cancelToken,
    })

    return response.data
  },
})

export default locationServices(
  process.env.REACT_APP_API_PRODUCT_PATH,
  'product/locations'
)
