import Paper from '@mui/material/Paper'
import styled from 'styled-components'

const CardProduct = styled(Paper)`
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  border-radius: 8px;
  padding: 58px;
  margin-bottom: 22px;
  @media (max-width: 1100px) {
    width: 100%;
    height: 100%;
    padding: 5%;
  }
`
export default CardProduct
