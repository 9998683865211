import { useState, useCallback } from 'react'
import { Container } from './styles'
import ImageWithEmoji from './ImageWithEmoji'
import MessageQueue from './MessageQueue'
import logoWeb from '../../../../assets/logoWeb.webp'
import logoSVG from '../../../../assets/logo.svg'

const AuthValid = () => {
  const [emoji, setEmoji] = useState('🔒')

  const handleEmojiChange = useCallback(newEmoji => {
    setEmoji(newEmoji)
  }, [])

  return (
    <Container>
      <ImageWithEmoji
        webpSrc={logoWeb}
        svgSrc={
          logoSVG ||
          'https://www.kitchencenter.cl/cdn/shop/files/kitchencenter_logo.svg?v=1676480309&width=180'
        }
        alt="logo-kc"
        emoji={emoji}
      />
      <MessageQueue onEmojiChange={handleEmojiChange} />
    </Container>
  )
}

export default AuthValid
