import * as yup from 'yup'

export const schema = yup
  .object({
    email: yup
      .string()
      .email('El email ingresado no es válido')
      .max(255)
      .required('Ingresé su email, por favor'),
    password: yup.string().required('Ingresé su contraseña, por favor'),
  })
  .required()
