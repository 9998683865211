import styled from 'styled-components'
import { Box } from '@mui/material'
import ButtonDownloadOptions from '../../atoms/ButtonDownloadOptions/ButtonDownloadOptions'
import PropTypes from 'prop-types'

const DownloadContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;

  margin-left: 0.5rem;
  @media (max-width: 700px) {
    margin-left: 0rem;
  }
`

const FilterLiquidationDowload = ({
  children,
  onDownloadExcel,
  onDowloadExcelDetail,
  enabledDownload,
}) => {
  return (
    <Box pt="25px" ml="20px" mr="20px" mb="20px">
      <Box
        flexDirection="row"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent={'space-between'}
        width={'100%'}
      >
        {children}
        {onDownloadExcel && (
          <DownloadContainer>
            <ButtonDownloadOptions
              onClick={onDownloadExcel}
              disabled={!enabledDownload}
              type={'select'}
              options={[
                {
                  label: 'Listado liquidaciones',
                  onClick: onDownloadExcel,
                },
                {
                  label: 'Listado liquidaciones con detalle de pedidos',
                  onClick: onDowloadExcelDetail,
                },
              ]}
            />
          </DownloadContainer>
        )}
      </Box>
    </Box>
  )
}
FilterLiquidationDowload.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
  onDownloadExcel: PropTypes.func,
  onDowloadExcelDetail: PropTypes.func,
  enabledDownload: PropTypes.bool,
}
FilterLiquidationDowload.defaultProps = {
  onDownloadExcel: undefined,
  onDowloadExcelDetail: undefined,
}
export default FilterLiquidationDowload
