import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchProductImport from './fetchProductImport'

export const productImportAdpater = createEntityAdapter()

export const productImportSlice = createSlice({
  name: 'product/import',
  initialState: productImportAdpater.getInitialState({
    status: 'idle',
    url: {},
    errors: null,
  }),
  reducers: {
    resetProductImport(state) {
      state.status = 'idle'
      state.errors = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProductImport.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchProductImport.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errors = payload
    })
    builder.addCase(fetchProductImport.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.url = payload
    })
  },
})
export const { resetProductImport } = productImportSlice.actions
const reducer = productImportSlice.reducer
export default reducer
