/* eslint-disable no-console */
import { useEffect, useState, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'

const useInstallPWA = () => {
  const promptEvent = window.deferredPrompt
  const [isSupportsPWA, setSupportsPWA] = useState(false)
  const [promptInstall, setPromptInstall] = useState(promptEvent || null)

  const isValidPWA = useCallback(() => setSupportsPWA(true), [])

  const handlerPrompt = useCallback(
    e => {
      e.preventDefault()
      console.info('Captura de A2HS 🧐')
      window.deferredPrompt = e
      setPromptInstall(e)
      isValidPWA()
    },
    [isValidPWA]
  )

  const onPrompt = useCallback(
    e => {
      e.preventDefault()
      if (!promptInstall) {
        console.log('oops, no prompt event guardado en window')
        return null
      }
      promptInstall.prompt()
      promptInstall.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.info('El usuario aceptó el aviso de A2HS 🥳')
          setSupportsPWA(false)
        } else {
          console.info('El usuario descartó la solicitud de A2HS 😕')
          setSupportsPWA(true)
          setPromptInstall(null)
        }
      })
    },
    [promptInstall]
  )

  useEffect(() => {
    // https://web.dev/customize-install/#beforeinstallprompt
    window.addEventListener('beforeinstallprompt', handlerPrompt)
    // https://web.dev/customize-install/#detect-install
    window.addEventListener('appinstalled', e => {
      e.preventDefault()
      console.info('El usuario instaló el aviso de A2HS 🤩')
      setSupportsPWA(false)
    })

    if (!isEmpty(promptInstall)) {
      setSupportsPWA(true)
    }

    return () => window.removeEventListener('transitionend', handlerPrompt)
  }, [handlerPrompt, promptInstall])

  return { isSupportsPWA, onInstallApp: onPrompt }
}

export default useInstallPWA
