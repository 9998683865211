import { useContext, createContext } from 'react'
import PropTypes from 'prop-types'

const inistialState = {
  isUpdateWorker: false,
  isSuccessWorker: false,
  reloadPage: () => undefined,
}

const ServiceWorkerContext = createContext(inistialState)

export const ProvideWorker = ({ children, ...rest }) => {
  return (
    <ServiceWorkerContext.Provider {...rest}>
      {children}
    </ServiceWorkerContext.Provider>
  )
}

ProvideWorker.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
}
ProvideWorker.defaultProps = { children: null }

export const useWorker = () => useContext(ServiceWorkerContext)
