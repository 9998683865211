import AccordionMUi from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const AccordionStyled = styled(AccordionMUi)`
  &.MuiAccordion-root:before {
    background-color: transparent;
  }
  .MuiAccordionSummary-root {
    padding: 0;
    width: 100%;
    background-color: transparent;
    min-height: auto;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiIconButton-edgeEnd {
    position: absolute;
    left: 10rem;
  }
  .MuiAccordionDetails-root {
    padding-top: 0px;
  }
`

export default function Accordion({ title, children }) {
  return (
    <AccordionStyled elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {title && title()}
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0, margin: 0 }}>
        {children}
      </AccordionDetails>
    </AccordionStyled>
  )
}
Accordion.propTypes = {
  title: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
}
