import TableCell from '@mui/material/TableCell'
import styled from 'styled-components'

const CellHeader = styled(TableCell)`
  padding: 0px;
  padding-left: 20px;
  padding-right: 10px;
  background-color:: ${({ theme }) => theme.palette.background.default};
`
CellHeader.displayName = 'CellHeader'
export default CellHeader
