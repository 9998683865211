import PropTypes from 'prop-types'
import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import Text from '../../atoms/Typography/Text'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteDocumentList,
  deleteIdle,
  downloadIdle,
  getDeleteDocument,
  getDownloadDocument,
  getUpdateDocument,
  updateDocumentList,
} from '../../../../domain/features/orders/orderSlice'
import LoadDocument from '../LoadDocument/LoadDocument'
import Alert, { typeAlert } from '../../atoms/Alert/Alert'
import { deleteDocument } from '../../../../domain/features/orderDetail/orderDetailSlice'
import Collapse from '@mui/material/Collapse'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import ButtonCopy from '../../atoms/ButtonCopy/ButtonCopy'
import Tooltip from '@mui/material/Tooltip'

const TextNormal = styled(Text)`
  font-size: 14px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 0.2px;
  padding: 0px;
  cursor: text;
  margin: 0px;
`

const TextUnselected = styled(TextNormal)`
  color: #d1d0d0;
  font-weight: bold;
  font-size: 18px;
`
const TextNormalSemiBold = styled('span')`
  font-weight: 400;
  cursor: text;
  line-break: anywhere;
`
const TitleProduct = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  cursor: text;
`
const TitleProductBold = styled(TitleProduct)`
  font-weight: bold;
  cursor: text;
`
const TitleProductBoldItalic = styled(TitleProductBold)`
  font-style: italic;
`

const ItemCard = styled(Paper)`
  padding-top: 16px;
  padding-left: 5%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-bottom: 1rem;
  box-shadow: 0px 0.836312px 3.34525px rgba(33, 33, 33, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  @media (max-width: 1100px) {
    padding-left: 1rem;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`

const TitleStatus = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-weight: 700;
  font-size: 23px;
  line-height: 23px;
  margin-bottom: 20px;
  cursor: text;
`

const Divider = styled('div')`
  margin-bottom: 1.5rem;
  margin-right: 1rem;
`

const ItemCardTribute = styled(ItemCard)`
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  padding-left: 5%;
  padding-right: 5%;
  @media (max-width: 1100px) {
    padding-top: 1rem;
    padding-bottom: 3rem;
    padding-left: 4%;
    padding-right: 4%;
  }
`
const ItemCardBillingEffect = styled(ItemCard)`
  box-shadow: ${({ ispress }) =>
    ispress === 'true'
      ? '0px 12.836312px 14.34525px rgba(33, 33, 33, 0.25)'
      : '0px 0.836312px 3.34525px rgba(33, 33, 33, 0.25)'};
`
const BillingType = styled('div')`
  display: grid;
  flex-direction: row;
  align-items: center;
`
const SuccessIcon = styled(CheckCircleIcon)`
  margin-right: 0.5rem;
  fill: ${({ theme }) => theme.palette.success.dark};
`
const ILoadDocument = styled('div')`
  cursor: pointer;
  float: left;
`

const ContainerDestintion = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
`

const RowSelect = styled('div')`
  display: flex;
  justify-content: baseline;
  flex-direction: row;
`

const CardBillingOrder = ({
  document_type,
  billing,
  delivery,
  document_name,
  id_order,
  isSelectBilling = false,
}) => {
  const dispatch = useDispatch()
  const [showDocumentDownload, setShowDocumentDownload] = useState(false)
  const [showUpdateDocument, setShowUpdateDocument] = useState(false)
  const [showDeleteDocument, setShowDeleteDocument] = useState(false)
  const { status: updateDocumentStatus } = useSelector(getUpdateDocument)

  const { status: downloadDocumentStatus } = useSelector(
    getDownloadDocument
  )
  const { status: deleteDocumentStatus } = useSelector(getDeleteDocument)

  useEffect(() => {
    if (
      updateDocumentStatus === 'loading' ||
      updateDocumentStatus === 'error'
    ) {
      setShowUpdateDocument(true)
    } else if (updateDocumentStatus === 'finished') {
      dispatch(updateDocumentList(id_order))
      setTimeout(() => {
        setShowUpdateDocument(false)
      }, 5000)
    } else {
      setShowUpdateDocument(false)
    }
    if (downloadDocumentStatus === 'loading') {
      setShowDocumentDownload(true)
    } else if (downloadDocumentStatus === 'finished') {
      setShowDocumentDownload(true)
      setTimeout(() => {
        dispatch(downloadIdle())
        setShowDocumentDownload(false)
      }, 5000)
    } else {
      setShowDocumentDownload(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDocumentStatus, downloadDocumentStatus, dispatch])
  useEffect(() => {
    if (deleteDocumentStatus === 'loading') {
      setShowDeleteDocument(true)
    } else if (deleteDocumentStatus === 'finished') {
      dispatch(deleteDocument(id_order))
      dispatch(deleteDocumentList(id_order))
      setShowDeleteDocument(true)
      setTimeout(() => {
        setShowDeleteDocument(false)
        dispatch(deleteIdle())
      }, 5000)
    } else if (deleteDocumentStatus === 'error') {
      setTimeout(() => {
        setShowDeleteDocument(false)
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDocumentStatus, dispatch])

  if (document_type === null) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rectangular" height={210} />
        <Skeleton variant="rectangular" height={300} />
      </Stack>
    )
  }

  const getCopyText = () => {
    return `${delivery?.address}, ${delivery?.destination_county}, ${delivery?.destination_region}`
  }

  const getCopyTextBilling = () => {
    return `
      Documento Tributario: ${document_type === 'factura' ? 'FACTURA' : 'BOLETA'}

      Nombre o razón social: ${billing?.name || '-'}
      Rut: ${billing?.rut || '-'}
      Giro: ${delivery?.giro || ''}
      Mail: ${billing?.mail || ''}
      Dirección de despacho:: ${billing?.address || '-'}
      Comuna: ${billing?.destination_county || '-'}
      Región: ${billing?.destination_region || '-'}

     ${billing?.address}, ${billing?.destination_county}, ${billing?.destination_region}
    `
  }

  return (
    <>
      <ItemCardBillingEffect ispress={String(isSelectBilling)}>
        <TitleStatus>Documento Tributario</TitleStatus>

        <div>
          <Tooltip
            title={`El documento se envía automaticamente al correo del cliente ${billing?.name}`}
            followCursor={true}
          >
            <BillingType>
              <RowSelect>
                <SuccessIcon fontSize="small" />
                <TitleProductBold>
                  {document_type === 'factura' ? 'FACTURA' : 'BOLETA'}
                </TitleProductBold>
              </RowSelect>
            </BillingType>
            <BillingType>
              <TextUnselected>
                {document_type === 'factura' ? 'BOLETA' : 'FACTURA'}
              </TextUnselected>
            </BillingType>
          </Tooltip>
          <ILoadDocument>
            <LoadDocument
              ispress={isSelectBilling}
              value={document_name}
              name={id_order}
              label={document_type}
              disabled={downloadDocumentStatus === 'loading'}
            />
          </ILoadDocument>
        </div>

        <Divider />

        <Collapse
          in={Boolean(document_type && document_type === 'factura')}
        >
          <Divider />
          <ContainerDestintion>
            <TitleProductBoldItalic>
              Detalles de facturación
            </TitleProductBoldItalic>
            <ButtonCopy
              title="Copiar detalles facturación"
              getText={getCopyTextBilling}
              message="Detalles facturación copiados!!"
            />
          </ContainerDestintion>
          <TextNormal>
            Rut: <TextNormalSemiBold>{delivery?.rut}</TextNormalSemiBold>
          </TextNormal>

          <TextNormal>
            Nombre o razón social:{' '}
            <TextNormalSemiBold>{billing?.name}</TextNormalSemiBold>
          </TextNormal>
          <TextNormal>
            Giro: <TextNormalSemiBold>{billing?.giro}</TextNormalSemiBold>
          </TextNormal>
          <TextNormal>
            Dirección tributaria:{' '}
            <TextNormalSemiBold>{billing?.address}</TextNormalSemiBold>
          </TextNormal>
          <TextNormal>
            Comuna:{' '}
            <TextNormalSemiBold>
              {billing?.destination_county}
            </TextNormalSemiBold>
          </TextNormal>
          <TextNormal>
            Región:{' '}
            <TextNormalSemiBold>
              {billing?.destination_region}
            </TextNormalSemiBold>
          </TextNormal>
          <TextNormal>
            Mail: <TextNormalSemiBold>{billing?.mail}</TextNormalSemiBold>
          </TextNormal>
        </Collapse>
      </ItemCardBillingEffect>

      <Collapse in={isObject(delivery) && !isEmpty(delivery)}>
        <ItemCardTribute>
          <ContainerDestintion>
            <TitleStatus>Detalles de entrega</TitleStatus>
            <ButtonCopy
              title="Copiar dirección"
              getText={getCopyText}
              message={`Dirección de ${delivery?.name} copiada!!`}
            />
          </ContainerDestintion>
          <TextNormal>
            Nombre:{' '}
            <TextNormalSemiBold>{delivery?.name}</TextNormalSemiBold>
          </TextNormal>
          <TextNormal>
            Rut: <TextNormalSemiBold>{delivery?.rut}</TextNormalSemiBold>{' '}
          </TextNormal>
          <TextNormal>
            Dirección de despacho:{' '}
            <TextNormalSemiBold>{delivery?.address}</TextNormalSemiBold>
          </TextNormal>
          <TextNormal>
            Comuna:{' '}
            <TextNormalSemiBold>
              {delivery?.destination_county}
            </TextNormalSemiBold>
          </TextNormal>
          <TextNormal>
            Región:{' '}
            <TextNormalSemiBold>
              {delivery?.destination_region}
            </TextNormalSemiBold>
          </TextNormal>
        </ItemCardTribute>
      </Collapse>

      <Alert
        key="upload-document"
        open={
          showUpdateDocument &&
          (updateDocumentStatus === 'error' ||
            updateDocumentStatus === 'loading')
        }
        onClose={() => {
          setShowUpdateDocument(false)
        }}
        title={
          updateDocumentStatus === 'loading'
            ? 'subiendo archivo'
            : updateDocumentStatus === 'error'
              ? 'Se ha producido un error'
              : 'Archivo descargado exitosamente'
        }
        isLoading={updateDocumentStatus === 'loading'}
        isError={updateDocumentStatus === 'error'}
        severity={
          updateDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />

      <Alert
        key="download-document"
        open={
          showDocumentDownload &&
          (downloadDocumentStatus === 'finished' ||
            downloadDocumentStatus === 'error' ||
            downloadDocumentStatus === 'loading')
        }
        onClose={() => {
          dispatch(downloadIdle())
          setShowDocumentDownload(false)
        }}
        title={
          downloadDocumentStatus === 'error'
            ? 'Se ha producido un error'
            : downloadDocumentStatus === 'loading'
              ? 'Descargando archivo'
              : 'Archivo descargado exitosamente'
        }
        isLoading={downloadDocumentStatus === 'loading'}
        isError={downloadDocumentStatus === 'error'}
        severity={
          downloadDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />
      <Alert
        key="delete-document"
        open={
          showDeleteDocument &&
          (deleteDocumentStatus === 'finished' ||
            deleteDocumentStatus === 'error' ||
            deleteDocumentStatus === 'loading')
        }
        onClose={() => {
          dispatch(deleteIdle())
          setShowDeleteDocument(false)
        }}
        title={
          deleteDocumentStatus === 'error'
            ? 'Se ha producido un error'
            : deleteDocumentStatus === 'loading'
              ? 'Eliminando archivo'
              : deleteDocumentStatus === 'finished'
                ? 'Archivo eliminado exitosamente'
                : ''
        }
        isLoading={deleteDocumentStatus === 'loading'}
        isError={deleteDocumentStatus === 'error'}
        severity={
          deleteDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />
    </>
  )
}
CardBillingOrder.propTypes = {
  document_type: PropTypes.string,
  id_order: PropTypes.string,
  document_name: PropTypes.string,
  billing: PropTypes.shape({
    address: PropTypes.string,
    name: PropTypes.string,
    giro: PropTypes.string,
    rut: PropTypes.string,
    destination_county: PropTypes.string,
    destination_region: PropTypes.string,
    mail: PropTypes.string,
  }),
  delivery: PropTypes.shape({
    address: PropTypes.string,
    name: PropTypes.string,
    rut: PropTypes.string,
    destination_county: PropTypes.string,
    destination_region: PropTypes.string,
    giro: PropTypes.string,
  }),
  isSelectBilling: PropTypes.bool,
}

CardBillingOrder.defaultProps = {
  id_order: 'id',
  document_type: 'boleta',
  isSelectBilling: false,
}
export default CardBillingOrder
