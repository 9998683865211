import client from '../client/client'

const sellerService = (baseUrl, endpoint) => ({
  bySlug: async ({ slugSeller }) => {
    const response = await client(`${baseUrl}/${endpoint}/${slugSeller}`)
    return response.data
  },
})

export default sellerService(
  process.env.REACT_APP_API_COMMISIONS_PATH,
  'sellers'
)
