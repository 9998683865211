import client from '../client/client'
import persistentStore from '../persistentStore'

const liquidationService = (baseUrl, endpoint) => ({
  filter: async ({
    startDate,
    endDate,
    skip,
    limit,
    accountingStatement = undefined,
    cancelToken,
  }) => {
    let queryParams = ''
    if (limit !== -1) {
      queryParams = `limit=${limit}`
    }
    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }
    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (skip) {
      queryParams += `&skip=${skip}`
    }

    try {
      const response = await client(
        `${baseUrl}/${endpoint}/by-seller?${queryParams}`,
        { cancelToken }
      )

      return response.data
    } catch (error) {
      return error
    }
  },
  export: async ({
    startDate = undefined,
    endDate = undefined,
    accountingStatement = undefined,
    sellers = undefined,
    detailed,
    cancelToken,
  }) => {
    let queryParams = ''

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate}`
    }

    if (sellers) {
      queryParams += `&seller=${encodeURIComponent(sellers)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (detailed) {
      queryParams += '&detailed=true'
    }

    try {
      const response = fetch(
        `${baseUrl}/${endpoint}/download?${queryParams}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: persistentStore.getToken(),
            app: process.env.REACT_APP_APPLICATION_NAME,
          },
          cancelToken,
        }
      )
      const data = (await response).json()
      return data
    } catch (error) {
      return error
    }
  },
})

export default liquidationService(
  process.env.REACT_APP_API_SETTLEMENT_PATH,
  'liquidation'
)
