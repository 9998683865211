import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Autocomplete from '../../atoms/Autocomplete/Autocomplete'
import Input from '../../atoms/Input'

const MenuItemStyled = styled(MenuItem)`
  padding-top: 11px;
  min-width: 250px;
  max-width: 250px;
  color: ${({ theme }) => theme?.palette?.text?.primary};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    color: #d1d0d0;
    border-color: #d1d0d0;
    pointer-events: auto;
  }
`

const ContainerSelect = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;

  .MuiSelect-selectMenu {
    min-height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  .MuiSelect-disabled {
    cursor: not-allowed;
    color: #d1d0d0;
    border-color: #d1d0d0;
    pointer-events: auto;
  }
`

const CheckboxStyled = styled(Checkbox)`
  width: 18px;
  height: 18px;
  padding-right: 20px;
`

const InputStyled = styled(Input)`
  height: 36px;
  padding: 0;
  width: 100%;
  display: block;
  color: ${({ theme }) => theme?.palette?.text?.primary};
`
const Li = styled('li')`
  color: ${({ theme }) => theme?.palette?.text?.primary};
  width: 100%;
  min-width: 200px;
  padding: 0px;
  position: relative;
  display: block;
  padding-bottom: ${({ isend }) => (isend === 'true' ? '40px' : '0px')};
`

const ContainerDelete = styled(MenuItemStyled)`
  cursor: pointer;
  z-index: 10;
  border-top-color: ${({ theme }) => theme?.palette?.primary?.light};
  border-top-style: solid;
  border-top-width: 1px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 0px;
  padding-left: 15px;
  margin-top: 10px;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: 1 !important;
  color: ${({ theme }) => theme?.palette?.secondary?.main};
  background-color: ${({ theme }) => theme?.palette?.third?.main};

  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
          color: #d1d0d0;
        `
      : css`
          cursor: pointer;
        `};

  &:hover && {
    background-color: ${({ theme }) =>
      theme?.palette?.background?.default};
  }

  &:disabled {
    cursor: not-allowed;
    color: #d1d0d0;
    border-color: #d1d0d0;
    pointer-events: auto;
  }
`

const Text = styled('p')`
  cursor: pointer;

  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
  width: 100%;
  display: inline-block;
  color: ${({ theme }) => theme?.palette?.secondary?.main};

  ${ContainerDelete}:hover & {
    background-color: ${({ theme }) =>
      theme?.palette?.background?.default};
    font-weight: bold;
  }
`

const MenuDelete = ({ ...props }) => (
  <ContainerDelete {...props}>
    <Text>Borrar selección</Text>
  </ContainerDelete>
)

const MenuDefault = ({ value, defaultValue, ...props }) => (
  <MenuItemStyled value={value} {...props}>
    <CheckboxStyled checked={defaultValue?.includes(value)} />
    {value}
  </MenuItemStyled>
)
MenuDefault.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
}

export default function CustomSelectStatus({
  selectCurrentItem,
  setSelectItem = x => x,
  label,
  defaultStatus = [''],
  loading,
  ...props
}) {
  const [options, setOptions] = useState([])
  const handleSelectBase = (list, set) => name => {
    if (list.some(item => item === name)) {
      const index = list.indexOf(name)
      const values = [...list]
      values.splice(index, 1)
      set(values)
    } else {
      set([...list, name])
    }
  }
  const handleSelect = handleSelectBase(selectCurrentItem, setSelectItem)

  useEffect(() => {
    if (defaultStatus && defaultStatus?.length > 0) {
      const current = []

      defaultStatus &&
        defaultStatus?.map((title, index) =>
          current?.push({ title, id: index })
        )
      current?.push({ title: 'Borrar selección', id: 9999 })
      setOptions(current)
    }
  }, [defaultStatus])

  return (
    <ContainerSelect variant="outlined">
      <Autocomplete
        {...props}
        loading={loading}
        label={label}
        selectCurrentItem={selectCurrentItem}
        defaultValue={selectCurrentItem}
        options={options}
        value={selectCurrentItem?.map(ss => {
          return { title: ss }
        })}
        onChange={(_, value) => {
          const isDelete = value?.find(field => field?.id === 9999)
          if (isDelete) {
            return setSelectItem([])
          }
          return value?.map(val => handleSelect(val?.title))
        }}
        getOptionLabel={option => option?.title}
        renderOption={(_, { title, id }) => (
          <Li
            key={`${title}-${id}-li`}
            isend={String(defaultStatus?.length === id + 1)}
          >
            {id !== 9999 && (
              <MenuDefault
                key={`${title}-${id}-MenuDefault`}
                value={title}
                defaultValue={selectCurrentItem}
                onClick={() => handleSelect(title)}
              />
            )}
            {id === 9999 && (
              <MenuDelete
                key={`${title}-${id}-MenuDelete`}
                onClick={() => setSelectItem([])}
                disabled={selectCurrentItem?.length === 0}
              />
            )}
          </Li>
        )}
        renderInput={params => (
          <InputStyled {...params} placeholder={label} />
        )}
        id={`id-autocomplete-${label}`}
        renderTags={() => {}}
        multiple
        disableClearable
        disableCloseOnSelect
      />
    </ContainerSelect>
  )
}

CustomSelectStatus.propTypes = {
  defaultStatus: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  selectCurrentItem: PropTypes.any,
  setSelectItem: PropTypes.any,
  label: PropTypes.string.isRequired,
  isfilterright: PropTypes.bool,
  isfilterleft: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  loading: PropTypes.any,
}
CustomSelectStatus.defaultProps = {
  isfilterright: false,
  isfilterleft: false,
  defaultStatus: [''],
}
