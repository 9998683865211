import { useDispatch, useSelector } from 'react-redux'
import {
  addStartDay,
  addEndDay,
  getCurrentDate,
} from '../../../../domain/features/ui/rangeOfDaysSlice'
import InputRangeDate from '../../atoms/InputBase/InputRangeDate/InputRangeDate'

const RangeOfDays = () => {
  const date = useSelector(getCurrentDate)
  const dispatch = useDispatch()
  const onChange = dates => {
    const [start, end] = dates
    dispatch(addStartDay(start))
    dispatch(addEndDay(end))
  }

  return (
    <InputRangeDate
      startDate={date[0]}
      endDate={date[1]}
      onChange={onChange}
      placeholderText="Selecciona una fecha"
    />
  )
}

export default RangeOfDays
