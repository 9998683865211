import InputAdornment from '@mui/material/InputAdornment'
import SearchRounded from '@mui/icons-material/SearchRounded'
import InputSearch from '../../atoms/InputBase/InputSearch/InputSearch'

const Search = props => {
  return (
    <InputSearch
      id="input-with-icon-textfield"
      placeholder="Buscar Pedido Marketplace"
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRounded />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default Search
