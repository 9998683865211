import styled from 'styled-components'
import PropTypes from 'prop-types'
import InputAreaController from '../../molecules/InputAreaController'
import VariantCheckboxImage from '../VariantCheckboxImage/VariantCheckboxImage'
import CardProduct from '../../atoms/CardProduct'
import TitleProduct from '../../atoms/Typography/TitleProduct'
import Label from '../../atoms/Label'
import FieldCategory from '../../molecules/FieldCategory'
import FieldBrand from '../../molecules/FieldBrand'
import FieldInputController from '../../molecules/FieldInputController'

const SubContainer = styled('div')`
  display: flex;
  width: 100%;
`
const SubContainerWrap = styled(SubContainer)`
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`

const SubContainerColumn = styled(SubContainer)`
  flex-direction: column;
  margin-bottom: 2%;
`

const SubContainerColumnMargined = styled(SubContainerColumn)`
  margin-right: -5%;
`

const ProductInfoForm = ({
  setError,
  clearErrors,
  control,
  errors,
  mediaList,
  isDetailsView,
  productId,
}) => {
  return (
    <CardProduct>
      <TitleProduct>Información General</TitleProduct>
      <SubContainerColumn>
        <Label required>Nombre producto</Label>
        <FieldInputController
          control={control}
          errors={errors}
          name="productName"
          placeholder="Nombre producto"
          helperText="El nombre del producto no debe llevar la marca y debe ser en mayúscula la primera letra y el resto en minúscula."
        />
      </SubContainerColumn>
      <SubContainerWrap>
        <SubContainerColumn>
          <FieldCategory control={control} defaultValue="" />
        </SubContainerColumn>
        <SubContainerColumnMargined>
          <FieldBrand control={control} defaultValue="" />
        </SubContainerColumnMargined>
      </SubContainerWrap>
      <SubContainerColumn>
        <Label required>Descripción</Label>
        <InputAreaController
          control={control}
          errors={errors}
          name="detailedDescription"
          placeholder="Descripción del producto..."
          helperText="Se debe invitar al cliente a comprar el producto pero con mayor detalle, sin hacer referencia a características específicas. Máximo de 850 palabras."
          multiline
          rows={5}
        />
      </SubContainerColumn>
      <SubContainerColumn>
        <Label>Imágenes del producto </Label>
        <VariantCheckboxImage
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          mediaList={mediaList}
          control={control}
          isDetailsView={isDetailsView}
          productId={productId}
        />
      </SubContainerColumn>
    </CardProduct>
  )
}

ProductInfoForm.propTypes = {
  productId: PropTypes.string,
  register: PropTypes.any,
  setValue: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  control: PropTypes.any,
  errors: PropTypes.object,
  mediaList: PropTypes.array,
  isDetailsView: PropTypes.bool,
}

export default ProductInfoForm
