import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import subOrderService from '../../../data/services/subOrderService'

const fetchDeleteDocument = createAsyncThunk(
  'orders/delete/document',
  async ({ orderId }, { signal }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    const result = await subOrderService.documentDelete({ orderId })
    return result
  }
)
export default fetchDeleteDocument
