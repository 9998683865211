import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import InputEmail from '../../atoms/InputEmail/InputEmail'

const InputEmailController = ({
  showError,
  errors,
  label,
  control,
  disabled,
  email = '',
}) => {
  return (
    <Controller
      control={control}
      name="email"
      defaultValue={email}
      render={({ field: { onChange, onBlur, ref, ...rest } }) => (
        <InputEmail
          {...{ showError, errors, label, disabled, email }}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          {...rest}
        />
      )}
    />
  )
}

export default InputEmailController

InputEmailController.propTypes = {
  showError: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string,
  control: PropTypes.any,
  disabled: PropTypes.bool,
  email: PropTypes.string,
}
