import { Button, CircularProgress } from '@mui/material'
import Text from '../../atoms/Typography/Text'
import styled from 'styled-components'

export const Center = styled('div')`
  display: flex;
  column-gap: 40px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  flex-direction: column;
  padding-bottom: 60px;
  @media (max-width: 965px) {
    justify-content: space-between;
    flex-direction: column;
    height: fit-content;
  }
`

export const LogoContainer = styled('div')`
  position: absolute;
  top: 68px;
  left: 85px;
  @media (max-width: 965px) {
    position: static !important;
    margin-bottom: 30px;
    margin-left: 16px;
    margin-top: 14px;
    align-self: flex-start;
  }
`

export const TitleText = styled(Text)`
  font-size: 1.65em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.04em;
  text-align: left;
  color: black;
  @media (max-width: 965px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.04em;
    text-align: center;
  }
`
export const TitleTextNoWrap = styled(TitleText)`
  white-space: nowrap;
`
export const TitleTextUser = styled(TitleText)`
  margin-bottom: 21px;
  white-space: nowrap;
`

export const DescriptionText = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-align: left;
  color: black;
  margin-top: 37px;
  @media (max-width: 965px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-align: center;
    width: 70%;
    margin: auto;
    margin-top: 15px;
  }
`
export const ItemText = styled(DescriptionText)`
  margin-top: 14px;
  @media (max-width: 965px) {
    margin-top: 8px;
  }
`
export const OnboardingImage = styled('img')`
  width: 100%;
  @media (max-width: 965px) {
    height: auto;
    max-height: 190px;
    margin: auto;
    display: block;
  }
`

export const StyledButton = styled(Button)`
  width: 215px;
  height: 50px;
  bottom: 40px;
  @media (max-width: 965px) {
    width: 50%;
    height: 40.09px;
  }
`

export const Container = styled('div')`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 0px;
  align-items: center;
  width: 59%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 50px;
  min-height: 65vh;
  gap: 60px;
  @media (min-width: 1400px) {
    width: 40%;
  }
  @media (min-width: 965px) and (max-width: 1050px) {
    width: 75%;
  }
  @media (max-width: 965px) {
    grid-template-columns: 100%;
    align-content: center;
    min-height: 65vh;
    height: 100%;
    width: 100%;
    gap: 25px;
    padding: 0;
    margin-top: -20px;
    margin-bottom: 50px;
  }
`
export const RightContainer = styled('div')`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 965px) {
    margin: auto;
    padding: 0px;
  }
`
export const LeftContainer = styled('div')`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 965px) {
    margin: auto;
    padding: 0px;
  }
`

export const SVGContainer = styled('div')`
  @media (max-width: 965px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const SubTitleText = styled(Text)`
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 5%;
  letter-spacing: 0.04em;
  text-align: left;
  color: black;
  margin-bottom: 20px;
  margin-top: 20px;
  @media (max-width: 965px) {
    font-size: 20px;
    font-style: normal;
    line-height: 17px;
    letter-spacing: 0.04em;
    text-align: center;
    margin-bottom: 10px;
  }
`

export const DescriptionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 25px;
`

export const InfoText = styled(Text)`
  font-size: 15px;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  color: black;
  margin-bottom: 40px;
  @media (max-width: 965px) {
    margin: auto;
    width: 90%;
    text-align: center;
  }
`
export const Spinner = styled(CircularProgress)`
  color: #f6f6f7;
`
