import * as yup from 'yup'

export const schema = yup
  .object({
    email: yup.string(),
    password: yup
      .string()
      .required('Ingresé contraseña, por favor')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#_?!@$%^&*-]).{8,}$/,
        'Tu contraseña debe contener al menos 8 caracteres, un número, una mayúscula, una minúscula y un caracter especial.'
      ),
    passwordConfirmation: yup
      .string()
      .required('Confirmé contraseña, por favor')
      .oneOf([yup.ref('password'), null], 'Tus contraseñas no coinciden'),
  })
  .required()
