import Typography from '@mui/material/Typography'
import styled from 'styled-components'

const TitleCard = styled(Typography)`
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: bold;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
`
export default TitleCard
