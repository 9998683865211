import client from '../client/client'
import persistentStore from '../persistentStore'

const orderService = (baseUrl, endpoint) => ({
  orderBilling: async ({ id, cancelToken }) => {
    try {
      const response = await client(
        `${baseUrl}/${endpoint}/${encodeURIComponent(id)}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: persistentStore.getToken(),
            app: process.env.REACT_APP_APPLICATION_NAME,
            'api-key': process.env.REACT_APP_ORDER_API_KEY,
          },
          cancelToken,
        }
      )
      return response.data
    } catch (error) {
      return error
    }
  },
})

export default orderService(process.env.REACT_APP_API_ORDER_PATH, 'orders')
