import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'

const Progress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.third.main};
`

const Spinner = ({ ...props }) => {
  return <Progress {...props} />
}
Spinner.propTypes = {}

export default Spinner
