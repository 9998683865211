import Autocomplete from '@mui/material/Autocomplete'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import Field from '../../atoms/Field'
import styled from 'styled-components'

const TextFieldKC = styled(props => <Field {...props} />)`
  margin-top: 5px;
  .MuiOutlinedInput-root {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    padding-left: 14px;
    width: 90%;
  }
  @media (max-width: 1100px) {
    .MuiOutlinedInput-root {
      width: 100%;
    }
  }
`

const FieldAutoComplete = ({
  control,
  name,
  defaultValue,
  placeholder,
  loading,
  InputProps,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, ref, value, ...fields },
        fieldState: { error },
      }) => (
        <Autocomplete
          {...props}
          fullWidth
          disablePortal
          value={value}
          defaultValue={defaultValue}
          onChange={(_, data) => onChange(data)}
          noOptionsText="Ingresé una opción valida"
          renderInput={params => (
            <TextFieldKC
              loading={String(loading)}
              disabled={String(loading) === 'true'}
              {...{ name, ...params }}
              value={value}
              defaultValue={defaultValue}
              error={error}
              inputRef={ref}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
              }}
              {...fields}
            />
          )}
        />
      )}
    />
  )
}

FieldAutoComplete.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.array,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  loading: PropTypes.any,
  InputProps: PropTypes.any,
}

export default FieldAutoComplete
