import { combineReducers } from '@reduxjs/toolkit'

import drawerSlice from '../features/ui/drawerSlice'
import liquidationsReducer from '../features/liquidations/liquidationsSlice'
import orderReducer from '../features/orders/orderSlice'
import orderDownloadReducer from '../features/orderListDownload/orderListDownloadSlice'
import orderDetailReducer from '../features/orderDetail/orderDetailSlice'
import orderBillingReducer from '../features/orderBilling/orderBillingSlice'
import ProductListReducer from '../features/productSearchApi/productSlice'
import BrandReducer from '../features/brandsSlugSeller/brandsSlice'
import ProductCreateReducer from '../features/productCreate/productCreateSlice'
import ProductDetailReducer from '../features/productDetail/productDetailSlice'
import ProductUpdateReducer from '../features/productUpdate/productUpdateSlice'
import CategoryReducer from '../features/category/categorySlice'
import ProductUpdateStatusReducer from '../features/productStatusUpdate/productUpdateStatusSlice'
import ProductSkuReducer from '../features/productListBySku/productListBySkuSlice'
import dispatchedReducer from '../features/ui/dispatchedSlice'
import accountingStatementReducer from '../features/ui/accountingStatementSlice'

import documentReducer from '../features/ui/documentSlice'
import ProductImportReducer from '../features/productImport/productImportSlice'
import ProductCreateImportReducer from '../features/productCreateImport/productCreateImportSlice'
import ProductValidateReducer from '../features/productValidate/productValidateSlice'
import ProductCreateValidateReducer from '../features/productCreateValidate/productCreateValidateSlice'

import ProductExportSlice from '../features/productExport/productExportSlice'
import ProductCreateExportSlice from '../features/productCreateExport/productCreateExportSlice'

import ProductExportByIdSlice from '../features/productExportById/productExportByIdSlice'
import typeReducer from '../features/ui/typeSlice'
import currentDateReducer from '../features/ui/rangeOfDaysSlice'

import currentDatePayoutReducer from '../features/ui/rangeOfDaysPayoutSlice'
import ProductListDownloadSlicer from '../features/productListDownload/productListDownloadSlice'
import ProductExportAllByIdAdapter from '../features/productExportAllById/productExportAllByIdSlice'
import ProductChangePositionAdapter from '../features/productChangeImagePosition/productChangeImagePositionSlice'
import sellerByIdReducer from '../features/seller/sellerSlice'

import liquidationsDownloadReducer from '../features/liquidationListDownload/liquidationsDownloadSlice'
import locationListReducer from '../features/locationList/locationListSlice'
import authReducer from '../features/user/userAuthSlice'

const reducers = combineReducers({
  auth: authReducer,
  liquidation: combineReducers({
    list: liquidationsReducer,
    download: liquidationsDownloadReducer,
  }),
  seller: combineReducers({
    detail: sellerByIdReducer,
  }),
  order: combineReducers({
    list: orderReducer,
    detail: orderDetailReducer,
    billing: orderBillingReducer,
    orderDownload: orderDownloadReducer,
  }),
  products: combineReducers({
    list: ProductListReducer,
    create: ProductCreateReducer,
    detail: ProductDetailReducer,
    update: ProductUpdateReducer,
    detailStatus: ProductUpdateStatusReducer,
    sku: ProductSkuReducer,
    import: ProductImportReducer,
    export: ProductExportSlice,
    createImport: ProductCreateImportReducer,
    createExport: ProductCreateExportSlice,
    createValidate: ProductCreateValidateReducer,
    validate: ProductValidateReducer,
    exportById: ProductExportByIdSlice,
    exportAllById: ProductExportAllByIdAdapter,
    download: ProductListDownloadSlicer,
    changePosition: ProductChangePositionAdapter,
  }),
  category: CategoryReducer,
  location: combineReducers({ list: locationListReducer }),
  brands: BrandReducer,
  ui: combineReducers({
    drawer: drawerSlice,
    rangeOfDayPayout: currentDatePayoutReducer,
    rangeOfDay: currentDateReducer,
    dispatched: dispatchedReducer,
    accountingStatement: accountingStatementReducer,
    document: documentReducer,
    type: typeReducer,
  }),
})

export default reducers
