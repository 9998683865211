import { createAsyncThunk } from '@reduxjs/toolkit'
import orderService from '../../../data/services/orderService'
import { decodeJwt } from '../../../data/crypto/decodeJwt'
import axios from 'axios'

const fechOrderBilling = createAsyncThunk(
  'order/billing',
  async ({ id = undefined }, { rejectWithValue, signal }) => {
    if (!id || typeof id === 'undefined') {
      return null
    }

    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })

    try {
      const data = await orderService.orderBilling({
        id,
        cancelToken: source.token,
      })
      const certificate = decodeJwt(process.env.REACT_APP_KEY_ORDERS_H512)
      const decode = certificate(data)

      return decode
    } catch (error) {
      return rejectWithValue(JSON.stringify(error))
    }
  }
)
export default fechOrderBilling
