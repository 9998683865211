import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import fetchProductUpdateMedia from '../productUpdate/fetchProductUpdateMedia'
import fetchProductDetail from './fetchProductDetail'

export const productDetailAdapter = createEntityAdapter({
  selectId: ({ id }) => String(id),
})

export const producDetailtSlice = createSlice({
  name: 'product/detail',
  initialState: productDetailAdapter.getInitialState({
    status: 'idle',
    entities: {},
    product: {},
    mediaStatus: 'idle',
    mediaIds: [],
    media: [],
  }),
  reducers: {
    updateProductStatus(state, { payload }) {
      state.product.status = payload.status
    },
    resetProductDetail(state) {
      state.status = 'idle'
      state.product = {}
      state.entities = {}
      state.mediaStatus = 'idle'
      state.mediaIds = []
      state.media = []
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProductDetail.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchProductDetail.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchProductDetail.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.product = payload?.product
      state.media = payload?.product?.media
      state.mediaIds = []
      if (!isEmpty(payload?.product)) {
        productDetailAdapter.setOne(state, payload?.product)
      }
    })
    builder.addCase(fetchProductUpdateMedia.pending, state => {
      state.mediaStatus = 'loading'
    })
    builder.addCase(fetchProductUpdateMedia.rejected, state => {
      state.mediaStatus = 'error'
    })
    builder.addCase(
      fetchProductUpdateMedia.fulfilled,
      (state, { payload }) => {
        state.mediaStatus = 'finished'
        state.mediaIds = [...state.mediaIds, ...payload.mediaIds]
        state.media = [...state.media, ...payload.media]
      }
    )
  },
})

export const { updateProductStatus, resetProductDetail } =
  producDetailtSlice.actions
const reducer = producDetailtSlice.reducer
export default reducer
