import PropTypes from 'prop-types'
import styled from 'styled-components'

import ButtonDeleteFile from '../../atoms/ButtonDeleteFile/ButtonDeleteFile'

import Button from '@mui/material/Button'

import Text from '../../atoms/Typography/Text'
import TextError from '../../atoms/Typography/TextError'
import Spinner from '../../atoms/Spinner'

import error from '../../../assets/error.png'
import success from '../../../assets/success.png'

const Column = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
`
const Container = styled(Column)`
  justify-content: space-between;
  align-items: center;
`
const ColumnRight = styled(Column)`
  flex-direction: row;
  align-items: center;
`

const Label = styled('label')`
  display: flex;
  flex-direction: row;
  height: 100%;
  &:hover {
    border-bottom-width: 0;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme }) => theme.palette.text.primary};
  }
`

const StyledTextError = styled(TextError)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #ef5454;
  width: 100%;
  margin: 0;
`
const StyleButtonFile = styled(Button)`
  color: black;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  height: 100%;
  .MuiButtonBase-root {
    padding-left: 0;
    padding: 0px;
    margin: 0px;
  }
  ${Label}:hover & {
    background-color: transparent;
    border: 0;
  }
`

const Icon = styled('img')`
  margin-left: 30px;
  margin-right: 14px;
`
const LoadText = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #2469ce;
  align-items: center;
  display: flex;
`

const AlertIcons = styled(Icon)`
  margin: 0;
  padding-right: 5px;
`

const SuccessText = styled(Text)`
  color: #92e6b5;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
`

const ReverseIcon = styled(Icon)`
  transform: rotate(180deg);
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const ColumnFlex = styled('div')`
  display: flex;
  flex: 1;
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledSpinner = styled(Spinner)`
  color: #2469ce;
  margin-right: 14px;
`

const ImportProductsControll = ({
  handleDeleteXlsx,
  register,
  errors,
  icon,
  text,
  reverseIcon,
  value,
  onSubmit,
  disabled,
  status,
}) => {
  const { onChange, ...xlsx } = register('multiProducts', {
    required: false,
  })

  return (
    <Container>
      <Column>
        <Label>
          <StyleButtonFile
            variant="text"
            component="span"
            disabled={disabled}
            startIcon={
              reverseIcon ? (
                <ReverseIcon src={icon} disabled={disabled} />
              ) : (
                <Icon src={icon} alt="import" />
              )
            }
          >
            {text}
          </StyleButtonFile>
          <input
            hidden
            type="file"
            disabled={disabled}
            {...xlsx}
            onChange={e => {
              onChange(e).then(() => {
                onSubmit(e)
              })
            }}
          />
        </Label>
      </Column>
      <Column>
        <ColumnRight>
          <ColumnFlex>
            {(errors && errors['multiProducts']) || status.errors ? (
              <Row>
                <AlertIcons src={error} width="24" height="19" />
                <StyledTextError>Planilla no validada</StyledTextError>
              </Row>
            ) : status.status === 'loading' ? (
              <LoadText>
                <StyledSpinner size="19px" /> Cargando planilla
              </LoadText>
            ) : value && status.status === 'finished' ? (
              <SuccessText>
                <AlertIcons src={success} /> Planilla validada
              </SuccessText>
            ) : null}
          </ColumnFlex>
          <ColumnFlex>
            {value ? (
              <ButtonDeleteFile
                title={value[0]?.name}
                name={value[0]?.name}
                download={value[0]?.name}
                url={URL.createObjectURL(value[0])}
                onClick={handleDeleteXlsx}
                disabled={status.status === 'loading'}
              />
            ) : null}
          </ColumnFlex>
        </ColumnRight>
      </Column>
    </Container>
  )
}
ImportProductsControll.propTypes = {
  label: PropTypes.string,
  allFiles: PropTypes.object,
  value: PropTypes.any,
  handleDeleteXlsx: PropTypes.func,
  register: PropTypes.any,
  errors: PropTypes.object,
  icon: PropTypes.any,
  text: PropTypes.string,
  reverseIcon: PropTypes.bool,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  status: PropTypes.object,
}

export default ImportProductsControll
