import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  addOneAccountingStatement,
  allAccountingStatement,
  removeAllAccountingStatement,
  removeOneAccountingStatement,
} from '../../../../domain/features/ui/accountingStatementSlice'

import SelectWithChips from '../../molecules/SelectWithChips'

const Select = styled(SelectWithChips)`
  max-width: 20rem;
  min-width: auto;
`

const SelectAccountingStatement = ({ isallfilter, list }) => {
  const accountingStatement = useSelector(allAccountingStatement)

  const dispatch = useDispatch()
  const handleChange = useCallback(
    event => {
      return event.target.value !== 'borrar'
        ? dispatch(addOneAccountingStatement(event.target.value))
        : dispatch(removeAllAccountingStatement())
    },
    [dispatch]
  )

  const handleDelete = useCallback(
    value => {
      dispatch(removeOneAccountingStatement(value))
    },
    [dispatch]
  )

  return (
    <Select
      label="Estado contable"
      isCheckbox
      isallfilter={isallfilter}
      value={accountingStatement}
      onChange={handleChange}
      list={
        list || [
          'No Liquidado',
          'Facturado - No Pagado',
          'Pagado',
          'Contabilizado',
          'Contabilización Fallida',
          'Procesando',
        ]
      }
      handleDeleteChip={handleDelete}
      handleDeleteAll={value =>
        dispatch(removeAllAccountingStatement(value))
      }
    />
  )
}
SelectAccountingStatement.propTypes = {
  list: PropTypes.array,
  isallfilter: PropTypes.bool,
}
SelectAccountingStatement.defaultProps = {
  list: [
    'No Liquidado',
    'Facturado - No Pagado',
    'Pagado',
    'Contabilizado',
    'Contabilización Fallida',
    'Procesando',
  ],
}
export default SelectAccountingStatement
