import styled from 'styled-components'
import TitleCard from './TitleCard'

const SubtitleCard = styled(TitleCard)`
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
`

export default SubtitleCard
