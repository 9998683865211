import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import styled from 'styled-components'

const ButtonFilter = styled(
  ({ isfilter, badgeContent, isloading, ...props }) => (
    <Tooltip title={!isfilter ? 'Mostar filtros' : 'Esconder filttros'}>
      <IconButton
        variant="contained"
        isloading={String(isloading)}
        {...props}
      >
        <Badge badgeContent={badgeContent} color="secondary">
          {isfilter ? <FilterAltIcon /> : <FilterAltOffIcon />}
        </Badge>
      </IconButton>
    </Tooltip>
  )
)`
  border: solid 1px #d1d0d0;
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 10px;
  &:disabled {
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'not-allowed'};
    pointer-events: auto;
  }
`

export default ButtonFilter
