import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchProductCreateImport from './fetchProductCreateImport'

export const productCreateImportAdpater = createEntityAdapter()

export const productCreateImportSlice = createSlice({
  name: 'product/createImport',
  initialState: productCreateImportAdpater.getInitialState({
    status: 'idle',
    url: {},
    errors: null,
  }),
  reducers: {
    resetProductCreateImport(state) {
      state.status = 'idle'
      state.errors = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProductCreateImport.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchProductCreateImport.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errors = payload
    })
    builder.addCase(fetchProductCreateImport.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.url = payload
    })
  },
})
export const { resetProductCreateImport } = productCreateImportSlice.actions
const reducer = productCreateImportSlice.reducer
export default reducer
