export const colors = new Map([
  ['success', ' #92E6B5'],
  ['warning', '#FFC96B'],
  ['error', '#FC8E8E'],
  ['pending', '#C4C4C4'],
])
export const statusColorMapper = new Map([
  ['No despachado', colors.get('warning')],
  ['Despachado', colors.get('success')],
  ['Ingresado', colors.get('warning')],
  ['Rechazado', colors.get('error')],
  ['Devuelto', colors.get('success')],
  ['Cancelado', colors.get('error')],
  ['Facturado', colors.get('success')],
  ['Pagado', colors.get('success')],
  ['Contabilizado', colors.get('warning')],
  ['Contabilización Fallida', colors.get('error')],
  ['Procesando', colors.get('pending')],
  ['Facturado - No pagado', colors.get('warning')],
  ['No liquidado', colors.get('warning')],
  ['DRAFT', colors.get('warning')],
  ['ACTIVE', colors.get('success')],
  ['ARCHIVED', colors.get('pending')],
])
