import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import FieldQuantity from '../../atoms/FieldQuantity'

const FieldQuantityController = ({
  control,
  name,
  defaultValue,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...fields }, fieldState: { error } }) => (
        <FieldQuantity
          {...{ name, ...props }}
          error={error}
          inputRef={ref}
          {...fields}
        />
      )}
    />
  )
}

FieldQuantityController.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
}

export default FieldQuantityController
