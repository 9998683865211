import styled from 'styled-components'
import Title from '../../atoms/Typography/Title'
import LoginForm from '../../organisms/LoginForm'

const LayoutLogin = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100vh;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.palette.third.dark};
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const Logo = styled('img')`
  height: 180px;
  width: 180px;
  border: 1px solid #c1c1c1;
  @media (max-width: 700px) {
    margin-bottom: 10px;
    height: 120px;
    width: 120px;
  }
  @media (max-width: 500px) {
    margin-bottom: 10px;
    height: 80px;
    width: 80px;
  }
`

const TitleLogo = styled(Title)`
  font-size: 24px;
  line-height: 26px;
  font-style: light;
  font-weight: 200;
  letter-spacing: 0.04em;
  text-align: center;
  margin-bottom: 10%;

  color: ${({ theme }) => theme.palette.third.light};
  @media (max-width: 800px) {
    padding-left: 0;
  }
  @media (max-width: 700px) {
    margin-bottom: 30px;
    margin-top: 30px;
  }
`
const SubTitleLogo = styled(TitleLogo)`
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  color: #b7b7b7;
  @media (max-width: 800px) {
    padding-left: 0;
  }
  @media (max-width: 700px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

const SubLayoutLogin = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const TitleContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

const Login = () => {
  return (
    <LayoutLogin>
      <SubLayoutLogin>
        <TitleContainer>
          <TitleLogo variant="h1">Seller Center</TitleLogo>
          <Logo
            alt="logo"
            src="https://cdn.shopify.com/s/files/1/0104/4391/5319/files/kitchencenter_logo.svg?6927"
          />
        </TitleContainer>
        <SubTitleLogo variant="h2">Marketplace</SubTitleLogo>
      </SubLayoutLogin>
      <SubLayoutLogin>
        <LoginForm />
      </SubLayoutLogin>
    </LayoutLogin>
  )
}

export default Login
