import { debounce } from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'
import fetchProductExportById from '../productExportById/fetchProductExportById'

const recursiveFetch = debounce(async (dispatch, bulkId) => {
  let rejected
  const data = await dispatch(fetchProductExportById({ bulkId }))
  rejected = data.meta.requestStatus
  if (rejected === 'rejected') {
    return await recursiveFetch(dispatch, bulkId)
  }
  return true
}, 2000)

const fetchProductCreateExport = createAsyncThunk(
  'product/createExport',
  async (categories, { rejectWithValue }) => {
    try {
      const response = await productAdminServices.exportCreate({
        categories: categories,
      })
      if (response.error) {
        return rejectWithValue('Operación bulk falló')
      }
      if (response.ok) {
        const resJson = await response.json()
        const blob = resJson.file

        if (blob) {
          const newBlob = new Blob([new Uint8Array(resJson.file.data)])

          const blobUrl = window.URL.createObjectURL(newBlob)

          const link = document.createElement('a')
          link.href = blobUrl
          link.setAttribute('download', 'categorias.xlsx')
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(newBlob)
        }

        return resJson
      } else {
        throw new Error('file not found')
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchProductCreateExport
