import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useSpring, animated } from '@react-spring/web'
import styled from 'styled-components'

import {
  onChangeDrawer,
  isOpenDrawer,
} from '../../../../domain/features/ui/drawerSlice'
import { isSellerKitchenCenter } from '../../../../domain/features/seller/getSeller'

import DrawerMui from '@mui/material/Drawer'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import useMediaQuery from '@mui/material/useMediaQuery'

import Navigate from '../../atoms/Navigate/Navigate'
import back from '../../../assets/icon/back.svg'

export const drawerWidth = 235
export const drawerMinWith = 60

const ListItemStyle = styled(ListItem)`
  width: 100%;
  height: 35px;
  text-align: left;
  padding-left: 18px;
  margin-left: 10px;
  border: 0;
  background-color: ${({ active, theme }) =>
    active === 'true'
      ? theme.palette.type === 'dark'
        ? '#202223'
        : '#F0F0F0'
      : 'transparent'};
  border-left: ${({ active }) =>
    active === 'true' ? '2px solid #008060' : 0};
`

const DrawerContainer = styled(DrawerMui)`
  .MuiDrawer-paperAnchorDockedLeft {
    overflow: hidden;
    transition: width 0.4s;
    width: ${({ open }) =>
      open ? `${drawerWidth}px` : `${drawerMinWith}px`};
  }
  .MuiDrawer-paper {
    overflow: hidden;
    background-color: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#313234' : '#FFFFFF'};
  }
`

const DrawerItemText = styled(ListItemText)`
  .MuiTypography-body1 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.2px;
    overflow: hidden;
    color: ${({ active }) => (active === 'true' ? '#008060' : '#212121')};
  }
`

const DrawerHeader = styled(Paper)`
  width: ${drawerMinWith}px;
  border: 0px;
  border-radius: 0px;
  background-color: #000000;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 0;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-right: 0px;
  border-right-width: 0px;
  overflow: hidden;
  ${({ theme }) => theme.mixins.toolbar}
  @media (min-width: 700px) {
    min-height: 58px;
  }
`

const DrawerFooter = styled(DrawerHeader)`
  justify-content: center;
`

const DrawerList = styled(List)`
  flex: 1;
  border-radius: 0;
  border-left: ${drawerMinWith}px solid black;
  overflow: hidden;
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#313234' : '#FFFFFF'};
`

const DrawerNavigate = styled(Navigate)`
  text-decoration: none;
  text-decoration-line: none;
  padding: 0;
  margin: 0;
`

const Logo = styled('img')`
  position: absolute;
  top: 8px;
  left: 8px;
  border: 1px solid #c1c1c1;
  background-color: ${({ theme }) =>
    theme.palette.type !== 'dark' ? '#313234' : '#FFFFFF'};
`

const Back = styled(animated('img'))`
  width: 25px;
  height: 25px;
`

const TitleAPP = styled(animated('h1'))`
  font-size: 20px;
  line-height: 24px;
  font-style: light;
  font-weight: normal;
  margin-left: 10px;
  position: fixed;
  top: 2px;
  color: ${({ isopen, theme }) =>
    isopen === 'true' ? theme.palette.info.dark : '#FFFFFF'};
`

const SubTitleAPP = styled(animated('h2'))`
  font-size: 10px;
  line-height: 12px;
  font-style: light;
  font-weight: normal;
  margin-left: 10px;
  position: fixed;
  top: 32px;
  white-space: wrap;
  color: ${({ isopen, theme }) =>
    isopen === 'true' ? theme.palette.info.light : '#c1c1c1'};
`

const Drawer = () => {
  const isMobile = useMediaQuery('(max-width:1100px)')
  const dispatch = useDispatch()
  const open = useSelector(isOpenDrawer)
  const toggleDrawer = newOpen => () => {
    return dispatch(onChangeDrawer(newOpen))
  }

  const animation = useSpring({
    to: {
      transform: !open ? 'rotate(180deg)' : 'rotate(360deg)',
    },
    native: true,
  })
  const { pathname } = useLocation()

  const isUserKC = useSelector(isSellerKitchenCenter)

  const menuDrawer = useMemo(
    () =>
      isUserKC
        ? [
            { text: 'Pedidos', route: '/pedidos' },
            { text: 'Productos', route: '/productos' },
          ]
        : [
            { text: 'Pedidos', route: '/pedidos' },
            { text: 'Productos', route: '/productos' },
            { text: 'Liquidaciones', route: '/liquidaciones' },
          ],
    [isUserKC]
  )

  return (
    <>
      <DrawerContainer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <DrawerHeader elevation={0}>
          <DrawerNavigate to={{ pathname: '/' }}>
            <Logo
              src="https://cdn.shopify.com/s/files/1/0104/4391/5319/files/kitchencenter_logo.svg?6927"
              alt="logo-kc"
              width="44px"
              height="44px"
            />
          </DrawerNavigate>
        </DrawerHeader>

        <DrawerList role="list">
          <div>
            <TitleAPP isopen={String(open)}>Seller Center</TitleAPP>
            <SubTitleAPP isopen={String(open)}>Marketplace</SubTitleAPP>
          </div>
          {menuDrawer.map(({ text, route }, index) => (
            <DrawerNavigate
              to={{ pathname: route, state: [], search: '' }}
              key={`${text}-${route}-${index}`}
            >
              <ListItemStyle
                role="listitem"
                key={text}
                as={Button}
                active={String(pathname.includes(route))}
              >
                <DrawerItemText
                  primary={text}
                  active={String(pathname.includes(route))}
                  onClick={isMobile ? toggleDrawer(false) : null}
                />
              </ListItemStyle>
            </DrawerNavigate>
          ))}
        </DrawerList>

        <DrawerFooter elevation={1}>
          <IconButton onClick={() => dispatch(onChangeDrawer(!open))}>
            <Back src={back} style={animation} alt="open-close" />
          </IconButton>
        </DrawerFooter>
      </DrawerContainer>
    </>
  )
}

export default Drawer
