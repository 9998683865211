import axios from 'axios'
import axiosRetry from 'axios-retry'

import auth, { getIdToken } from '../firebase/auth'
import { getFirebaseToken } from '../db/authDB'
import persistentStore from '../persistentStore'

const client = axios.create({
  headers: {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
    app: process.env.REACT_APP_APPLICATION_NAME || 'sellerCenter',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
})

client.interceptors.request.use(
  async config => {
    const { accessToken: token } = await getFirebaseToken()
    if (token) {
      config.headers.authorization = token
    } else {
      config.headers.authorization = persistentStore.getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosRetry(client, {
  retries: 1, // Solo reintentar una vez
  retryCondition: error => {
    return error.response.status === 401
  },
  retryDelay: retryCount => {
    return retryCount * 1000 // Retrasar el reintento por 1 segundo
  },
})

// Interceptor de respuesta para agregar token JWT
client.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const user = auth.currentUser
        if (user) {
          const token = await getIdToken(user, true)

          axios.defaults.headers.common['authorization'] = token
          originalRequest.headers['authorization'] = token

          persistentStore.setToken(token)

          return client(originalRequest)
        }
      } catch (refreshError) {
        return Promise.reject(refreshError)
      }
    }

    return Promise.reject(error)
  }
)

export default client
