import React from 'react'
import { Fragment } from 'react'
import PropTypes, { string, arrayOf } from 'prop-types'
import styled from 'styled-components'
import {
  useTable,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Skeleton from '@mui/material/Skeleton'

import Card from '../../atoms/Card'
import RowHead from '../../atoms/BaseTable/RowHead'
import CellHeader from '../../atoms/BaseTable/BaseCell/CellHeader/CellHeader'
import TextHeader from '../../atoms/BaseTable/Typography/TextHeader'
import CellEmpty from '../../atoms/BaseTable/BaseCell/CellEmpty'
import CellTooltip from '../../atoms/BaseTable/BaseCell/CellTooltip'
import ArrowSort from '../../molecules/ArrowSort'
import Tooltip from '../../atoms/ToolTip/ToolTip'
import TableFooter from '../../organisms/TableFooter'
import ErrorSearch from '../../molecules/SearchMessages/ErrorSearch/ErrorSearch'
import CellTooltipNavigate from '../../atoms/BaseTable/BaseCell/CellTooltipNavigate'
import CellAccountingStatement from '../../atoms/BaseTable/BaseCell/CellAccountingStatement/CellAccountingStatement'

const isEmpty = value =>
  typeof value === 'undefined' || value === '' || value === null

const Container = styled('div')`
  margin-top: 1%;
  margin-bottom: 10rem;
  .MuiTableCell-root {
    color: #202223 !important;
    border-top: 1px solid #b7b7b7;
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'pointer'};
  }
`

const TableSort = styled(TableSortLabel)`
  flex-direction: row;
`

const TableTemplate = ({
  columns,
  data,
  pageCurrentIndex,
  setPage,
  perPage,
  setPerPage,
  totalPage,
  renderFooter,
  onSort,
  isLoading = true,
  isError = false,
  manualSortBy = false,
  withPage = false,
  pathname = '/productos',
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: rowList,
    prepareRow,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      pageCount: totalPage,
      useControlledState: state => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: pageCurrentIndex,
          }),
          [state]
        )
      },
      initialState: {
        pageIndex: pageCurrentIndex,
        pageSize: perPage,
        sortBy: [{ id: 'updatedAt', desc: true }],
      },
      manualPagination: withPage,
      manualSortBy,
    },
    useSortBy,
    useExpanded,
    usePagination
  )
  React.useEffect(() => {
    if (!onSort) {
      return
    }
    onSort(sortBy)
  }, [onSort, sortBy])
  const rows = withPage ? page : rowList

  const tooltip = React.useMemo(
    () =>
      new Map([
        ['/productos', 'Ver producto'],
        ['/pedidos', 'Ver pedido'],
        ['/liquidaciones', 'Ver pedidos'],
      ]).get(pathname),
    [pathname]
  )
  const columnsByOrder = React.useMemo(
    () =>
      new Map([
        ['/productos', [2, 3, 4, 5, 6, 7, 8, 9, 10]],
        ['/pedidos', []],
        ['/liquidaciones', [4]],
      ]).get(pathname),
    [pathname]
  )

  return (
    <Container isloading={String(isLoading)}>
      {isError ? (
        <ErrorSearch />
      ) : (
        <TableContainer
          component={Card}
          data-testid="TableTemplate__TableContainer"
        >
          <Table aria-label="tracking-table" {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <RowHead
                  data-testid="TableTemplate__RowHead"
                  key={String(headerGroup.id)}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, index) => (
                    <CellHeader
                      align="left"
                      key={String(column.id)}
                      {...column.getHeaderProps(
                        column.getSortByToggleProps()
                      )}
                      sortDirection={column.isSortedDesc ? 'desc' : 'asc'}
                    >
                      <TableSort
                        data-testid={`TableTemplate__TableSortLabel--${index}`}
                        title="Ordernar por"
                        active={column.isSorted}
                        IconComponent={() =>
                          column.isSorted ? (
                            <ArrowSort
                              sort={String(column.isSortedDesc)}
                            />
                          ) : columnsByOrder.some(c => c === index) ? (
                            <ArrowSort
                              sort={String(column.isSortedDesc)}
                            />
                          ) : null
                        }
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      >
                        <TextHeader>{column.render('Header')}</TextHeader>
                      </TableSort>
                    </CellHeader>
                  ))}
                </RowHead>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={String(row.id)}>
                    <Tooltip title={tooltip} followCursor={true}>
                      <TableRow
                        {...row.getRowProps()}
                        hover={true}
                        style={{ cursor: 'pointer' }}
                      >
                        {row.cells.map(cell => {
                          if (isLoading) {
                            return (
                              <CellTooltip
                                key={cell.row.id}
                                {...cell.getCellProps()}
                              >
                                <Skeleton variant="rectangular" />
                              </CellTooltip>
                            )
                          }
                          if (
                            cell.column.Header === 'Estado' ||
                            cell.column.Header === 'Estado despacho' ||
                            cell.column.Header === 'Estado contable'
                          ) {
                            return (
                              <CellAccountingStatement
                                key={cell.row.id}
                                {...cell.getCellProps()}
                                text={cell.value}
                              />
                            )
                          }
                          if (
                            cell.column.Header === 'Factura' ||
                            cell.column.Header === 'Reporte'
                          ) {
                            return (
                              <CellTooltip
                                key={cell.row.id}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </CellTooltip>
                            )
                          }

                          return isEmpty(cell.value) &&
                            cell.column.Header !== 'Acción' &&
                            cell.column.Header !==
                              'Documento tributario' &&
                            cell.column.Header !== 'Vista previa' ? (
                            <CellEmpty
                              key={cell.row.id}
                              {...cell.getCellProps()}
                            />
                          ) : cell.column.Header !== 'Acción' &&
                            cell.column.Header !==
                              'Documento tributario' &&
                            cell.column.Header !== 'Vista previa' ? (
                            <CellTooltipNavigate
                              key={cell.row.id}
                              to={cell?.row?.original?.to}
                              state={cell?.row?.original?.to.state}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </CellTooltipNavigate>
                          ) : (
                            <CellTooltip
                              key={cell.row.id}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </CellTooltip>
                          )
                        })}
                      </TableRow>
                    </Tooltip>
                  </Fragment>
                )
              })}
            </TableBody>
          </Table>

          {withPage ? (
            <TableFooter
              pageCurrentIndex={pageIndex}
              hasNextPage={canNextPage}
              hasPreviousPage={canPreviousPage}
              setPage={setPage}
              totalPage={pageOptions?.length}
              listPerPage={[10, 25, 50, 75, 100, 'Todos']}
              perPage={pageSize}
              handleSelectPerPage={e => {
                setPageSize(Number(e.target.value))
                setPerPage(Number(e.target.value))
              }}
            />
          ) : null}
          {renderFooter && renderFooter()}
        </TableContainer>
      )}
    </Container>
  )
}
TableTemplate.propTypes = {
  query: string,
  columns: arrayOf(Object),
  data: arrayOf(Object),
  pageCurrentIndex: PropTypes.number,
  setPage: PropTypes.any,
  perPage: PropTypes.number,
  setPerPage: PropTypes.any,
  totalPage: PropTypes.number,
  isLoading: PropTypes.bool,
  withPage: PropTypes.bool,
  renderFooter: PropTypes.func,
  resetPrevLoadRow: PropTypes.func,
  onSort: PropTypes.func,
  isError: PropTypes.bool,
  manualSortBy: PropTypes.bool,
  pathname: PropTypes.string,
}
TableTemplate.defaultProps = {
  pageCurrentIndex: 0,
  totalPage: 0,
  query: '',
  columns: [],
  data: [],
  withPage: false,
  isLoading: true,
  isError: false,
  perPage: 25,
  manualSortBy: false,
}
export default TableTemplate
