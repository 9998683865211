import PropTypes from 'prop-types'
import styled from 'styled-components'

import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'

import Navigate from '../../atoms/Navigate/Navigate'

const Icon = styled(AddIcon)``

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  width: 179px;
  height: 37px;
  text-transform: none;

  @media (max-width: 700px) {
    text-align: center;
    margin: 0px;
    padding: 0px;
    padding-right: 5px;
    padding-left: 5px;
    min-width: 20px;
    height: 40px;
    width: 40px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`

const AddProductButton = ({ to, title, variant, addIcon, ...props }) => {
  const matches = useMediaQuery('(max-width:700px)')
  return (
    <Navigate to={to}>
      <StyledButton
        {...props}
        variant={variant || 'contained'}
        color="primary"
        startIcon={addIcon ? <Icon fontSize="large" /> : null}
      >
        {!matches && title}
      </StyledButton>
    </Navigate>
  )
}
AddProductButton.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  addIcon: PropTypes.bool,
  variant: PropTypes.string,
}
AddProductButton.defaultProps = {
  variant: 'contained',
}
export default AddProductButton
