import styled from 'styled-components'
import ButtonFile from '../../atoms/ButtonFile/ButtonFile'
import ToolTip from '../../atoms/ToolTip'
import PropTypes from 'prop-types'
import Tooltip from '../../atoms/ToolTip/ToolTip'
import Collapse from '@mui/material/Collapse'

const Column = styled('div')`
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;
`
const Container = styled(Column)`
  margin-bottom: 2%;
  justify-content: space-between;
`
const ErrorCircle = styled('div')`
  padding: 0;
  display: flex;
  height: 25px;
  width: 25px;
  background-color: ${({ theme }) => theme?.palette?.error?.dark};
  color: {({ theme }) => theme?.palette?.third?.main};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`

const ErrorTooltip = styled(Tooltip)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ButtonFileTooltip = ({ tooltipTitle = '', ...props }) => (
  <ToolTip title={tooltipTitle} followCursor={true}>
    <div>
      <ButtonFile {...props} />
    </div>
  </ToolTip>
)
ButtonFileTooltip.propTypes = { tooltipTitle: PropTypes.string }
ButtonFileTooltip.defaultProps = {
  tooltipTitle: '',
}

const FieldFileCustom = ({
  name,
  label,
  tooltipTitle,
  register,
  errors,
  ispress,
}) => {
  return (
    <>
      <Container>
        <Column>
          <ButtonFileTooltip
            title={label}
            tooltipTitle={tooltipTitle}
            name={name}
            register={register}
            multiple={false}
            accept={'application/pdf'}
            ispress={String(ispress)}
          />
          <Collapse in={Boolean(errors && errors[name])}>
            <ErrorTooltip
              title="Error al adjuntar documento tributario PDF."
              followCursor={true}
            >
              <ErrorCircle>!</ErrorCircle>
            </ErrorTooltip>
          </Collapse>
        </Column>
      </Container>
    </>
  )
}

FieldFileCustom.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  register: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.object,
  ispress: PropTypes.bool,
}
FieldFileCustom.defaultProps = {
  tooltipTitle: 'Adjuntar archivo (PDF)',
  handleFileChange: () => {},
  ispress: false,
}
export default FieldFileCustom
