import { createSelector } from '@reduxjs/toolkit'
import { orderBillingAdapter } from './orderBillingSlice'

const getStateOrder = state => state.order.billing

export const getOrderBilling = createSelector(
  [getStateOrder],
  order => order
)

const orderSelectors = orderBillingAdapter.getSelectors(getStateOrder)
export const resultOrderBilling = orderSelectors.selectAll

