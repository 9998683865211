import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'

import { schema } from '../../../../domain/schemas/loginFormSchema'
import { login } from '../../../../domain/features/user/userAuthSlice'

import Collapse from '@mui/material/Collapse'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import ReportGmailerrorredOutlined from '@mui/icons-material/ReportGmailerrorredOutlined'

import InputEmailController from '../../molecules/InputEmailController/InputEmailController'
import InputPasswordController from '../../molecules/InputPasswordController/InputPasswordController'
import Title from '../../atoms/Typography/Title'
import AuthValid from '../../pages/auth/AuthValid'
import { getAuth } from '../../../hooks/useAuth'

const Spinner = styled(CircularProgress)`
  background-color: ${({ theme }) => theme.palette.third.dark};
`

const Form = styled('form')`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.third.main};
  height: 100%;
  @media (max-width: 800px) {
    padding-top: 5%;
    padding-bottom: 5%;
  }
`

const ContainerFormController = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 50%;
  justify-content: center;
  @media (max-width: 800px) {
    margin-top: 4%;
    width: 70%;
  }
`

const ButtonStyled = styled(Button)`
  display: flex;
  width: 25%;
  border-radius: 5px;
  width: 100%;
`

const ErrorText = styled('p')`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  margin: 0;
  color: ${({ theme }) => theme.palette.error.dark};
`

const ErrorIcon = styled(ReportGmailerrorredOutlined)`
  color: ${({ theme }) => theme.palette.error.dark};
  margin-right: 10px;
`

const ErrorContainer = styled('div')`
  color: ${({ theme }) => theme.palette.error.dark};
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`

const FormContainer = styled('div')`
  display: flex;
  width: 80%;
  flex-direction: column;
`

const FormTitle = styled(Title)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 24px;
  font-style: bold;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 8%;
`

const SubContainerController = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = event => event.preventDefault()

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  })

  const dispatch = useDispatch()
  const { loading, isAuthenticated, error } = useSelector(getAuth)
  const sessionHandler = useCallback(
    async ({ email, password }) =>
      await dispatch(login({ email, password })).unwrap(),
    [dispatch]
  )

  if (loading) {
    return <AuthValid />
  }

  if (isAuthenticated) {
    return <Navigate to="/productos" replace />
  }

  return (
    <Form onSubmit={handleSubmit(sessionHandler)}>
      <FormContainer>
        <FormTitle variant="h3">Iniciar Sesión</FormTitle>
        <ContainerFormController>
          <SubContainerController>
            <Collapse in={!!error}>
              <ErrorContainer>
                <ErrorIcon fontSize="large" />
                <ErrorText>{error} </ErrorText>
              </ErrorContainer>
            </Collapse>
            <InputEmailController
              label="Ingresa tu correo electrónico"
              errors={errors}
              showError={!!error}
              control={control}
            />
            <InputPasswordController
              label="Ingresa tu contraseña"
              showError={!!error}
              control={control}
              errors={errors}
              showPassword={showPassword}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
            />
          </SubContainerController>
        </ContainerFormController>
      </FormContainer>
      <ButtonContainer>
        <ButtonStyled
          onClick={handleSubmit(sessionHandler)}
          type="submit"
          variant="contained"
          disabled={isSubmitting || loading}
          endIcon={
            isSubmitting || loading ? <Spinner size="1.2rem" /> : null
          }
        >
          Ingresar
        </ButtonStyled>
      </ButtonContainer>
    </Form>
  )
}

export default LoginForm
