import PropTypes from 'prop-types'
import AlertMUI from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import Text from '../Typography/Text'
import styled from 'styled-components'
import IconSuccess from '../Icon/IconSuccess'

export const typeAlert = new Map([
  ['finished', 'success'],
  ['error', 'error'],
  ['loading', 'info'],
])

const AlertStyled = styled(AlertMUI)`
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
`

const TextAlert = styled(Text)`
  color: #5c5c5c;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin-left: 5px;
`

const Alert = ({
  isLoading,
  title,
  open,
  onClose,
  isError = false,
  anchorOrigin,
  isMultipleLoading,
  ...props
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={isLoading ? null : 4000}
      onClose={onClose}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'center',
        }
      }
    >
      <AlertStyled
        onClose={onClose}
        {...props}
        closeText="Cerrar"
        action={
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        icon={
          isMultipleLoading || isLoading ? (
            <CircularProgress size={20} />
          ) : isError ? (
            <ErrorOutlineIcon />
          ) : (
            <IconSuccess />
          )
        }
      >
        <TextAlert>{title}</TextAlert>
      </AlertStyled>
    </Snackbar>
  )
}
Alert.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorOrigin: PropTypes.any,
  isMultipleLoading: PropTypes.bool,
}
Alert.defaultProps = {
  isLoading: false,
  isError: false,
}
export default Alert
