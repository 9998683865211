import { createAsyncThunk } from '@reduxjs/toolkit'
import userServicesSettlement from '../../../data/services/userServicesSettlement'

const fetchBrands = createAsyncThunk(
  'brands',
  async ({ slug_seller, token }, { rejectWithValue }) => {
    try {
      const brandSlugSeller =
        await userServicesSettlement.getSlugSellerBrands({
          slug_seller,
          token,
        })

      return brandSlugSeller
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState().brands
      if (state?.status === 'finished' || state?.status === 'loading') {
        return false
      }
    },
  }
)
export default fetchBrands
