import persistentStore from '../../../data/persistentStore'

const downloadBill = (endpoint, fileName, type) =>
  fetch(endpoint, {
    headers: {
      authorization: persistentStore.getToken(),
      app: process.env.REACT_APP_APPLICATION_NAME,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.blob())
    .then(response => {
      const url = window.URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${fileName || 'file'}.${type || 'pdf'}`
      )
      document.body.appendChild(link)
      link.click()
      link.remove()
    })

export default downloadBill
