import { useState, useCallback } from 'react'
import styled from 'styled-components'
import split from 'lodash/split'
import { useSelector, useDispatch } from 'react-redux'

import { logout } from '../../../../domain/features/user/userAuthSlice'
import { getSellerById } from '../../../../domain/features/seller/getSeller'

import useInstallPWA from '../../../hooks/useInstallPWA'
import { useWorker } from '../../../hooks/useWorker'
import { useAuth } from '../../../hooks/useAuth'

import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined'
import useMediaQuery from '@mui/material/useMediaQuery'
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop'
import InstallMobileIcon from '@mui/icons-material/InstallMobile'
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import LogoutOutlined from '@mui/icons-material/LogoutOutlined'

import Dropdown from '../../atoms/Dropdown/Dropdown'
import Text from '../../atoms/Typography/Text'

const IconContainer = styled('div')`
  margin-right: 0.5rem;
`
const UserSeller = styled(Person2OutlinedIcon)`
  color: ${({ theme }) => theme.palette.info.main};
`
const UserSellerRounded = styled(PersonRoundedIcon)`
  color: ${({ theme }) => theme.palette.info.main};
`
const AvatarSeller = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.info.main};
`

const AvatarDetail = styled(Box)`
  color: ${({ theme }) => theme.palette.info.main};
  @media (max-width: 700px) {
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
`

const ButtonUser = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 170px;
  background-color: ${({ theme }) => theme.palette.third.dark};
  color: ${({ theme }) => theme.palette.info.main};
  height: 52px;
  padding-left: 10px;
  padding-right: 5px;
  transition: background-color 0.4s;
  &:hover {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${({ theme }) =>
      theme.palette.type !== 'dark' ? '#202223' : '#313234'};
  }
  @media (max-width: 700px) {
    width: auto;
  }
`

const UserDropdown = styled(Dropdown)`
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-color: ${({ theme }) => theme.palette.third.dark};
`

const UserMenuItem = styled(MenuItem)`
  height: 35px;
  width: 175px;
  background-color: ${({ theme }) => theme.palette.third.main};
  @media (max-width: 700px) {
    margin: 0px;
  }
`
const TextUser = styled(Text)`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.info.main};
  @media (max-width: 700px) {
    margin: 0px;
  }
`

const TextSeller = styled(Text)`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.info.main};
  @media (max-width: 700px) {
    margin: 0px;
  }
`
const TextUserMini = styled(TextUser)`
  color: ${({ theme }) => theme.palette.text.primary};
  white-space: wrap;
`

const TextSellerMini = styled(TextSeller)`
  white-space: wrap;
  color: ${({ theme }) => theme.palette.text.primary};
`

const UserMenuItemMini = styled(UserMenuItem)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
`

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const dispatch = useDispatch()

  const closeSession = useCallback(
    async () => await dispatch(logout()).unwrap(),
    [dispatch]
  )

  const { user } = useAuth()
  const { name: nameSellerCompany } = useSelector(getSellerById)
  const matches = useMediaQuery('(max-width:700px)')

  const { isSupportsPWA, onInstallApp } = useInstallPWA()
  const { isUpdateWorker, reloadPage } = useWorker()
  return (
    <>
      <ButtonUser
        role="menu"
        onClick={handleClick}
        data-testid="UserMenu__ButtonUser"
      >
        {user?.photoURL && (
          <AvatarSeller src={user?.photoURL} alt={user?.email} />
        )}

        {!user?.photoURL && Boolean(anchorEl) ? (
          <UserSellerRounded fontSize="large" />
        ) : (
          <UserSeller fontSize="large" />
        )}

        {!matches && (
          <AvatarDetail ml="10px" mr="10px" minWidth="100px">
            {user ? (
              <TextUser>
                {matches
                  ? split(user?.displayName, ' ', 1)[0]
                  : user?.displayName}
              </TextUser>
            ) : (
              <Skeleton variant="text" />
            )}
            {nameSellerCompany ? (
              <TextSeller>{nameSellerCompany}</TextSeller>
            ) : (
              <Skeleton variant="text" />
            )}
          </AvatarDetail>
        )}
      </ButtonUser>
      <UserDropdown
        id="logout"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        autoFocus={false}
      >
        {matches && (
          <UserMenuItemMini disabled>
            {user ? (
              <TextUserMini>{user?.displayName}</TextUserMini>
            ) : (
              <Skeleton variant="text" />
            )}
            {nameSellerCompany ? (
              <TextSellerMini>{nameSellerCompany}</TextSellerMini>
            ) : (
              <Skeleton variant="text" />
            )}
          </UserMenuItemMini>
        )}
        {isSupportsPWA && !isUpdateWorker && (
          <UserMenuItem onClick={onInstallApp}>
            <IconContainer>
              {matches ? (
                <InstallMobileIcon fontSize="small" />
              ) : (
                <InstallDesktopIcon fontSize="small" />
              )}
            </IconContainer>
            Descargar App
          </UserMenuItem>
        )}
        {isSupportsPWA && isUpdateWorker && (
          <UserMenuItem onClick={reloadPage}>
            <IconContainer>
              <UpdateOutlinedIcon fontSize="small" />
            </IconContainer>
            Actualizar App
          </UserMenuItem>
        )}
        <UserMenuItem onClick={closeSession}>
          <IconContainer>
            <LogoutOutlined fontSize="small" />
          </IconContainer>
          Cerrar Sesión
        </UserMenuItem>
      </UserDropdown>
    </>
  )
}

export default UserMenu
