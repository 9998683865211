import * as yup from 'yup'

export const schema = yup.object().shape({
  multiProducts: yup
    .mixed()
    .nullable()
    .test('type', 'Tu archivo debe estar en formato xlsx', value => {
      return (
        value &&
        [
          '.xlsx',
          '.xls',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(value[0]?.type)
      )
    })
    .notRequired(),
})
