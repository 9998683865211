import { createAsyncThunk } from '@reduxjs/toolkit'
import sellerService from '../../../data/services/sellerService'
import { decodeJwt } from '../../../data/crypto/decodeJwt'
import isEmpty from 'lodash/isEmpty'

const fetchSeller = createAsyncThunk(
  'seller/byId',
  async ({ slugSeller, token }, { rejectWithValue, getState }) => {
    try {
      const data = await sellerService.bySlug({
        slugSeller: isEmpty(slugSeller)
          ? getState().auth.user?.slugSeller
          : slugSeller,
        token: token || getState().auth.token,
      })
      const certificate = decodeJwt(
        process.env.REACT_APP_KEY_COMMISSIONS_H512
      )
      const decode = certificate(data)

      return decode
    } catch (error) {
      return rejectWithValue(JSON.stringify(error))
    }
  }
)
export default fetchSeller
