/* eslint-disable linebreak-style */

import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import InputPassword from '../../atoms/InputPassword/InputPassword'

const InputPasswordController = ({
  showError = false,
  showPassword = false,
  errors,
  label,
  handleClickShowPassword = () => {},
  handleMouseDownPassword = () => {},
  control,
  name = 'password',
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ref, ...rest } }) => (
        <InputPassword
          {...{
            showError,
            showPassword,
            errors,
            label,
            handleClickShowPassword,
            handleMouseDownPassword,
            name,
          }}
          inputRef={ref}
          onChange={onChange}
          {...rest}
        />
      )}
    />
  )
}

export default InputPasswordController

InputPasswordController.propTypes = {
  showError: PropTypes.bool,
  showPassword: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string,
  control: PropTypes.any,
  name: PropTypes.string,
  handleClickShowPassword: PropTypes.func,
  handleMouseDownPassword: PropTypes.func,
}
