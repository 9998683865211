import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const theme = (modeTheme = 'light') =>
  responsiveFontSizes(
    createTheme({
      palette: {
        background: {
          default: modeTheme === 'dark' ? '#313234' : '#F6F6F7',
          paper: modeTheme === 'dark' ? '#313234' : '#FFFFFF',
        },
        type: modeTheme === 'dark' ? 'dark' : 'light',
        text: {
          primary: modeTheme === 'dark' ? '#FFFFFF' : '#212121',
          secondary: modeTheme === 'dark' ? '#F6F6F7' : '#5C5F62',
        },
        primary: {
          light: modeTheme === 'dark' ? '#FFFFFF' : '#b7b7b7',
          main: '#008060',
          dark: '#005943',
        },
        secondary: {
          light: '#204f92',
          main: modeTheme === 'dark' ? '#FFFFFF' : '#2F71D1',
          dark: '#588dda',
        },
        error: {
          light: '#8d9195',
          main: '#FC8E8E',
          dark: '#E72020',
          contrastText: ' #FFFFFF',
        },
        success: {
          dark: '#058060',
          main: '#F4FCF7',
        },
        warning: {
          main: '#FFC96B',
        },
        info: {
          light: modeTheme !== 'dark' ? '#313234' : '#c1c1c1',
          main: '#E9F4FD',
          dark: modeTheme !== 'dark' ? '#313234' : '#E9F4FD',
        },
        third: {
          light: '#F6F6F7',
          main: '#ffffff',
          dark: '#313234',
          contrastText: 'rgba(107, 107, 107, 1)',
        },
      },
      typography: {
        button: {
          fontSize: 16,
          letterSpacing: 0.2,
          textTransform: 'none',
        },
        body1: {
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '0.15px',
        },
      },
      components: {
        MuiTableRow: {
          hover: { 'background-color': '#F4F4F4' },
        },
        MuiTooltip: {
          tooltip: {
            fontSize: '15px',
          },
          styleOverrides: {
            tooltip: {
              'background-color': '#767676',
              padding: '3px 10px',
              margin: 0,
            },
          },
        },
        MuiRadio: {
          colorPrimary: '#2F71D1',
          root: {
            color: '#8D9195',
            $checked: '#2F71D1',
          },
        },
        MuiCheckbox: {
          root: {
            color: '#5C5F62',
            $checked: '#2F71D1',
          },
        },
        MuiInputBase: {
          'background-color': '#ffffff',
          border: '1px solid #b7b7b7',
          'padding-right': '10px',
          'border-radius': '5px',
        },
        MuiOutlinedInput: {
          'background-color': '#ffffff',
          border: '1px solid #b7b7b7',
          'padding-right': '10px',
          'border-radius': '5px',
        },
        MuiTextField: {
          'background-color': '#ffffff',
          border: '1px solid #b7b7b7',
          'padding-right': '10px',
          'border-radius': '5px',
        },
      },
    })
  )

export default theme
