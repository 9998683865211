import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Tooltip from '../../atoms/ToolTip/ToolTip'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import styled from 'styled-components'
import { useRef } from 'react'
import Navigate from '../../atoms/Navigate'

const GoProduct = styled(Navigate)`
  margin: 0px;
  padding: 0px;
`

const IconButtonBase = styled(IconButton)`
  color: ${({ theme, color }) => color || theme.palette.text.primary};
  z-index: 5;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const ButtonEditProduct = ({
  to,
  titleTooltip,
  color,
  fontSize = 'large',
}) => {
  const iconbuttonRef = useRef()

  return (
    <GoProduct to={to}>
      <Tooltip title={titleTooltip}>
        <IconButtonBase ref={iconbuttonRef} color={color}>
          <EditNoteOutlinedIcon fontSize={fontSize || 'large'} />
        </IconButtonBase>
      </Tooltip>
    </GoProduct>
  )
}
ButtonEditProduct.propTypes = {
  to: PropTypes.any,
  titleTooltip: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
}
ButtonEditProduct.defaultProps = {
  to: undefined,
  titleTooltip: '',
  fontSize: 'large',
  color: 'inherit',
}
export default ButtonEditProduct
