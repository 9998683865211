import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import productImage from '../../../assets/productos.png'
import Title from '../../atoms/BaseTable/Typography/Title'

const NoProductsImage = styled('img')`
  width: 30%;
`

const NoProductsText = styled(Title)`
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #8d9195;
  margin: 40px 0px;
`

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  width: 20%;
  height: 15%;
`

const ProductsNotFound = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <NoProductsImage src={productImage} alt="not-found" />
      <NoProductsText>
        Todavía no has agregado ningún producto.
      </NoProductsText>
      <StyledButton
        onClick={() => navigate('/productos/nuevo')}
        variant="contained"
      >
        Agregar Producto
      </StyledButton>
    </Container>
  )
}

export default ProductsNotFound
