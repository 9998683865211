import React, { Fragment } from 'react'
import isString from 'lodash/isString'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import useMediaQuery from '@mui/material/useMediaQuery'
import { statusColorMapper } from '../../../../domain/mappers/statusColorMapper'

import Tooltip from '../../atoms/ToolTip'
import Text from '../../atoms/Typography/Text'

import Accordion from '../../molecules/Accordion'
import Image from '../../atoms/Image'
import Tag from '../../atoms/Tag/Tag'

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 2rem;
  @media (max-width: 700px) {
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }
`

const ItemCard = styled(Paper)`
  margin-bottom: 5rem;
  box-shadow: 0px 0.836312px 3.34525px rgba(33, 33, 33, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  @media (max-width: 1100px) {
    padding-left: 1rem;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`

const ContentCard = styled('div')`
  padding-top: 16px;
  padding-left: 50px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (max-width: 700px) {
    padding-bottom: 2%;
    padding-top: 2%;
    padding: 1%;
  }
`

const TitleStatus = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-weight: 700;
  font-size: 26px;
  line-height: 27px;
  cursor: text;
`

const TextSku = styled(Text)`
  margin-top: 5px;
  font-weight: normal;
  font-size: 16px;
  cursor: text;
`
const TextBrand = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  cursor: text;
`
const ItemContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 3rem;
  margin-bottom: 1rem;
  @media (max-width: 1100px) {
    padding-right: 1rem;
  }
  @media (max-width: 700px) {
    padding-right: 3%;
    padding-top: 3%;
    width: 100%;
    align-items: flex-start;
  }
`

const Column = styled('div')`
  margin-left: 5%;
  @media (max-width: 1100px) {
    margin-left: 15px;
  }
  @media (max-width: 700px) {
    margin-left: 0px;
    margin-right: 10px;
  }
`

const ContainerColumn = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  @media (max-width: 700px) {
    justify-content: flex-start;
    text-align: left;
  }
`

const ItemContent = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 3rem;
  margin-bottom: 1rem;
  @media (max-width: 1100px) {
    padding-right: 1rem;
  }
`

const ContainerType = styled('div')`
  margin-top: 4rem;
  padding-left: 3.8rem;
  @media (max-width: 1100px) {
    padding-left: 1rem;
  }
`
const HeaderRight = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const TitleProduct = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  cursor: text;
`
const SubTitleID = styled(TitleProduct)`
  white-space: nowrap;
`
const SubTitleDate = styled(SubTitleID)`
  font-size: 14px;
  line-height: 25px;
  color: #5c5f62;
  font-weight: 300;
`
const TitleProductText = styled(TitleProduct)`
  font-weight: 400;
`
const Divider = styled('div')`
  border-bottom: 1px solid #b7b7b7;
  margin-bottom: 1.2rem;
`

const ItemContainerRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
`

const AmountText = styled(TitleProduct)`
  font-size: 14px;
`

const ContainerID = styled('div')`
  margin-right: 5rem;
`

const AccordingContainer = styled(ItemContent)`
  margin-right: 0rem;
  width: 100%;
`
const ImageDefault = styled(Image)`
  object-fit: cover;
  width: 90px;
  height: 90px;
  margin-right: 20px;
  @media (max-width: 700px) {
    margin-right: 2%;
    width: 45px;
    height: 45px;
  }
`
const TagContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex: 1;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

const CardAfterSale = ({
  id,
  type,
  accountingStatement,
  status,
  getProductBySku,
  reason,
  createdAt,
  ...props
}) => {
  const isDisabled = React.useMemo(
    () => status === 'Rechazado' || status === 'Venta',
    [status]
  )
  const matches = useMediaQuery('(max-width:700px)')
  return (
    <Fragment>
      <Header>
        <ContainerType>
          <TitleStatus>{type}</TitleStatus>
          <ContainerID>
            <SubTitleID>{id}</SubTitleID>
          </ContainerID>
          <ContainerID>
            <SubTitleDate>{createdAt}</SubTitleDate>
          </ContainerID>
        </ContainerType>
        <HeaderRight>
          <TagContainer>
            {status && (
              <Tooltip title="Estado despacho">
                <Tag status={statusColorMapper.get(status)}>{status}</Tag>
              </Tooltip>
            )}
            {accountingStatement && (
              <Tooltip title="Estado contable">
                <Tag status={statusColorMapper.get(accountingStatement)}>
                  {accountingStatement}
                </Tag>
              </Tooltip>
            )}
          </TagContainer>
        </HeaderRight>
      </Header>
      <ItemCard disabled={isDisabled}>
        {props?.itemList?.map(product => {
          const priceTotal = product?.price * product?.quantity
          const productBySku = product.sku
            ? getProductBySku(product.sku)
            : {}

          return (
            <Fragment key={`${id}-${reason}-${product?.name}`}>
              <ContentCard>
                <ItemContainer>
                  <ImageDefault
                    src={product?.image || productBySku?.image}
                    alt={productBySku?.alt || product?.sku}
                  />
                  <div>
                    <TitleProduct>{product?.name}</TitleProduct>
                    {matches && (
                      <ContainerColumn>
                        <Column>
                          <TitleProduct>
                            CLP {product?.price?.toLocaleString('es-CL')} x{' '}
                            {product?.quantity}
                          </TitleProduct>
                        </Column>
                        <Column>
                          <TitleProduct>
                            CLP {priceTotal?.toLocaleString('es-CL')}
                          </TitleProduct>
                        </Column>
                      </ContainerColumn>
                    )}
                    {product.sku && (
                      <TextSku>
                        SKU: {product.sku}{' '}
                        {productBySku?.ownSku
                          ? ` | SKU Propio ${productBySku?.ownSku}`
                          : null}
                      </TextSku>
                    )}
                    <TextBrand>
                      Marca:{' '}
                      {isString(product?.brand)
                        ? product?.brand
                        : product?.brand?.brandName}
                    </TextBrand>
                    <TextBrand>
                      Motivo {type.toLowerCase()}: <b>{reason}</b>
                    </TextBrand>
                  </div>
                  {!matches && (
                    <ContainerColumn>
                      <Column>
                        <TitleProduct>
                          CLP {product?.price?.toLocaleString('es-CL')} x{' '}
                          {product?.quantity}
                        </TitleProduct>
                      </Column>
                      <Column>
                        <TitleProduct>
                          CLP {priceTotal?.toLocaleString('es-CL')}
                        </TitleProduct>
                      </Column>
                    </ContainerColumn>
                  )}
                </ItemContainer>
              </ContentCard>
              <Divider />
            </Fragment>
          )
        })}
        <ContentCard>
          <ItemContent>
            <ItemContainerRow>
              <TitleProductText>Venta</TitleProductText>
              <TitleProductText>
                CLP {props?.totalSale?.toLocaleString('es-CL')}
              </TitleProductText>
            </ItemContainerRow>
            <ItemContainerRow>
              <TitleProductText>Comisión</TitleProductText>
              <TitleProductText>
                CLP {props?.commission?.toLocaleString('es-CL')}
              </TitleProductText>
            </ItemContainerRow>
            <div style={{ width: '100%' }}>
              <Accordion
                title={() => (
                  <ItemContainerRow>
                    <TitleProductText>Otros descuentos</TitleProductText>
                    <div>
                      <TitleProductText>
                        CLP {props?.otherDiscount?.toLocaleString('es-CL')}
                      </TitleProductText>
                    </div>
                  </ItemContainerRow>
                )}
              >
                <AccordingContainer>
                  <ItemContainerRow>
                    <AmountText color="textSecondary">
                      Transporte
                    </AmountText>
                    <AmountText color="textSecondary">
                      CLP {props?.transport?.toLocaleString('es-CL')}
                    </AmountText>
                  </ItemContainerRow>
                  <ItemContainerRow>
                    <AmountText color="textSecondary">Multa</AmountText>
                    <AmountText color="textSecondary">
                      CLP {props?.penalty?.toLocaleString('es-CL')}
                    </AmountText>
                  </ItemContainerRow>
                </AccordingContainer>
              </Accordion>
            </div>
            <ItemContainerRow>
              <TitleProduct>Payout (IVA incluido)</TitleProduct>
              <TitleProduct>
                CLP {props?.payout?.toLocaleString('es-CL')}
              </TitleProduct>
            </ItemContainerRow>
          </ItemContent>
        </ContentCard>
      </ItemCard>
    </Fragment>
  )
}
CardAfterSale.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  accountingStatement: PropTypes.string,
  status: PropTypes.string,
  itemList: PropTypes.array,
  totalSale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  otherDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transport: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
  ]),
  penalty: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
  ]),
  commission: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  payout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getProductBySku: PropTypes.func,
  reason: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
}
export default CardAfterSale
