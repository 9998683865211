import styled from 'styled-components'
import BaseCell from '../BaseCell'

const Cell = styled(BaseCell)`
  padding-right: 10px;
  padding-left: 20px;
  text-transform: capitalize;
  white-space: nowrap;
`
Cell.displayName = 'Cell'
export default Cell
