import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export const documentAdapter = createEntityAdapter({
  selectId: document => document,
  sortComparer: (a, b) => String(a).localeCompare(String(b)),
})

export const documentSlice = createSlice({
  name: 'document',
  initialState: documentAdapter.getInitialState(),
  reducers: {
    addAllDocument: documentAdapter.setAll,
    addOneDocument(state, { payload }) {
      if (payload.includes(undefined)) {
        return
      }
      return documentAdapter.addMany(state, payload)
    },
    addDocument: documentAdapter.addOne,
    removeOneDocument: documentAdapter.removeOne,
    removeAllDocument: documentAdapter.removeAll,
  },
})

export const {
  addAllDocument,
  addOneDocument,
  removeOneDocument,
  addDocument,
  removeAllDocument,
} = documentSlice.actions

const getStatedocument = state => state.ui.document

const documentelectors = documentAdapter.getSelectors(getStatedocument)
export const allDocument = documentelectors.selectAll

const reducer = documentSlice.reducer
export default reducer
