import PropTypes from 'prop-types'

import CustomSelectStatus from '../CustomSelectStatus/CustomSelectStatus'

export default function SelectState({
  isfilterright,
  isfilterleft,
  onChange,
  value,
  label,
}) {
  const defaultStatus = [
    'Aprobado',
    'Deshabilitado',
    'Pendiente aprobación',
  ]
  return (
    <CustomSelectStatus
      label={label}
      defaultStatus={defaultStatus}
      selectCurrentItem={value}
      setSelectItem={onChange}
      isfilterright={isfilterright}
      isfilterleft={isfilterleft}
    />
  )
}

SelectState.propTypes = {
  isfilterright: PropTypes.bool,
  isfilterleft: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
}
SelectState.defaultProps = {
  label: 'Estado',
  value: [],
  onChange: x => x,
}
