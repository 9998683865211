import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Navigate = styled(props => <Link {...props} />)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  text-decoration-line: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

export default Navigate
