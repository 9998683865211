import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'

const fetchProductValidate = createAsyncThunk(
  'product/validate',
  async ({ data }, { rejectWithValue }) => {
    try {
      const form = new FormData()
      form.append('file', data[0])

      const response = await productAdminServices.validate(form)
      const valid = response.ok ? response : await response.json()
      if (valid.error) {
        return rejectWithValue(
          valid?.error || 'Error en campos de planilla.'
        )
      }

      return valid.ok
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchProductValidate
