import styled from 'styled-components'
import Text from './Text'

const Title = styled(Text)`
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.04em;
`

export default Title
