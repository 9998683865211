import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import subOrderService from '../../../data/services/subOrderService'

const fetchUpdateDocument = createAsyncThunk(
  'orders/update/document',
  async ({ document, orderId }, { signal }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    const result = await subOrderService.fileUpdate({ document, orderId })
    return result
  }
)
export default fetchUpdateDocument
