import styled from 'styled-components'

const TextError = styled('p')`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.palette.error.dark};
  padding: 0;
  margin: 0;
  margin-left: 38px;
  @media (max-width: 1300px) {
    margin-top: 5px;
  }
`
export default TextError
