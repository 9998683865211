import { createSelector } from '@reduxjs/toolkit'
import { productCreateAdpater } from './productCreateSlice'

const getStateProductCreate = state => state.products.create

export const getProductCreate = createSelector(
  [getStateProductCreate],
  product => product
)

const productCreateSelector = productCreateAdpater.getSelectors(
  getStateProductCreate
)
export const resultsProductCreate = productCreateSelector.selectAll
