import TextField from '@mui/material/TextField'
import styled from 'styled-components'

const TextFieldStyled = styled(TextField)`
  width: 75%;
  margin-bottom: 5%;
  color: ${({ theme }) => theme.palette.text.primary} !important;
  .MuiInput-input {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .MuiFormLabel-colorPrimary {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-align: left;
  }

  .MuiButton-textPrimary {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.04em;
    padding: 0;
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: underline;
  }
  .Mui-disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
  cursor: ${({ isloading }) => (isloading === 'true' ? 'wait' : 'text')};
`
TextFieldStyled.displayName = 'TextFieldStyled'
export default TextFieldStyled
