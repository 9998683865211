import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchLocations from './fetchLocations'
import isArray from 'lodash/isArray'

import sortString from '../../utils/sortString'
import persistentStore from '../../../data/persistentStore'

export const locationListAdaparter = createEntityAdapter({
  selectId: ({ id }) => String(id),
})

export const locationListSlice = createSlice({
  name: 'location/list',
  initialState: locationListAdaparter.getInitialState({
    status: 'idle',
    entities: {},
    onlyNameList: [],
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLocations.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchLocations.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchLocations.fulfilled, (state, { payload }) => {
      state.status = 'finished'

      state.onlyNameList = isArray(payload)
        ? payload.map(({ name }) => name).sort(sortString)
        : []
      locationListAdaparter.setAll(state, payload)
      persistentStore.setLocations(state.onlyNameList)
    })
  },
})

const reducer = locationListSlice.reducer
export default reducer
