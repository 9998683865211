import fromPairs from 'lodash/fromPairs'
import pipe from 'lodash/fp/pipe'

const deleteUndefinedEntries = object =>
  pipe(
    Object.entries,
    o => o.filter(([, value]) => value !== undefined),
    fromPairs
  )(object)

export default deleteUndefinedEntries
