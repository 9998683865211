/* eslint-disable linebreak-style */

import PropTypes from 'prop-types'
import TextFieldStyled from '../../atoms/TextFieldStyled/TextFieldStyled'

const InputEmail = ({
  showError = false,
  errors,
  label,
  onChange,
  disabled = false,
  email,
}) => {
  return (
    <TextFieldStyled
      error={!!errors?.email || showError}
      id="standard-adornment-email"
      variant="standard"
      type="email"
      disabled={disabled}
      autoComplete="email"
      onChange={onChange}
      autoFocus
      defaultValue={email}
      required={true}
      label={label}
      helperText={errors.email ? errors.email.message : null}
    />
  )
}

InputEmail.propTypes = {
  showError: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  email: PropTypes.string,
}

export default InputEmail
