import {
  BrowserRouter,
  Routes as AppRoutes,
  Navigate,
  Route,
} from 'react-router-dom'

import LayoutAuth from '../theme/layouts/Layout'
import NotFound from '../components/pages/NotFound'
import DynamicOnboarding from '../components/pages/DynamicOnboarding'
import Products from '../components/pages/Products/Products'
import NewPassword from '../components/pages/NewPassword'
import AddProduct from '../components/pages/AddProdcut/AddProduct'
import ProductDetails from '../components/pages/ProductDetails'
import Orders from '../components/pages/Orders'
import OrderDetail from '../components/pages/OrderDetail'
import Liquidations from '../components/pages/Liquidations'
import MultipleEdit from '../components/pages/MultipleEdit'
import Expiration from '../components/pages/Expiration'
import Confirmation from '../components/pages/Confirmation'
import MultipleCreate from '../components/pages/MultipleCreate/MultipleCreate'
import { login, logout } from '../../domain/features/user/userAuthSlice'
import { useDispatch, useSelector } from 'react-redux'

import {
  auth,
  onAuthStateChanged,
  getIdTokenResult,
} from '../../data/firebase/auth'
import { useEffect } from 'react'
import normalizeAuth from '../../data/firebase/normalizeAuth'
import persistentStore from '../../data/persistentStore'
import fetchSeller from '../../domain/features/seller/fetchSeller'
import ProtectedRoute from './ProtectedRoute'
import Login from '../components/pages/Login'

const Routes = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(state => state.auth)
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        try {
          const tokenResult = await getIdTokenResult(user)

          persistentStore.setToken(tokenResult.token)

          const userAuth = normalizeAuth({
            ...user,
            ...tokenResult.claims,
          })

          dispatch(
            login.fulfilled({ user: userAuth, token: tokenResult.token })
          )

          await dispatch(
            fetchSeller({
              slugSeller: userAuth.slugSeller,
              token: tokenResult.token,
            })
          ).unwrap()
        } catch (error) {
          const tokenResult = await getIdTokenResult(user, true)
          const userAuth = normalizeAuth({
            ...user,
            ...tokenResult.claims,
          })
          persistentStore.setToken(tokenResult.token)
          dispatch(
            login.fulfilled({
              user: userAuth,
              token: tokenResult.token,
              error: JSON.stringify(error),
            })
          )
        }
      } else {
        await dispatch(logout()).unwrap()
      }
    })

    return () => {
      unsubscribe()
    }
  }, [dispatch])

  return (
    <BrowserRouter
      basename={process.env.PUBLIC_URL || '/'}
      future={{ v7_startTransition: true }}
    >
      <AppRoutes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="productos" replace />
            ) : (
              <Login />
            )
          }
        />
        <Route path="onboarding/:page" element={<DynamicOnboarding />} />
        <Route path="expirado" element={<Expiration />} />
        <Route path="configurar-contrasena" element={<NewPassword />} />
        <Route path="confirmacion" element={<Confirmation />} />

        <Route
          element={
            <ProtectedRoute>
              <LayoutAuth />
            </ProtectedRoute>
          }
        >
          <Route path="productos" element={<Products />} />
          <Route path="productos/nuevo" element={<AddProduct />} />
          <Route path="productos/:id" element={<ProductDetails />} />
          <Route path="productos/editar" element={<MultipleEdit />} />
          <Route
            path="productos/nuevo/masivo"
            element={<MultipleCreate />}
          />
          <Route path="pedidos" element={<Orders />} />
          <Route path="pedidos/:id" element={<OrderDetail />} />
          <Route path="liquidaciones" element={<Liquidations />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </AppRoutes>
    </BrowserRouter>
  )
}

export default Routes
