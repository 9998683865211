import PropTypes from 'prop-types'
import { useState, useCallback, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Collapse from '@mui/material/Collapse'
import fetchUpdateDocument from '../../../../domain/features/orders/fetchUpdateDocument'
import fetchDownloadDocument from '../../../../domain/features/orders/fetchDownloadDocument'
import fetchDeleteDocument from '../../../../domain/features/orders/fetchDeleteDocument'

import Tooltip from '../../atoms/ToolTip/ToolTip'
import FieldFileCustom from '../FieldFile/FieldFileCustom'
import Alert, { typeAlert } from '../../atoms/Alert/Alert'
import { getUpdateDocument } from '../../../../domain/features/orders/orderSlice'
import { updateDocument } from '../../../../domain/features/orderDetail/orderDetailSlice'
import { StyleButtonFileClean } from '../../atoms/ButtonDeleteFile/ButtonDeleteFile'
import Modal from '../../molecules/Modal/Modal'
import trash from '../../../assets/icon/trash.svg'

const Button = styled('a')`
  color: ${({ theme }) => theme?.palette?.secondary?.main};
  font-size: 14px;
`

const DownloadTooltip = styled(Tooltip)`
  text-transform: none;
  text-decoration: underline;
`
const LoadDocument = ({
  name,
  value,
  label,
  disabled,
  ispress = false,
}) => {
  const handleClick = event => {
    event.stopPropagation()
  }

  const dispatch = useDispatch()
  const [updateDocumentMessage, setUpdateDocumentMessage] = useState({
    status: false,
    message: '',
  })

  const [isOpen, setIsOpen] = useState(false)
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm({
    mode: 'all',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  })

  const action = async e => {
    const keys = Object.keys(e)
    const file = e[keys[0]][0]
    if (file.size > 2000000) {
      setError(name, {
        type: 'custom',
        message: 'El archivo no puede exceder 2 MB',
      })
      setUpdateDocumentMessage({
        status: 'error',
        message:
          'Error al adjuntar documento tributario. El archivo debe tener un peso menor a 2MB.',
      })
      return
    } else if (file.type !== 'application/pdf') {
      setError(name, {
        type: 'custom',
        message:
          'Error al adjuntar documento tributario. El archivo debe ser formato PDF',
      })
      setUpdateDocumentMessage({
        status: 'error',
        message:
          'Error al adjuntar documento tributario. El archivo debe ser formato PDF',
      })
    } else {
      dispatch(fetchUpdateDocument({ document: file, orderId: name }))
    }
  }
  const onSubmit = useCallback(action, [dispatch, name, setError])

  const file = useWatch({ name, control })
  useEffect(() => {
    setUpdateDocumentMessage({ status: 'idle', message: '' })
    if (file && file[0]) {
      handleSubmit(onSubmit)()
    }
  }, [file, handleSubmit, onSubmit, setUpdateDocumentMessage])

  const handleDownload = orderId => e => {
    e.preventDefault()
    dispatch(fetchDownloadDocument({ orderId }))
  }

  const handleDeleteFile = orderId => e => {
    e.preventDefault()
    //dispatch()
    setIsOpen(false)
    dispatch(fetchDeleteDocument({ orderId }))
  }
  const { status } = useSelector(getUpdateDocument)
  useEffect(() => {
    if (status === 'finished') {
      dispatch(updateDocument(name))
    }
  }, [status, dispatch, name])

  return (
    <div
      role="button"
      onClick={handleClick}
      onKeyDown={() => {}}
      tabIndex={name}
    >
      {value ? (
        <div>
          <DownloadTooltip title="Descargar" followCursor={true}>
            <Button
              onClick={disabled ? null : handleDownload(name)}
            >{`${label}-${name}.pdf`}</Button>
          </DownloadTooltip>
          {name ? (
            <>
              <Tooltip title="Eliminar" followCursor={true}>
                <StyleButtonFileClean
                  variant="text"
                  startIcon={<img src={trash} alt="delete" />}
                  onClick={() => {
                    setIsOpen(true)
                  }}
                />
              </Tooltip>
              <Modal
                documentName={`${label}-${name}.pdf`}
                open={isOpen}
                onSubmit={handleDeleteFile(name)}
                onClose={() => {
                  setIsOpen(false)
                }}
              ></Modal>
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        <>
          <Collapse in={Boolean(!value)}>
            <div
              style={{
                marginLeft: '10px',
                marginTop: '3px',
              }}
            >
              <FieldFileCustom
                name={name}
                ispress={ispress}
                label={String(label)?.toUpperCase()}
                tooltipTitle="Adjuntar documento tributario (PDF)"
                handleDeleteFile={() => {}}
                control={control}
                register={register}
                errors={errors}
                allFiles={[]}
              />
            </div>
          </Collapse>
          <Alert
            key="upload-document"
            open={
              isSubmitting ||
              updateDocumentMessage.status === 'error' ||
              updateDocumentMessage.status === 'loading'
            }
            onClose={() => {
              setUpdateDocumentMessage({ status: false, message: '' })
            }}
            title={updateDocumentMessage.message}
            isLoading={updateDocumentMessage.status === 'loading'}
            isError={updateDocumentMessage.status === 'error'}
            severity={
              updateDocumentMessage.status === 'error'
                ? typeAlert.get('error')
                : typeAlert.get('success')
            }
            onClick={e => {
              e.preventDefault()
            }}
          />
        </>
      )}
    </div>
  )
}
LoadDocument.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  ispress: PropTypes.bool,
}
LoadDocument.defaultProps = {
  name: '',
  value: '',
  label: '',
  disabled: false,
  ispress: false,
}
export default LoadDocument
