import { createSelector } from '@reduxjs/toolkit'
import { categoryAdapter } from './categorySlice'

const getStateCategory = state => state.category

export const getCategory = createSelector(
  [getStateCategory],
  category => category
)

const categorySelector = categoryAdapter.getSelectors(getStateCategory)
export const resultsCategory = categorySelector.selectAll
export const searchAttribuesByCategory =
  (category = '') =>
  state =>
    categorySelector
      .selectAll(state)
      .find(
        ({ product_type_name }) => String(product_type_name) === category
      )
      ?.attributes.map(({ attribute_name }) => String(attribute_name))
