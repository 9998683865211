import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'

const fetchProductCreateImport = createAsyncThunk(
  'product/createImport',
  async ({ data, seller }, { rejectWithValue }) => {
    try {
      const form = new FormData()
      form.append('file', data[0])
      form.append('seller', seller)
      const response = await productAdminServices.importCreate(form)
      if (response.error) {
        return rejectWithValue('Planilla no validada')
      }

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchProductCreateImport
