import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import trash from '../../../assets/icon/trash.svg'
import Tooltip from '../../atoms/ToolTip'

const Row = styled('div')`
  display: flex;
  flex-direction: row;
`

export const StyleButtonFile = styled(Button)`
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.text.primary};
  justify-content: flex-start;
  height: 35px;
  background: transparent;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 200;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
  text-decoration: underline;
  min-width: 25px;
  .MuiButtonBase-root {
    padding-left: 0;
  }
  .Mui-disabled {
    cursor: not-allowed;
  }
`
export const StyleButtonFileClean = styled(Button)``
const ButtonDelete = styled(StyleButtonFile)`
  width: 17px;
`
const StyleButtonLink = styled(Link)`
  color: black;
  text-decoration: underline;
`

const ButtonDeleteFile = ({ title, name, url, download, ...props }) => {
  return (
    <Row>
      <Tooltip title="Eliminar" name={name} followCursor={true}>
        <ButtonDelete
          variant="text"
          name={name}
          startIcon={<img src={trash} alt="delete" name={name} />}
          {...props}
        />
      </Tooltip>
      <Tooltip title="Descargar" followCursor={true}>
        <StyleButtonFile variant="text">
          <StyleButtonLink href={url} target="blank" download={download}>
            {title}
          </StyleButtonLink>
        </StyleButtonFile>
      </Tooltip>
    </Row>
  )
}
ButtonDeleteFile.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  url: PropTypes.string,
  download: PropTypes.any,
}

export default ButtonDeleteFile
