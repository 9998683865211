import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getCategory } from '../../../../domain/features/category/getCategory'
import CustomSelectStatus from '../CustomSelectStatus/CustomSelectStatus'
import fetchCategory from '../../../../domain/features/category/fetchCategory'

export default function SelectCategory({
  isallfilter,
  isfilterleft,
  onChange,
  value,
  label,
  width = '130px',
}) {
  const dispatch = useDispatch()
  useEffect(() => {
    const promise = dispatch(fetchCategory())
    return () => promise.abort()
  }, [dispatch])

  const { categoryOnlyNameList, status } = useSelector(getCategory)

  return (
    <CustomSelectStatus
      width={width}
      label={label}
      defaultStatus={categoryOnlyNameList}
      selectCurrentItem={value}
      setSelectItem={onChange}
      isallfilter={isallfilter}
      isfilterleft={isfilterleft}
      loading={status === 'loading'}
    />
  )
}

SelectCategory.propTypes = {
  isallfilter: PropTypes.bool,
  isfilterleft: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
  width: PropTypes.string,
}
SelectCategory.defaultProps = {
  label: 'Categoría',
}
