import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchProductCreateValidate from './fetchProductCreateValidate'

export const productCreateValidateAdpater = createEntityAdapter()

export const productCreateValidateSlice = createSlice({
  name: 'product/createValidate',
  initialState: productCreateValidateAdpater.getInitialState({
    status: 'idle',
    ok: false,
    errors: null,
  }),
  reducers: {
    resetProductCreateValidate(state) {
      state.status = 'idle'
      state.errors = null
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProductCreateValidate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(
      fetchProductCreateValidate.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.errors = payload
      }
    )
    builder.addCase(fetchProductCreateValidate.fulfilled, state => {
      state.errors = null
      state.status = 'finished'
    })
  },
})
export const { resetProductCreateValidate } =
  productCreateValidateSlice.actions
const reducer = productCreateValidateSlice.reducer
export default reducer
