import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getSellerById } from '../../../../domain/features/seller/getSeller'

import CustomSelectStatus from '../CustomSelectStatus/CustomSelectStatus'

export default function SelectBrand({
  isfilterright,
  isfilterleft,
  onChange,
  value,
  label,
}) {
  const { brandOnlyNameList } = useSelector(getSellerById)

  return (
    <CustomSelectStatus
      id="brandselect"
      key="brandselect"
      label={label}
      defaultStatus={brandOnlyNameList}
      selectCurrentItem={value}
      setSelectItem={onChange}
      isfilterright={isfilterright}
      isfilterleft={isfilterleft}
    />
  )
}

SelectBrand.propTypes = {
  isfilterright: PropTypes.bool,
  isfilterleft: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
}
SelectBrand.defaultProps = {
  label: 'Marca',
  value: [],
  onChange: x => x,
}
