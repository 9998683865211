import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AutoTextComplete from '@mui/material/Autocomplete'

const AC = forwardRef((props, ref) => (
  <AutoTextComplete {...props} ref={ref} />
))
AC.displayName = 'AC'

const ACWithStyles = styled(AC)`
  min-width: ${({ width }) => (width ? width : '100px')};
  width: fit-content;
  .MuiInputBase-input {
    cursor: ${({ loadingtext }) =>
      loadingtext === 'true' ? 'wait' : 'text'};
    white-space: nowrap;
    width: fit-content;
    max-width: auto;
    min-width: auto;
    @media (max-width: 1100px) {
      min-width: 100%;
      width: 100%;
    }
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    height: 0px;
  }
  .MuiOutlinedInput-root {
    height: 36px;
    white-space: nowrap;
    font-size: 14px;
    padding: 0px !important;
    padding-left: 14px !important;
    border-color: #7c7c7c;
    border-top-right-radius: ${({ isfilterright }) =>
      isfilterright === 'true' ? '0px' : '5px'};
    border-bottom-right-radius: ${({ isfilterright }) =>
      isfilterright === 'true' ? '0px' : '5px'};
    border-top-left-radius: ${({ isfilterleft }) =>
      isfilterleft === 'true' ? '0px' : '5px'};
    border-bottom-left-radius: ${({ isfilterleft }) =>
      isfilterleft === 'true' ? '0px' : '5px'};
  }
  .Mui-disabled {
    cursor: not-allowed;
    color: #d1d0d0;
    border-color: #d1d0d0;
    pointer-events: auto;
  }
`

const Autocomplete = ({
  selectCurrentItem,
  label,
  options,
  isfilterright,
  isfilterleft,
  loading,
  ...props
}) => {
  return (
    <ACWithStyles
      {...props}
      label={label}
      options={options}
      value={[
        selectCurrentItem?.map(item => {
          return { title: item }
        }),
      ]}
      componentsProps={{
        paper: {
          sx: {
            width: 'fit-content',
          },
        },
      }}
      renderTags={() => undefined}
      loadingtext={String(loading)}
      isfilterright={String(isfilterright)}
      isfilterleft={String(isfilterleft)}
      multiple
      fullWidth
      disableCloseOnSelect
      noOptionsText="No hay resultados..."
    />
  )
}

Autocomplete.propTypes = {
  selectCurrentItem: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isfilterright: PropTypes.bool,
  isfilterleft: PropTypes.bool,
  loading: PropTypes.any,
}

Autocomplete.defaultProps = {
  isfilterright: false,
  isfilterleft: false,
}
Autocomplete.displayName = 'Autocomplete'
export default Autocomplete
