import styled from 'styled-components'
import Field from '../Field'
import InputAdornment from '@mui/material/InputAdornment'
import { bool, string, object } from 'prop-types'

const StyleInputAdornment = styled(InputAdornment)`
  .MuiTypography-root {
    color: ${({ error }) => (!error ? '#7c7c7c' : '#e72020')};
    font-size: 13px;
    font-weight: 400, normal;
    padding: 0px 0px 0px 4px;
  }
`

const FieldPrice = ({ error, name, helperText, ...props }) => {
  return (
    <Field
      type="number"
      id={name}
      name={name}
      error={error}
      helperText={helperText}
      {...props}
      InputProps={{
        startAdornment: (
          <StyleInputAdornment error={!!error} position="start">
            $
          </StyleInputAdornment>
        ),
        endAdornment: (
          <StyleInputAdornment error={!!error} position="end">
            CLP
          </StyleInputAdornment>
        ),
      }}
    />
  )
}

FieldPrice.propTypes = {
  label: string,
  required: bool,
  placeholder: string,
  helperText: string,
  error: object,
  name: string,
}

export default FieldPrice
