import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

export const LabelStyled = styled('label')`
  cursor: text;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 9px;
  :disabled {
    color: #d1d0d0;
    cursor: not-allowed;
  }
  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
          color: #d1d0d0;
        `
      : css`
          cursor: default;
        `};
`
const Label = ({ required, children, ...props }) => (
  <LabelStyled {...props}>
    {children}
    {required ? ' *' : ''}
  </LabelStyled>
)
Label.propTypes = {
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
}
export default Label
