import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import persistentStore from '../../../data/persistentStore'
import fetchProductExportAllById from './fetchProductExportAllById'

export const productExportAllByIdAdapter = createEntityAdapter()

export const productExportAllByIdSlice = createSlice({
  name: 'product/exportById',
  initialState: productExportAllByIdAdapter.getInitialState({
    status: 'idle',
    url: '',
    errors: null,
    entities: persistentStore.getProducts(),
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProductExportAllById.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(
      fetchProductExportAllById.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.errors = payload
      }
    )
    builder.addCase(
      fetchProductExportAllById.fulfilled,
      (state, { payload }) => {
        state.status = 'finished'
        const { json } = payload
        state.entities = json
        persistentStore.setProducts(json)
      }
    )
  },
})

const reducer = productExportAllByIdSlice.reducer
export default reducer
