/* eslint-disable linebreak-style */
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { InputAdornment, IconButton } from '@mui/material'
import {
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material'
import TextFieldStyled from '../../atoms/TextFieldStyled/TextFieldStyled'
const IconButtonStyle = styled(IconButton)`
  color: #7c7c7c;
`
const InputPassword = ({
  showError = false,
  showPassword = false,
  errors,
  label,
  handleClickShowPassword = () => {},
  handleMouseDownPassword = () => {},
  onChange,
  name,
}) => {
  return (
    <TextFieldStyled
      error={!!errors[name] || showError}
      id="standard-adornment-password"
      type={showPassword ? 'text' : 'password'}
      variant="standard"
      autoComplete="current-password"
      required={true}
      autoFocus
      label={label}
      onChange={onChange}
      helperText={errors[name] ? errors[name].message : null}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButtonStyle
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <VisibilityOffOutlined />
              ) : (
                <VisibilityOutlined />
              )}
            </IconButtonStyle>
          </InputAdornment>
        ),
      }}
    />
  )
}

InputPassword.propTypes = {
  showError: PropTypes.bool,
  showPassword: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string,
  handleClickShowPassword: PropTypes.func,
  handleMouseDownPassword: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
}

export default InputPassword
