import { string } from 'prop-types'
import styled from 'styled-components'
import Center from '../../../atoms/Center/Center'
import WarningAmberIcon from '../../../../assets/icon/warning.svg'

const Container = styled(Center)`
  max-width: 500px;
  padding-bottom: 6rem;
`
const IconWarning = styled('img')``

const TextError = styled('p')`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.palette.error.light};
  margin: 0;
`

const TextContainer = styled('div')`
  margin-bottom: 20%;
  margin-top: 20px;
`

const ErrorSearch = ({ searchText }) => {
  return (
    <Center data-testid="ErrorSerach__Container--center">
      <Container>
        <IconWarning src={WarningAmberIcon} alt="warning" />
        {searchText ? (
          <TextError>
            No hay resultados para “{searchText}”. Intenta con otra
            búsqueda.
          </TextError>
        ) : (
          <TextContainer>
            <TextError>No hay resultados.</TextError>
            <TextError>Intenta con otra búsqueda.</TextError>
          </TextContainer>
        )}
      </Container>
    </Center>
  )
}
ErrorSearch.propTypes = { searchText: string }
export default ErrorSearch
