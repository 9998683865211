import Autocomplete from '@mui/material/Autocomplete'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import Field from '../../atoms/Field'
import styled from 'styled-components'
import { colors, getColorCode } from './colors'

import InputAdornment from '@mui/material/InputAdornment'
import Box from '@mui/material/Box'

const TextFieldKC = styled(props => <Field {...props} />)`
  margin-top: 5px;
  .MuiOutlinedInput-root {
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    padding-left: 14px;
    width: 90%;
  }
  @media (max-width: 1100px) {
    .MuiOutlinedInput-root {
      width: 100%;
    }
  }
`

const FieldColorAutocomplete = ({
  control,
  name,
  defaultValue,
  placeholder,
  loading,
  helperText,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, ref, value, ...fields },
        fieldState: { error },
      }) => (
        <Autocomplete
          {...props}
          options={colors.map(({ label }) => String(label))}
          fullWidth
          disablePortal
          value={value}
          defaultValue={defaultValue}
          onChange={(_, data) => onChange(data)}
          onInputChange={(_, data) => onChange(data)}
          renderInput={params => {
            return (
              <TextFieldKC
                loading={String(loading)}
                disabled={String(loading) === 'true'}
                {...{ name, ...params }}
                value={value}
                defaultValue={defaultValue}
                error={error}
                inputRef={ref}
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: value && (
                    <InputAdornment position="start">
                      <Box
                        sx={{
                          backgroundColor: getColorCode(value),
                          border: '1px solid #313234',
                          width: 18,
                          height: 18,
                          borderRadius: '50%',
                          marginRight: 1,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                helperText={helperText}
                {...fields}
              />
            )
          }}
        />
      )}
    />
  )
}

FieldColorAutocomplete.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.array,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  loading: PropTypes.any,
  helperText: PropTypes.any,
}

export default FieldColorAutocomplete
