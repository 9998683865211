import React from 'react'
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { Popover, Tooltip } from '@mui/material'
import Text from '../../atoms/Typography/Text'
import ModalDownload from '../ModalDowload/ModalDownload'
import persistentStore from '../../../../data/persistentStore'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

const ButtonDownloaStyled = styled(IconButton)`
  border: solid 1px #d1d0d0;
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 10px;
  &:disabled {
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'not-allowed'};
    pointer-events: auto;
  }
`

const ButtonOptionDropdown = styled(Button)`
  padding-left: 10%;
  &:disabled {
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'not-allowed'};
    pointer-events: auto;
  }
`
const Container = styled('div')`
  margin-left: 15px;
`

const DropdownContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 180px;
  padding-top: 7px;
`

const StyledText = styled(Text)`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  text-transform: none;
  padding-bottom: 7px;
  &:disabled {
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'not-allowed'};
    pointer-events: auto;
  }
`
const ButtonDownload = ({
  type,
  handleClickOptionOne,
  handleClickOptionTwo,
  showModalOnDownload,
  disabled,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [modalOpen, setModalOpen] = React.useState(false)
  const optionSelect = React.useRef(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onCloseModal = () => {
    setModalOpen(false)
  }

  const onSubmitModal = async accepted => {
    persistentStore.setDownloadProductTemplateAccept(accepted)
    optionSelect?.current()
    onCloseModal()
  }

  const clickOption = method => {
    const accepted = persistentStore.getDownloadProductTemplateAccept()
    if (accepted || !showModalOnDownload) {
      method()
    } else {
      optionSelect.current = method
      setModalOpen(true)
    }
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      {showModalOnDownload && (
        <ModalDownload
          open={modalOpen}
          onClose={onCloseModal}
          onSubmit={onSubmitModal}
        />
      )}
      {type === 'select' ? (
        <Container>
          <Tooltip title="Descargar planilla">
            <ButtonDownloaStyled
              aria-describedby={id}
              variant="outlined"
              onClick={handleClick}
              disabled={disabled}
            >
              <FileDownloadOutlinedIcon />
            </ButtonDownloaStyled>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            disabled={disabled}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <DropdownContainer>
              <ButtonOptionDropdown
                variant="text"
                size="large"
                onClick={() => clickOption(handleClickOptionOne)}
              >
                <StyledText>Con filtros aplicados</StyledText>
              </ButtonOptionDropdown>
              <ButtonOptionDropdown
                variant="text"
                size="large"
                onClick={() => clickOption(handleClickOptionTwo)}
              >
                <StyledText>Listado completo</StyledText>
              </ButtonOptionDropdown>
            </DropdownContainer>
          </Popover>
        </Container>
      ) : (
        <Tooltip title="Descargar">
          <ButtonDownloaStyled
            variant="outlined"
            disabled={disabled}
            {...props}
          >
            <FileDownloadOutlinedIcon />
          </ButtonDownloaStyled>
        </Tooltip>
      )}
    </>
  )
}

ButtonDownload.propTypes = {
  type: PropTypes.string,
  handleClickOptionOne: PropTypes.func,
  handleClickOptionTwo: PropTypes.func,
  showModalOnDownload: PropTypes.bool,
  disabled: PropTypes.bool,
}
ButtonDownload.defaultProps = {
  disabled: false,
}
export default ButtonDownload
