import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useSpring, animated } from '@react-spring/web'
import { isOpenDrawer } from '../../../domain/features/ui/drawerSlice'
import { drawerWidth } from '../../components/template/Drawer/Drawer'
import useMediaQuery from '@mui/material/useMediaQuery'

const MarginAnimation = styled(animated('div'))`
  margin-left: 5%;
  margin-right: 3%;
  @media (max-width: 1100px) {
    margin-left: 2.8%;
  }
  @media (max-width: 700px) {
    margin-right: 2%;
    margin-left: 1%;
  }
`
MarginAnimation.displayName = 'MarginAnimation'

const Margin = ({ children }) => {
  const open = useSelector(isOpenDrawer)
  const isMobile = useMediaQuery('(max-width:1100px)')
  const animation = useSpring({
    to: {
      marginLeft: !open ? '90px' : `${drawerWidth + 20}px`,
    },
    native: true,
    config: { mass: 1, tension: 170, friction: 26, clamp: true },
  })

  return (
    <MarginAnimation style={isMobile ? null : animation}>
      {children}
    </MarginAnimation>
  )
}

Margin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
}

export default Margin
