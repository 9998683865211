import { createSelector } from '@reduxjs/toolkit'
import { productUpdateAdpater } from './productUpdateSlice'

const getStateProductUpdate = state => state.products.update

export const getProductUpdate = createSelector(
  [getStateProductUpdate],
  product => product
)

const productUpdateSelector = productUpdateAdpater.getSelectors(
  getStateProductUpdate
)
export const resultsProductUpdate = productUpdateSelector.selectAll
