import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import styled from 'styled-components'
import fetchUpdateProductStatus from '../../../../domain/features/productSearchApi/fetchUpdateProductStatus'

const IconMenuButton = styled(IconButton)`
  padding: 5px;
  margin: 0px;
`

export default function ActionButton({ productId, status }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeStatus = React.useCallback(() => {
    dispatch(
      fetchUpdateProductStatus({
        id: productId,
        status: new Map([
          ['ACTIVE', 'ARCHIVED'],
          ['DRAFT', 'ARCHIVED'],
          ['ARCHIVED', 'DRAFT'],
        ]).get(status),
      })
    )
    setAnchorEl(null)
  }, [dispatch, setAnchorEl, productId, status])

  const open = Boolean(anchorEl)

  const options = React.useMemo(
    () =>
      new Map([
        [
          'ACTIVE',
          <MenuItem key="ACTIVE" onClick={handleChangeStatus}>
            Deshabilitar
          </MenuItem>,
        ],
        [
          'DRAFT',
          <MenuItem key="DRAFT" onClick={handleChangeStatus}>
            Deshabilitar
          </MenuItem>,
        ],
        [
          'ARCHIVED',
          <MenuItem key="ARCHIVED" onClick={handleChangeStatus}>
            Solicitar aprobación
          </MenuItem>,
        ],
      ]).get(status),
    [handleChangeStatus, status]
  )

  return (
    <>
      <IconMenuButton
        aria-haspopup="true"
        aria-label="more"
        aria-controls="long-menu"
        variant="contained"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconMenuButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options || null}
      </Menu>
    </>
  )
}

ActionButton.propTypes = {
  productId: PropTypes.string,
  status: PropTypes.string,
}
