import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import FieldAutoComplete from '../FieldAutoComplete'
import fetchCategory from '../../../../domain/features/category/fetchCategory'
import { getCategory } from '../../../../domain/features/category/getCategory'
import Label from '../../atoms/Label'

const FieldCategory = ({ control, defaultValue }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const promise = dispatch(fetchCategory())
    return () => promise.abort()
  }, [dispatch])
  const { categoryOnlyNameList, status } = useSelector(getCategory)

  return (
    <>
      <Label htmlFor="Categoría" required>
        Categoría
      </Label>
      <FieldAutoComplete
        control={control}
        options={categoryOnlyNameList}
        defaultValue={defaultValue}
        name="category"
        placeholder="Selecciona una categoría"
        disabled={status === 'loading'}
        loading={status === 'loading'}
        loadingText="Cargando Categorías..."
      />
    </>
  )
}

FieldCategory.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
}
export default FieldCategory
