import { Chip as Pill, styled } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
const StyledChip = styled(Pill)`
  .MuiChip-root {
    width: 70%;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 10px;
  }
  .MuiChip-deleteIcon {
    position: absolute;
    left: 0.8rem;
    display: flex;
    justify-content: center;
    height: 16px;
    width: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .MuiChip-label {
    padding-left: 1.5rem;
  }
`
const Chip = props => {
  return <StyledChip deleteIcon={<CloseIcon />} {...props} />
}

export default Chip
