import { useState, useMemo, useEffect } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import fetchProductUpdateMedia from '../../../../domain/features/productUpdate/fetchProductUpdateMedia'
import fetchProductChangeImagePosition from '../../../../domain/features/productChangeImagePosition/fetchProductChangeImagePosition'

import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Collapse from '@mui/material/Collapse'

import CheckboxImage from '../../atoms/CheckboxImage'
import Text from '../../atoms/Typography/Text'
import UploadImageButton from '../../atoms/UploadImageButton'
import Checkbox from '../../atoms/Checkbox'
import TextError from '../../atoms/Typography/TextError'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`
const ContainerCheckboxImage = styled('div')`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 3%;
  align-items: flex-end;
`
const SubContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2%;
`
const ContainerVariantText = styled('div')`
  display: flex;
  flex-direction: row;
`
const CheckboxStyle = styled(Checkbox)`
  margin-left: 5px;
  margin-right: 5px;
`
const VariantText = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  color: #212121;
`
const TextStyle = styled(Text)`
  color: #7c7c7c;
  font-size: 11px;
  font-weight: 400;
`
const DeleteVariantListStyle = styled(Button)`
  font-size: 19px;
  font-weight: bold;
  line-height: 22px;
  text-decoration: underline;
  text-transform: capitalize;
  color: #2469ce;
  padding: 0;
`
const FormController = styled(FormControlLabel)`
  padding: 0;
  margin: 0;
`
const TextErrorMedia = styled(TextError)`
  margin-left: 0px;
`
const DragIcon = styled('div')`
  position: absolute;
  right: 2px;
  top: 2px;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

const CheckBoxItem = styled('div')`
  position: relative;
  border: solid 1px #7c7c7c;
  padding: 2px 3px;
  border-radius: 10px;
  display: flex;
  align-items: center;
`
const PrincipalImageText = styled(Text)`
  font-style: italic;
  color: #7c7c7c;
`
const DragIconVisible = styled(DragIcon)`
  display: flex;
`

const createMedia = (file, updateUploadImage, checked, position) => ({
  id: file.name,
  alt: file.name,
  file: file,
  name: file.name,
  src: updateUploadImage,
  checked: checked,
  position,
  indeterminate: false,
})
const validType = type =>
  ['image/jpeg', 'image/jpg', 'image/png'].includes(type)
const validSize = size => size <= 400000

export function VariantCheckboxImage({
  errors,
  setError,
  clearErrors,
  control,
  mediaList,
  isDetailsView,
  productId,
}) {
  const { replace } = useFieldArray({
    control,
    name: 'variants',
  })

  const { append, remove, move } = useFieldArray({
    control,
    name: 'media',
  })

  const { append: appendToDelete } = useFieldArray({
    control,
    name: 'mediaToDelete',
  })

  const {
    append: mediaToMove,
    update: mediaToUpdate,
    fields: fieldsToMove,
  } = useFieldArray({
    control,
    name: 'mediaPosition',
  })

  const [newMedia, setNewMediaList] = useState([])

  useEffect(() => {
    setNewMediaList(mediaList)
  }, [mediaList])

  const [checked, setChecked] = useState(false)

  const handleAllChecked = () => {
    setChecked(!checked)
    const newMediaImageList = prev => {
      return [...prev].map(media => ({ ...media, checked: !checked }))
    }
    setNewMediaList(newMediaImageList)
  }

  const handleOnChange = event => {
    const currentVariant = Array(...newMedia).find(
      media => media.id === event.target.name
    )
    const checkedMediaImage = {
      ...currentVariant,
      checked: event.target.checked,
    }
    const newMediaImageList = prev => {
      return [...prev].map(media =>
        media.id === checkedMediaImage.id
          ? { ...checkedMediaImage }
          : { ...media }
      )
    }
    setNewMediaList(newMediaImageList)
  }

  const deleteMedia = async () => {
    const indexToDelete = []
    Array(...newMedia).forEach((media, index) => {
      if (media?.checked && !media.file.lastModified) {
        appendToDelete(media)
      }
      media?.checked && indexToDelete.push(index)
    })

    clearErrors()
    remove(indexToDelete)
    setNewMediaList(prev => [...prev].filter(({ checked }) => !checked))
    setChecked(false)
  }

  const countTotal = useMemo(() => Array(...newMedia).length, [newMedia])

  const dispatch = useDispatch()
  const handleUploadImage = async media => {
    try {
      dispatch(
        fetchProductUpdateMedia({
          media,
          productId: productId,
          status: 'DRAFT',
        })
      )
    } catch (error) {
      throw new Error(`Update product image error: ${error}`)
    }
  }
  const variantWatch = useWatch({
    control,
    name: 'variants',
  })

  const [erroMax, setErrorMax] = useState('')
  const selectedHandler = async event => {
    const max = 15
    if (event?.length + countTotal > max) {
      return setErrorMax(
        `Recuerda puedes subir hasta ${max} imágenes por producto, excediste el máximo en ${
          max - event?.length
        } imágen(es)`
      )
    }
    setErrorMax('')
    const filePromises = event.map((file, index) => {
      // Return a promise per file
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = async () => {
          try {
            const updateUploadImage = reader.result
            const media = createMedia(
              file,
              updateUploadImage,
              checked,
              newMedia.length
            )

            if (
              countTotal === 0 &&
              index === 0 &&
              validSize(file?.size) &&
              validType(file?.type)
            ) {
              if (variantWatch && variantWatch.length > 0) {
                const newVariants = variantWatch.map((variant, idx) => {
                  if (idx === 0) {
                    return { ...variant, mediaIds: [file.name] }
                  }
                  return { ...variant }
                })
                replace(newVariants)
              } else {
                replace([{ mediaIds: [file.name] }])
              }
            }
            append(media)
            setNewMediaList(prevMediaList => [...prevMediaList, media])

            if (
              validSize(file?.size) &&
              validType(file?.type) &&
              isDetailsView
            ) {
              await handleUploadImage([media])
            }
            resolve(media)
          } catch (err) {
            reject(err)
          }
        }
        reader.onerror = error => {
          reject(error)
        }
        reader.readAsDataURL(file)
      })
    })
    const fileInfos = await Promise.all(filePromises)
    return fileInfos
  }

  const count = useMemo(
    () => Array(...newMedia).filter(({ checked }) => checked).length,
    [newMedia]
  )

  useEffect(() => {
    if (!newMedia) {
      return
    }
    newMedia.forEach(({ file, status }, index) => {
      if (status === 'READY') {
        return
      }

      if (!validSize(file?.size)) {
        setError(
          `media.${index}.file`,
          {
            type: 'size',
            message: `El archivo número ${
              index + 1
            } debe tener un peso menor a 400 kB`,
          },
          { shouldFocus: true }
        )
      }
      if (!validType(file?.type)) {
        setError(
          `media.${index}.file`,
          {
            type: 'type',
            message: `El archivo número ${
              index + 1
            } debe estar en formato PNG o JPG`,
          },
          { shouldFocus: true }
        )
      }
    })
  }, [newMedia, setError])

  const addFieldToMove = newField => {
    const index = fieldsToMove.findIndex(
      field => field.alt === newField.alt
    )
    if (index === -1) {
      mediaToMove(newField)
    } else {
      mediaToUpdate(index, newField)
    }
  }

  const handleDrag = ({ source, destination }) => {
    if (destination) {
      const src = newMedia[source.index]
      const dest = newMedia[destination.index]
      if (src.alt === src.id || dest.alt === dest.id) {
        move(source.index, destination.index)
      }
      if (src.image) {
        addFieldToMove({
          alt: src.alt,
          position: destination.index,
        })
      }
      if (dest.image) {
        addFieldToMove({
          alt: dest.alt,
          position: destination.index,
        })
      }

      setNewMediaList(media => {
        const newM = [...media]
        newM.splice(source.index, 1)
        newM.splice(destination.index, 0, src)
        return newM
      })
      dispatch(
        fetchProductChangeImagePosition({
          productId: `gid://shopify/Product/${productId}`,
          imageId: src.id,
          position: destination.index,
        })
      )
    }
  }

  return (
    <Container>
      <Collapse in={count > 0}>
        <SubContainer>
          <ContainerVariantText>
            <FormController
              control={
                <CheckboxStyle
                  checked={count > 0}
                  indeterminate={count > 0}
                  onChange={handleAllChecked}
                />
              }
            />
            <VariantText>
              {count === 1
                ? `${count} imagen seleccionada`
                : `${count} imagenes seleccionadas`}
            </VariantText>
          </ContainerVariantText>
          <DeleteVariantListStyle onClick={deleteMedia}>
            Eliminar
          </DeleteVariantListStyle>
        </SubContainer>
      </Collapse>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="test-items" direction="horizontal">
          {providedDrop => (
            <ContainerCheckboxImage
              {...providedDrop.droppableProps}
              ref={providedDrop.innerRef}
            >
              {newMedia.map((media, index) => {
                return (
                  <Draggable
                    key={`test[${index}]`}
                    draggableId={`item-${index}`}
                    index={index}
                  >
                    {provided => (
                      <div>
                        {index === 0 && (
                          <PrincipalImageText>
                            Imagen principal
                          </PrincipalImageText>
                        )}
                        <CheckBoxItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <CheckboxImage
                            index={index}
                            key={String(index)}
                            size={index === 0 ? 'large' : 'small'}
                            src={
                              media?.preview?.image?.url
                                ? media?.preview?.image?.url
                                : media.src
                            }
                            alt={
                              media?.preview?.image?.altText
                                ? media?.preview?.image?.altText
                                : media?.alt || media?.name
                            }
                            id={media.id || media.id}
                            name={media.id || media.name}
                            onChange={handleOnChange}
                            checked={media.checked}
                            errors={errors}
                            isDetailsView={isDetailsView}
                            control={control}
                            media={media}
                            appendToDelete={appendToDelete}
                            setNewMediaList={setNewMediaList}
                          />
                          <DragIconVisible {...provided.dragHandleProps}>
                            <DragIndicatorRoundedIcon fontSize={'small'} />
                          </DragIconVisible>
                        </CheckBoxItem>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {providedDrop.placeholder}

              {countTotal >= 15 ? null : (
                <UploadImageButton
                  name="media"
                  onChange={selectedHandler}
                  control={control}
                />
              )}
            </ContainerCheckboxImage>
          )}
        </Droppable>
      </DragDropContext>
      {!isEmpty(errors?.media) ? (
        <TextErrorMedia>
          Una o más de tus imágenes tiene peso mayor a 400 KB o es de
          formato distinto a JPG o PNG.
        </TextErrorMedia>
      ) : (
        <TextStyle>
          {erroMax
            ? `${erroMax}, cada una debe ser cuadrada, de 2000x2000 px y con tamaño entre 250 y 400KB, siguiendo indicaciones del manual.`
            : 'Puedes subir hasta 15 imágenes por producto, cada una debe ser cuadrada, de 2000x2000 px y con tamaño entre 250 y 400KB, siguiendo indicaciones del manual.'}
        </TextStyle>
      )}
    </Container>
  )
}

VariantCheckboxImage.propTypes = {
  mediaList: PropTypes.array,
  control: PropTypes.any,
  setError: PropTypes.func,
  errors: PropTypes.object,
  clearErrors: PropTypes.func,
  isDetailsView: PropTypes.bool,
  productId: PropTypes.string,
}

VariantCheckboxImage.defaultProps = {
  mediaList: [],
  isDetailsView: false,
}

export default VariantCheckboxImage
