import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchBrands from './fetchBrands'
import isArray from 'lodash/isArray'
import sortString from '../../utils/sortString'
import persistentStore from '../../../data/persistentStore'

export const brandsAdapter = createEntityAdapter({
  selectId: ({ id }) => String(id),
})

export const brandSlice = createSlice({
  name: 'brands',
  initialState: brandsAdapter.getInitialState({
    status: 'idle',
    entities: {},
    brandOnlyNameList: persistentStore.isBrands()
      ? persistentStore?.getBrands()
      : [],
    brands: [],
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchBrands.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchBrands.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchBrands.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.brandOnlyNameList = isArray(payload)
        ? payload.map(({ brandName }) => brandName).sort(sortString)
        : []
      state.brands = payload
      brandsAdapter.setAll(state, payload)
      persistentStore.setBrands(state.brandOnlyNameList)
    })
  },
})

const reducer = brandSlice.reducer
export default reducer
