import capitalizeName from '../../utils/capitalizeName'

const normalizeOrderDelivery = ({
  destination: {
    destination_county,
    destination_region,
    destination_address1,
    destination_address2,
  },
  customer: { customer_name, customer_rut },
}) => ({
  name: capitalizeName(customer_name),
  rut: customer_rut,
  address: capitalizeName(
    `${destination_address1 || ''} ${destination_address2 || ''}`
  ),
  destination_county: capitalizeName(destination_county),
  destination_region: capitalizeName(destination_region),
})

export default normalizeOrderDelivery
