import Menu from '@mui/material/Menu'

import styled from 'styled-components'

const DropdownListStyled = styled(Menu)`
  .MuiPaper-root {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top-color: #ffffff;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%);
  }
`

const Dropdown = props => {
  return (
    <DropdownListStyled
      elevation={4}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  )
}

export default Dropdown
