import styled from 'styled-components'

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    margin-top: 0.3rem;
    margin-bottom: 3rem;
  }
  @media (max-width: 700px) {
    margin-bottom: 0.5rem;
  }
`
export default HeaderContainer
