/* eslint react/prop-types: 0 */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import fetchLiquidations from '../../../../domain/features/liquidations/fetchLiquidations'
import { getCurrentDate } from '../../../../domain/features/ui/rangeOfDaysSlice'
import { allAccountingStatement } from '../../../../domain/features/ui/accountingStatementSlice'
import {
  allLiquidations,
  getLiquidation,
} from '../../../../domain/features/liquidations/getLiquidations'
import downloadLink from '../../../../domain/adapters/download/downloadBill'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import arrow from '../../../assets/icon/arrowDown.png'
import Title from '../../atoms/BaseTable/Typography/Title'
import RangeOfDays from '../../organisms/RangeOfDays/RangeOfDays'
import SelectAccountingStatement from '../../organisms/SelectAccountingStatement/SelectAccountingStatement'
import TableTemplate from '../../template/TableTemplate'
import CellNumber from '../../atoms/BaseTable/BaseCell/CellNumber/CellNumber'
import HeaderContainer from '../../atoms/HeaderContainer'
import IconPdf from '../../atoms/Icon/IconPdf'
import IconExcel from '../../atoms/Icon/IconExcel'
import ChipManager from '../../organisms/ChipManager'
import FilterLiquidationDowload from '../../molecules/FilterLiquidationDowload/FilterLiquidationDowload'
import { getLiquidationsDownload } from '../../../../domain/features/liquidationListDownload/getLiquidationsDownload'
import fetchLiquidationDownload from '../../../../domain/features/liquidationListDownload/fetchLiquidationsDownload'
import Alert from '../../atoms/Alert/Alert'
import { getSellerById } from '../../../../domain/features/seller/getSeller'

const stateDownload = () =>
  new Map([
    [
      'loading',
      'Descargando liquidaciones, esto podria demorar varios minutos. ',
    ],
    ['error', 'Error al descargar liquidaciones.'],
  ])

const ButtonDownload = styled(Button)`
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: normal;
  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `};
  &:hover {
    background-color: transparent;
  }
  min-width: auto;
  max-width: auto;
  width: auto;
`

const RowCenter = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
`

const IconButtonStyle = styled(ButtonDownload)`
  padding: 8px;
`
const IconButtonStyleLeft = styled(ButtonDownload)`
  padding: 5px;
`
const accountingStatementBase = [
  'Facturado - No Pagado',
  'Pagado',
  'Contabilizado',
  'Procesando',
]
const Liquidations = () => {
  const dispatch = useDispatch()

  const columns = useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: 'date',
      },
      {
        Header: 'Venta',
        accessor: 'totalSale',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Comisión',
        accessor: 'commission',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Otros descuentos',
        accessor: 'otherDiscount',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Payout',
        accessor: 'payout',
        Cell: cell => <CellNumber number={cell.value} />,
      },
      {
        Header: 'Estado contable',
        accessor: 'accountingStatement',
      },
      {
        Header: 'Factura',
        accessor: 'bill',
        Cell: ({ row }) => {
          const pdfBill = row.values?.bill
          return isNil(pdfBill) ? null : (
            <ButtonDownload
              disabled={isNil(pdfBill)}
              variant="text"
              onClick={() =>
                downloadLink(
                  pdfBill,
                  `Factura-${row?.original?.sellerName}-${row?.original?.dateBill}`
                )
              }
              endIcon={() => <img src={arrow} alt="download" />}
            >
              Descargar
            </ButtonDownload>
          )
        },
      },
      {
        Header: 'Reporte',
        accessor: 'report',
        Cell: ({ row }) => {
          return (
            <RowCenter>
              <IconButtonStyleLeft
                onClick={() =>
                  downloadLink(
                    row?.values?.report?.pdfReport,
                    `Reporte-${row?.original?.sellerName}-${String(
                      row.values.date
                    ).replaceAll('/', '-')}`,
                    'pdf'
                  )
                }
              >
                <IconPdf />
              </IconButtonStyleLeft>
              <IconButtonStyle
                onClick={() =>
                  downloadLink(
                    row?.values?.report?.excelReport,
                    `Reporte-${row?.original?.sellerName}-${String(
                      row.values.date
                    ).replaceAll('/', '-')}`,
                    'xlsx'
                  )
                }
              >
                <IconExcel />
              </IconButtonStyle>
            </RowCenter>
          )
        },
      },
    ],
    []
  )

  const date = useSelector(getCurrentDate)

  const accountingStatementList = useSelector(allAccountingStatement)

  const [pageCurrentIndex, setPage] = useState(0)
  const [perPage, setPerPage] = useState(25)

  const { status: statusSeller } = useSelector(getSellerById)

  useEffect(() => {
    if (statusSeller !== 'finished') {
      return
    }

    const promise = dispatch(
      fetchLiquidations({
        startDate: date[0],
        endDate: date[1],
        accountingStatement:
          accountingStatementList && accountingStatementList?.length > 0
            ? accountingStatementList
            : accountingStatementBase,
        skip: pageCurrentIndex || 0,
        limit: perPage || 25,
      })
    )
    return () => {
      promise.abort()
    }
  }, [
    statusSeller,
    dispatch,
    date,
    accountingStatementList,
    pageCurrentIndex,
    perPage,
  ])

  const { pagination, status } = useSelector(getLiquidation)
  const data = useSelector(allLiquidations)

  const rows = useMemo(
    () => (status === 'loading' ? Array(25).fill({}) : data),
    [status, data]
  )

  // Download
  const handleDownload = useCallback(
    ({ detailed }) => {
      dispatch(
        fetchLiquidationDownload({
          startDate: date[0],
          endDate: date[1],
          accountingStatement:
            accountingStatementList && accountingStatementList?.length > 0
              ? accountingStatementList
              : accountingStatementBase,
          detailed,
        })
      )
    },
    [accountingStatementList, date, dispatch]
  )
  const { status: statusDownload } = useSelector(getLiquidationsDownload)
  return (
    <>
      <HeaderContainer>
        <Title>Liquidaciones</Title>
      </HeaderContainer>
      <Paper>
        <FilterLiquidationDowload
          enabledDownload={
            date[0] || date[1] || accountingStatementList.length > 0
              ? true
              : false
          }
          onDowloadExcelDetail={() => handleDownload({ detailed: true })}
          onDownloadExcel={() => handleDownload({ detailed: false })}
        >
          <div style={{ display: 'flex' }}>
            <RangeOfDays />
            <SelectAccountingStatement
              isallfilter={false}
              list={accountingStatementBase}
            />
          </div>
        </FilterLiquidationDowload>
        <ChipManager
          date={date}
          accountingStatement={accountingStatementList}
        />
        <TableTemplate
          pathname="/liquidaciones"
          isLoading={status === 'loading' || isEmpty(data)}
          isError={status === 'error'}
          columns={columns}
          data={rows}
          withPage={true}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          pageCurrentIndex={pageCurrentIndex || 0}
          totalPage={pagination?.paginationTotalCount || 0}
        />
      </Paper>

      <Alert
        open={statusDownload === 'loading'}
        title={stateDownload().get(statusDownload)}
        isLoading={statusDownload === 'loading'}
        severity={'info'}
        isError={statusDownload === 'error'}
      />
    </>
  )
}
export default Liquidations
