import persistentStore from '../persistentStore'

const userServices = baseUrl => ({
  firstLogin: async ({ uid, token }) => {
    return await fetch(`${baseUrl}/user/first-login/${uid}`, {
      method: 'PATCH',
      headers: {
        authorization: token || persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
    })
  },
})

export default userServices(
  process.env.REACT_APP_API_USER_PATH
)
