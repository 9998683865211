import PropTypes from 'prop-types'
import ListItemText from '@mui/material/ListItemText'
import MuiSelect from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'

import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'

import styled from 'styled-components'
import TextSecondary from '../Typography/TextSecondary'

export const Wrapper = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  .MuiSelect-selectMenu {
    min-height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .Mui-disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

export const SelectStyled = styled(MuiSelect)`
  height: 36px;
  border-top-right-radius: ${({ isallfilter }) =>
    isallfilter === 'true' ? '0px' : '5px'};
  border-bottom-right-radius: ${({ isallfilter }) =>
    isallfilter === 'true' ? '0px' : '5px'};
  border-top-left-radius: ${({ isfilterleft }) =>
    isfilterleft === 'true' ? '0px' : '5px'};
  border-bottom-left-radius: ${({ isfilterleft }) =>
    isfilterleft === 'true' ? '0px' : '5px'};
  box-sizing: border-box;

  @media (max-width: 700px) {
    max-width: 100%;
  }
  .Mui-disabled {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
    pointer-events: auto;
  }
`

const CheckboxStyled = styled(Checkbox)`
  width: 18px;
  height: 18px;
  padding-right: 20px;
  padding-top: 11px;
`
const Select = ({
  label,
  list,
  value,
  isCheckbox,
  handleDelete,
  handleDeleteAll,
  isallfilter = true,
  isfilterleft = true,
  ...props
}) => {
  return (
    <Wrapper variant="outlined">
      <SelectStyled
        {...props}
        displayEmpty
        isallfilter={String(isallfilter)}
        isfilterleft={String(isfilterleft)}
        labelId="simple-select"
        id="simple-select"
        value={value}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
        renderValue={() => {
          return <p>{label}</p>
        }}
      >
        {list &&
          list?.map((name, i) => {
            if (name !== 'separator') {
              return (
                <MenuItem
                  key={`${name}-${i}-menuitem`}
                  value={name}
                  onClick={() => handleDelete(name)}
                >
                  {isCheckbox ? (
                    <CheckboxStyled checked={value?.includes(name)} />
                  ) : null}
                  <ListItemText primary={name} />
                </MenuItem>
              )
            } else {
              return <hr key={`${name}-${i}-hr`} />
            }
          })}
        {isCheckbox ? (
          <MenuItem
            key="borrar"
            onClick={() => handleDeleteAll(value)}
            disabled={value?.length === 0}
          >
            <TextSecondary>Borrar Selección</TextSecondary>
          </MenuItem>
        ) : null}
      </SelectStyled>
    </Wrapper>
  )
}
Select.propTypes = {
  label: PropTypes.string,
  list: PropTypes.array,
  value: PropTypes.any,
  isCheckbox: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleDeleteAll: PropTypes.func,
  isallfilter: PropTypes.bool,
  isfilterleft: PropTypes.bool,
}
Select.defaultProps = {
  list: [],
  isCheckbox: false,
  isallfilter: true,
  isfilterleft: true,
}
export default Select
