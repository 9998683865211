import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import round from '../../utils/round'

import normalizeOrder from '../orders/normalizeOrder'
import tableDateFormat from '../../adapters/days/tableDateFormat'

const normalizeLiquidations = ({
  payout,
  suborders,
  accountingStatement,
  id,
  sellerName,
  payoutDate,
  idLiq,
  idFact,
  fact,
  idPago,
  number,
  seller,
  commission,
  dispatchedSale,
  totalSale,
  excelReport,
  pdfReport,
  otherDiscount,
}) => ({
  otherDiscount,
  payoutDate,
  id,
  numberId: number,
  sellerName,
  sellerRut: seller?.rut,
  date: tableDateFormat(payoutDate),
  totalSale: round(totalSale),
  payout: round(payout),
  commission: round(commission),
  dispatchedSale: round(dispatchedSale),
  accountingStatement,
  idLiquidate: idLiq,
  idBill: idFact,
  bill: fact,
  idPaid: idPago,
  dateBill: tableDateFormat(payoutDate)
    .replaceAll('/', '-')
    .replaceAll(':', '-'),
  report: {
    excelReport,
    pdfReport,
  },
  to: {
    pathname: '/pedidos',
    search: `?payoutId=${id}`,
    state: pipe(map(normalizeOrder))(suborders),
  },
})

export default normalizeLiquidations
