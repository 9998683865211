import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'

const Label = styled('label')`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
  &:hover {
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme }) => theme.palette.text.primary};
  }
`
const StyleButtonFile = styled(Button)`
  color: ${({ ispress, theme }) =>
    ispress === 'true'
      ? theme.palette.secondary.main
      : theme.palette.text.primary};
  justify-content: flex-start;
  font-size: 17px;
  font-weight: ${({ ispress }) => (ispress === 'true' ? '900' : '400')};
  letter-spacing: 0.04em;
  text-align: left;
  border-radius: 0px;
  padding: 0px;
  margin: 0px;

  .MuiButtonBase-root {
    padding-left: 0;
    padding: 0px;
    margin: 0px;
  }
  ${Label}:hover & {
    background-color: transparent;
  }
`

const ButtonFile = ({
  title,
  onChange,
  name,
  register,
  ispress,
  ...props
}) => {
  const file = register(name, { required: false, value: null })
  return (
    <Label>
      <input hidden type="file" {...props} onChange={onChange} {...file} />
      <StyleButtonFile
        variant="text"
        component="span"
        ispress={String(ispress)}
        startIcon={<FileUploadOutlinedIcon fontSize="large" />}
      >
        {title}
      </StyleButtonFile>
    </Label>
  )
}
ButtonFile.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  register: PropTypes.any,
  ispress: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
ButtonFile.defaultProps = {
  ispress: false,
}
export default ButtonFile
