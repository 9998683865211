/* eslint-disable linebreak-style */

import { useParams, useNavigate } from 'react-router-dom'
import Onboarding1 from '../Onboarding/Onboarding1'
import Onboarding2 from '../Onboarding/Onboarding2'
import Onboarding3 from '../Onboarding/Onboarding3'
import Onboarding4 from '../Onboarding/Onboarding4'
import Onboarding5 from '../Onboarding/Onboarding5'

const DynamicOnboarding = () => {
  const params = useParams()
  const navigate = useNavigate()

  if (params.page === '1') {
    return <Onboarding1 navigate={navigate} />
  } else if (params.page === '2') {
    return <Onboarding2 navigate={navigate} />
  } else if (params.page === '3') {
    return <Onboarding3 navigate={navigate} />
  } else if (params.page === '4') {
    return <Onboarding4 navigate={navigate} />
  } else if (params.page === '5') {
    return <Onboarding5 navigate={navigate} />
  }
}

export default DynamicOnboarding
