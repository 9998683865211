import styled from 'styled-components'
import TextField from '@mui/material/TextField'

const InputBase = styled(props => <TextField {...props} />)`
  cursor: ${({ isloading }) => (isloading === 'true' ? 'wait' : 'text')};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 3;
  width: 84%;
  box-sizing: border-box;
  font-weight: 500;
  border-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.text.primary};
  .MuiOutlinedInput-input {
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    border-color: ${({ theme }) => theme.palette.primary.light};
  }

  .Mui-disabled {
    cursor: not-allowed;
    color: #d1d0d0;
    border-color: #d1d0d0;
    pointer-events: auto;
  }

  .MuiOutlinedInput-root.Mui-error {
    color: ${({ error, theme }) =>
      !error ? theme.palette.primary.light : theme.palette.error.dark};
  }

  @media (max-width: 1300px) {
    max-width: 100%;
  }

  @media (max-width: 700px) {
    width: 80%;
  }
  @media (max-width: 700px) {
    width: 75%;
  }
`

const InputSearch = props => {
  return <InputBase variant="outlined" type="search" {...props} />
}

export default InputSearch
