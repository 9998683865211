import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'

const initialState = { isOpen: true }

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    onChangeDrawer(state, action) {
      state.isOpen = action.payload
    },
    handleDrawerOpen(state) {
      state.isOpen = true
    },
    handleDrawerClose(state) {
      state.isOpen = false
    },
  },
})

export const { onChangeDrawer, handleDrawerOpen, handleDrawerClose } =
  drawerSlice.actions

const getDrawerState = state => state.ui.drawer

export const isOpenDrawer = createSelector(
  [getDrawerState],
  state => !!state.isOpen
)

const reducer = drawerSlice.reducer
export default reducer
