import styled from 'styled-components'
import logo from '../../../assets/logo.svg'

const Img = styled('img')`
  width: 69px;
  height: 69px;
`

const Logo = () => {
  return <Img src={logo} alt="logo" />
}

export default Logo
