import styled from 'styled-components'
import PropTypes from 'prop-types'
import { statusColorMapper } from '../../../../../../domain/mappers/statusColorMapper'
import Tag from '../../../Tag/Tag'
import CellTooltip from '../BaseCell'

const CellStatus = styled(CellTooltip)`
  padding: 0;
  padding-right: 1rem;
  text-align: center;
`

const TagTable = styled(Tag)`
  height: 20px;
  width: 100%;
  font-size: 13px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media (max-width: 1100px) {
    margin-top: auto;
  }
`

const CellAccountingStatement = ({ text, ...props }) => {
  const textProduct = new Map([
    ['ACTIVE', 'Aprobado'],
    ['DRAFT', 'Pendiente aprobación'],
    ['ARCHIVED', 'Deshabilitado'],
  ]).get(text)

  return (
    <CellStatus {...props}>
      <TagTable
        status={statusColorMapper.get(text)}
        isproduct={String(text === 'DRAFT')}
      >
        {textProduct ? textProduct : text}
      </TagTable>
    </CellStatus>
  )
}
CellAccountingStatement.propTypes = {
  text: PropTypes.string,
}
export default CellAccountingStatement
