import { bool, string, func } from 'prop-types'
import styled, { css } from 'styled-components'
import CheckboxMUI from '@mui/material/Checkbox'

const StyleCheckbox = styled(CheckboxMUI)`
  width: 8px;
  height: 8px;
  border-radius: 1px;
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          color: #d1d0d0;
        `
      : null};
`

const Checkbox = ({ ...props }) => {
  return (
    <StyleCheckbox
      {...props}
      sx={{
        color: 'gray',
      }}
    />
  )
}

Checkbox.propTypes = {
  disabled: bool,
  label: string,
  variant: string,
  onChange: func,
  checked: bool,
  indeterminate: bool,
}

export default Checkbox
