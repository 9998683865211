/* eslint-disable linebreak-style */

import PropTypes from 'prop-types'
import Logo from '../../atoms/Logo'
import Carousel from '../../molecules/Carousel/Carousel'
import {
  Center,
  Container,
  DescriptionContainer,
  DescriptionText,
  LeftContainer,
  LogoContainer,
  OnboardingImage,
  RightContainer,
  StyledButton,
  TitleText,
} from './onboarding.styles'
const OnboardingImg = require('../../../assets/onboarding/onboarding4.png')

const Onboarding4 = ({ navigate }) => {
  const handleNavigate = () => {
    navigate('/onboarding/5')
  }
  return (
    <Center>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Container>
        <LeftContainer>
          <TitleText>Pedidos y liquidaciones</TitleText>
          <DescriptionContainer>
            <DescriptionText>
              Tus pedidos y liquidaciones se reflejan en ventanas separadas
              para que puedas ordenar tus ventas y cuentas de la mejor
              manera posible.{' '}
            </DescriptionText>
          </DescriptionContainer>
        </LeftContainer>
        <RightContainer>
          <OnboardingImage
            src={OnboardingImg}
            alt="onboarding4"
          />
        </RightContainer>
      </Container>
      <StyledButton variant="contained" onClick={handleNavigate}>
        Entendido
      </StyledButton>
      <Carousel />
    </Center>
  )
}

Onboarding4.propTypes = {
  navigate: PropTypes.func,
}

export default Onboarding4
