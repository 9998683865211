import isEmpty from 'lodash/isEmpty'
import { AES, enc } from 'crypto-js'
import Base64 from 'crypto-js/enc-base64'

/**
 * Local storage handler for SSR (Server Side Rendering).
 * Uses window.localStorage if available, otherwise provides mock functions.
 */
const localStorage =
  typeof window === 'undefined'
    ? {
        getItem: () => undefined,
        setItem: () => undefined,
        removeItem: () => undefined,
        clear: () => undefined,
      }
    : window.localStorage

/**
 * Secret key used for encryption and decryption.
 * @constant {string}
 */
const SECRET_KEY =
  'seller-center-E2LG0:Wy6@,hz9lt1,T5Wr!)~,5{s"|eSNzt6+7IWwkY`PZ!I'

/**
 * Keys used for storing data in local storage.
 * @constant {Object}
 * @property {string} MODETHEME - Key for storing theme mode.
 * @property {string} USER - Key for storing user data.
 * @property {string} TOKEN - Key for storing token.
 * @property {string} DOWNLOAD_PRODUCT_ACCEPT - Key for storing product template acceptance.
 * @property {string} PRODUCTS - Key for storing products.
 * @property {string} SELLER - Key for storing seller data.
 * @property {string} CATEGORY - Key for storing category data.
 * @property {string} BRAND - Key for storing brand data.
 * @property {string} LOCATION - Key for storing location data.
 */
const keyList = {
  MODETHEME: '@theme-light-or-dark',
  USER: '@user-seller',
  TOKEN: '@token',
  DOWNLOAD_PRODUCT_ACCEPT: '@download-product-accept',
  PRODUCTS: '@products-seller',
  SELLER: '@seller-detail',
  CATEGORY: '@category',
  BRAND: '@brand',
  LOCATION: '@location',
}
/**
 * Encrypts data using AES encryption.
 * @param {any} data - The data to be encrypted.
 * @returns {string} - The encrypted data.
 */
const encryptData = data =>
  AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()

/**
 * Decrypts data using AES decryption.
 * @param {string} data - The encrypted data to be decrypted.
 * @returns {any} - The decrypted data, or undefined if decryption fails.
 */
const decryptData = data => {
  try {
    return JSON.parse(AES.decrypt(data, SECRET_KEY).toString(enc.Utf8))
  } catch (error) {
    return undefined
  }
}

/**
 * Encodes data to base64 using crypto-js.
 * @param {any} data - The data to be encoded.
 * @returns {string} - The base64 encoded data.
 */
const encodeBase64 = data => {
  return Base64.stringify(enc.Utf8.parse(JSON.stringify(data)))
}

/**
 * Decodes base64 data using crypto-js.
 * @param {string} data - The base64 encoded data to be decoded.
 * @returns {any} - The decoded data.
 */
const decodeBase64 = data => {
  try {
    return JSON.parse(enc.Utf8.stringify(Base64.parse(data)))
  } catch (error) {
    return undefined
  }
}

/**
 * Retrieves an item from local storage and parses it.
 * @param {string} key - The key of the item to retrieve.
 * @returns {any} - The parsed item, or the original item if parsing fails.
 */
const getItem = key => {
  const item = localStorage.getItem(key)
  if (item === 'undefined') {
    return undefined
  }
  if (item === 'null') {
    return null
  }
  try {
    if (key === keyList.TOKEN) {
      return decodeBase64(item)
    }
    return decryptData(item)
  } catch (cause) {
    return item
  }
}

/**
 * Stringifies and stores an item in local storage.
 * @param {string} key - The key under which to store the item.
 * @param {any} value - The item to store.
 */
const setItem = (key, value) => {
  const item =
    key === keyList.TOKEN ? encodeBase64(value) : encryptData(value)
  localStorage.setItem(key, item)
}

/**
 * Removes an item from local storage.
 * @param {string} key - The key of the item to remove.
 */
const removeItem = key => localStorage.removeItem(key)

const PersistentStore = () => ({
  getDownloadProductTemplateAccept: () =>
    getItem(keyList.DOWNLOAD_PRODUCT_ACCEPT),
  setDownloadProductTemplateAccept: accepted =>
    setItem(keyList.DOWNLOAD_PRODUCT_ACCEPT, accepted),
  getTheme: () => getItem(keyList.MODETHEME),
  setTheme: theme => setItem(keyList.MODETHEME, theme),
  getProducts: () => getItem(keyList.PRODUCTS),
  setProducts: products => setItem(keyList.PRODUCTS, products),
  /**
   * Retrieves the user data from local storage. Decodes AES256 user.
   * @returns {Object} - The stored user data.
   */
  getUser: () => getItem(keyList.USER),

  /**
   * Stores the user data in local storage. Encodes user to AES256.
   * @param {Object} user - The user data to store.
   */
  setUser: user => setItem(keyList.USER, user),

  /**
   * Removes the user data from local storage.
   */
  deleteUser: () => removeItem(keyList.USER),

  /**
   * Retrieves the token from local storage. Decodes base64 token.
   * @returns {string} - The stored token.
   */
  getToken: () => getItem(keyList.TOKEN),

  /**
   * Stores the token in local storage. Encodes token to base64.
   * @param {string} token - The token to store.
   */
  setToken: token => setItem(keyList.TOKEN, token),

  /**
   * Removes the token from local storage.
   */
  deleteToken: () => removeItem(keyList.TOKEN),
  getSeller: () => getItem(keyList.SELLER),
  setSeller: seller => setItem(keyList.SELLER, seller),
  isSeller: () => isEmpty(PersistentStore().getSeller()),
  deleteSeller: () => removeItem(keyList.SELLER),
  getCategory: () => getItem(keyList.CATEGORY),
  setCategory: category => setItem(keyList.CATEGORY, category),
  isCategory: () => isEmpty(PersistentStore().getCategory()),
  deleteCategory: () => removeItem(keyList.CATEGORY),
  getBrands: () => getItem(keyList.BRAND),
  setBrands: brands => setItem(keyList.BRAND, brands),
  isBrands: () => !isEmpty(PersistentStore().getBrands()),
  deleteBrands: () => removeItem(keyList.BRAND),
  getLocations: () => getItem(keyList.LOCATION),
  setLocations: locations => setItem(keyList.LOCATION, locations),
  isLocations: () => isEmpty(PersistentStore().getLocations()),
  deleteLocations: () => removeItem(keyList.LOCATION),
  deleteAuth: () => {
    localStorage.clear()
  },
})

export default PersistentStore()
