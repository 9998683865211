import PropTypes from 'prop-types'
import styled from 'styled-components'

import MuiSelect from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import MenuItem from '@mui/material/MenuItem'

import Bold from '../../atoms/Typography/Bold'

const FooterCenter = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  margin: 1rem;
`

const Divider = styled('div')`
  margin-right: 1%;
`

const SelectPage = styled(MuiSelect)`
  max-width: 79px;
  border-radius: 5px;
  height: 36px;
  width: 80px;
  border: 1px solid #b7b7b7;
  .MuiSelect-select.MuiSelect-select {
    padding-right: 0px;
    padding-left: 10px;
  }
`

const TableFooter = ({
  pageCurrentIndex,
  setPage,
  perPage,
  handleSelectPerPage,
  totalPage,
  listPerPage,
  hasNextPage = false,
  hasPreviousPage = false,
  isGraphQL = false,
}) => {
  return (
    <FooterCenter>
      <Bold>Filas por pagina</Bold>
      <Divider />
      <SelectPage
        input={<InputBase />}
        value={perPage}
        onChange={handleSelectPerPage}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        }}
      >
        {listPerPage.map(perPage => (
          <MenuItem
            key={perPage}
            value={perPage === 'Todos' ? -1 : perPage}
          >
            {perPage}
          </MenuItem>
        ))}
      </SelectPage>
      <Divider />
      {isGraphQL ? null : (
        <Bold>
          {pageCurrentIndex + 1} de{' '}
          {perPage === 'Todos' || perPage < 0 ? 1 : totalPage}
        </Bold>
      )}
      <Divider />
      <IconButton
        title="Primera página"
        size="small"
        onClick={() => {
          setPage(0)
        }}
        disabled={!hasPreviousPage}
      >
        <FirstPageIcon alt="arrow-first" />
      </IconButton>
      <IconButton
        title="Página anterior"
        size="small"
        onClick={() => {
          setPage(p => (p === 0 ? 0 : p - 1))
        }}
        disabled={!hasPreviousPage}
      >
        <KeyboardArrowLeft alt="arrow-left" />
      </IconButton>
      <IconButton
        title="Página siguiente"
        size="small"
        onClick={() => {
          setPage(p => p + 1)
        }}
        disabled={!hasNextPage}
      >
        <KeyboardArrowRight alt="arrow-right" />
      </IconButton>
      {isGraphQL ? null : (
        <IconButton
          title="Última página"
          onClick={() => {
            setPage(totalPage - 1)
          }}
          disabled={!hasNextPage}
          size="small"
        >
          <LastPageIcon alt="arrow-end" />
        </IconButton>
      )}
    </FooterCenter>
  )
}
TableFooter.propTypes = {
  pageCurrentIndex: PropTypes.number,
  setPage: PropTypes.any,
  perPage: PropTypes.number,
  handleSelectPerPage: PropTypes.any,
  totalPage: PropTypes.number,
  isLoading: PropTypes.bool,
  withPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  listPerPage: PropTypes.array,
  isGraphQL: PropTypes.bool,
}
TableFooter.defaultProps = {
  pageCurrentIndex: 0,
  totalPage: 0,
  isLoading: false,
  perPage: 20,
  hasPreviousPage: false,
  hasNextPage: false,
  isGraphQL: false,
  listPerPage: [5, 10, 20],
}
export default TableFooter
