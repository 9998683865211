import {
  createEntityAdapter,
  createSlice,
  current,
} from '@reduxjs/toolkit'
import { isEmpty, isEqual } from 'lodash'
import fetchProductCreate from '../productCreate/fetchProductCreate'
import fetchProductUpdate from '../productUpdate/fetchProductUpdate'
import fetchProducts from './fetchProduct'
import fetchUpdateProductStatus from './fetchUpdateProductStatus'

export const productAdapter = createEntityAdapter({
  selectId: ({ id }) => String(id),
})

export const productSlice = createSlice({
  name: 'products',
  initialState: productAdapter.getInitialState({
    status: 'loading',
    entities: {},
    statusList: ['Aprobado', 'Deshabilitado', 'Pendiente aprobación'],
    query: '',
    sortKey: 'UPDATED_AT',
    reverse: true,
    pagination: {
      endCursor: null,
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: null,
      skip: 0,
    },
  }),
  reducers: {
    addSortKey(state, { payload }) {
      state.sortKey = payload || 'UPDATED_AT'
    },
    reverse(state, { payload }) {
      state.reverse = payload || true
    },
    addQuery(state, { payload }) {
      state.query = payload || ''
    },
    addProductStatusList(state, { payload }) {
      state.statusList = payload
    },
    addOneProduct(state, { payload }) {
      if (payload.includes(undefined)) {
        return
      }
      return productAdapter.addMany(state, payload)
    },
  },
  extraReducers: {
    [fetchProducts.pending]: state => {
      state.status = 'loading'
    },
    [fetchProducts.rejected]: (state, { meta }) => {
      state.status = meta.aborted ? 'loading' : 'error'
    },
    [fetchProducts.fulfilled]: (state, { meta, payload }) => {
      const { pagination, products } = payload
      state.status = 'finished'
      state.pagination = pagination
      const lastProduct = isEmpty(current(state).entities)
        ? null
        : normalize(current(state), payload)
      state.productList =
        lastProduct?.length > 0 &&
        isEmpty(meta.arg?.brands) &&
        meta.arg?.skip < 0 &&
        isEmpty(meta.arg?.sellerList) &&
        isEmpty(meta.arg?.query) &&
        isEmpty(meta.arg?.category) &&
        isEmpty(meta.arg?.status) &&
        isEqual(meta.arg.sortBy[0], {
          id: 'updatedAt',
          desc: true,
        })
          ? [...lastProduct, ...products]
          : payload?.products
      if (payload?.products) {
        lastProduct &&
        meta.arg?.skip < 0 &&
        isEmpty(meta.arg?.brands) &&
        isEmpty(meta.arg?.sellerList) &&
        isEmpty(meta.arg?.query) &&
        isEmpty(meta.arg?.category) &&
        isEmpty(meta.arg?.status) &&
        isEqual(meta.arg.sortBy[0], {
          id: 'updatedAt',
          desc: true,
        })
          ? productAdapter.setAll(state, [...lastProduct, ...products])
          : productAdapter.setAll(state, products)
      }
    },
    [fetchUpdateProductStatus.pending]: state => {
      state.status = 'loading'
    },
    [fetchUpdateProductStatus.rejected]: state => {
      state.status = 'error'
    },
    [fetchUpdateProductStatus.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      productAdapter.updateOne(state, {
        id: payload.id,
        changes: { ...payload, availableForSale: true },
      })
    },
    [fetchProductUpdate.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      productAdapter.updateOne(state, {
        id: payload.id,
        changes: { ...payload, availableForSale: true },
      })
      state.productList = current(state).productList?.map(product => {
        if (product.id === payload.id) {
          return {
            ...payload,
            justUpdated: true,
            to: {
              pathname: `/productos/${product.id.split('Product/')[1]}`,
              state: { ...product },
            },
          }
        }
        return product
      })
    },
    [fetchProductCreate.fulfilled]: (state, { meta, payload }) => {
      state.status = 'finished'
      productAdapter.upsertOne(state, {
        id: payload.id,
        changes: { ...payload, availableForSale: true },
      })
      state.productList = [
        {
          ...payload,
          featuredImage: {
            url: meta.arg?.media[0]?.src,
            alt: payload?.media[0]?.alt,
          },
          to: {
            pathname: `/productos/${payload?.id?.split('Product/')[1]}`,
            state: {
              ...payload,
              featuredImage: {
                url: meta.arg?.media[0]?.src,
                alt: payload?.media[0]?.alt,
              },
            },
          },
        },
        ...current(state).productList,
      ]
    },
  },
})

const normalize = (currentState, payload) => {
  return currentState.productList
    .filter(
      product =>
        !payload.products.some(prod => product.id === prod.id) ||
        product?.justUpdated
    )
    .filter(product => product.id !== '')
    .map(product => {
      return {
        count: product.variants.length,
        seller: product.tags
          .find(tag => tag.includes('seller_'))
          .split('seller_')[1],
        to: {
          pathname: `/productos/${product.id.split('Product/')[1]}`,
          state: { ...product },
        },
        ...product,
      }
    })
}

export const { addQuery, addProductStatusList } = productSlice.actions
const reducer = productSlice.reducer
export default reducer
