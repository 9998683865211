import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import { useTransition, animated, useSpring } from '@react-spring/web'
import Chip from '../../atoms/Chip'
import sessionPersistentStore from '../../../../data/sessionPersistentStore'

const StyledChip = styled(animated(Chip))`
  display: inline-flex;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  background-color: #eeeeee;
  border-color: #f5f5f5;
`
const Content = styled('div')`
  padding: 1rem;
  padding-top: 21px;
`
const DeleteAll = styled(animated(Button))`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
`
const TextTitle = styled('div')`
  font-weight: bold;
  margin-bottom: 15px;
`
const ChipCategories = ({ category, setCategory }) => {
  const [chips, setChips] = useState([])

  const deleteAll = () => {
    removeAllCategory()
  }
  const addChips = (label, deleteAction) => {
    setChips(current => [
      ...current,
      {
        key: current.length + 1,
        label: `${label}`,
        onDelete: deleteAction,
        value: label,
      },
    ])
  }
  const onDelete = chipToDelete => () => {
    setChips(chips => chips.filter(chip => chip.key !== chipToDelete.key))
    chipToDelete.onDelete()
  }

  const removeCategory = ca => () => {
    setCategory(cat => cat.filter(c => ca !== c))
  }
  const removeAllCategory = () => {
    setCategory([])
    sessionPersistentStore.setCategorySelect([])
  }
  useEffect(() => {
    setChips([])
    category?.map(s => addChips(s, removeCategory(s)))
    // eslint-disable-next-line
  }, [category])

  const transitions = useTransition(chips, {
    key: ({ label }) => label,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500, tension: 220, friction: 120 },
    exitBeforeEnter: true,
    native: true,
  })

  const fade = useSpring({
    to: { opacity: isEmpty(chips) ? 0 : 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
    delay: 400,
    native: true,
  })

  return (
    <Content>
      <TextTitle style={fade}>Categorías seleccionadas</TextTitle>
      {transitions((springs, item) => (
        <StyledChip
          key={item.key}
          label={item.label}
          onDelete={onDelete(item)}
          style={springs}
        />
      ))}
      <DeleteAll onClick={deleteAll} style={fade}>
        Borrar Categorías
      </DeleteAll>
    </Content>
  )
}
ChipCategories.propTypes = {
  category: PropTypes.arrayOf(PropTypes.string),
  setCategory: PropTypes.func,
}
export default ChipCategories
