import PropTypes from 'prop-types'
import Cell from '../Cell/Cell'

const CellTooltip = ({ children, onClick }) => (
  <Cell onClick={onClick}>{children}</Cell>
)

CellTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
  onClick: PropTypes.func,
}
export default CellTooltip
