import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import { searchAttribuesByCategory } from '../../../../domain/features/category/getCategory'
import { GeneralitiesItems } from './GeneralitiesList'

import OthersForms from '../../molecules/OthersForms/OthersForms'
import CardProduct from '../../atoms/CardProduct'
import TitleProduct from '../../atoms/Typography/TitleProduct'
import FieldFile from '../FieldFile'
import Text from '../../atoms/Typography/Text'

const ContainerFiles = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1%;
  margin-top: 2%;
`

const ProductExtraOptionsForm = ({
  control,
  errors,
  register,
  allFiles,
  setValue,
  clearErrors,
  setAllFiles,
}) => {
  const handleDeleteFile = e => {
    const { name } = e.target
    const newFiles = { ...allFiles }
    delete newFiles[name]
    setAllFiles(newFiles)
    setValue(name, null, { shouldDirty: true })
    clearErrors(name)
  }
  const category = useWatch({ name: 'category', control })
  const fieldsProduct = useSelector(searchAttribuesByCategory(category))

  const fieldListMemo = useMemo(
    () =>
      fieldsProduct
        ? GeneralitiesItems.filter(({ name }) =>
            fieldsProduct.some(
              field =>
                String(field).toLowerCase() === String(name).toLowerCase()
            )
          )
        : GeneralitiesItems,
    [fieldsProduct]
  )

  const isViewFile = name =>
    fieldsProduct
      ? fieldsProduct?.some(field => String(field) === String(name))
      : []

  return (
    <CardProduct>
      <TitleProduct>Otros atributos</TitleProduct>
      {isEmpty(category) ? (
        <Text>
          Selecciona la categoría del producto en “Información general”
          para agregar más especificaciones del mismo.
        </Text>
      ) : (
        <Text>
          Es importante que agregues las especificaciones necesarias
          dependiendo de la categoría del producto.
        </Text>
      )}
      {isEmpty(category) ? null : (
        <>
          <ContainerFiles>
            {!isViewFile('Manual de uso') ? null : (
              <FieldFile
                name="manual"
                label="Manual de uso"
                tooltipTitle="Adjuntar archivo (PDF)"
                allFiles={allFiles}
                handleDeleteFile={handleDeleteFile}
                register={register}
                control={control}
                errors={errors}
              />
            )}
            {!isViewFile('Ficha técnica') ? null : (
              <FieldFile
                name="dataSheet"
                label="Ficha técnica"
                tooltipTitle="Adjuntar archivo (PDF)"
                allFiles={allFiles}
                handleDeleteFile={handleDeleteFile}
                register={register}
                control={control}
                errors={errors}
              />
            )}
            {!isViewFile('Certificado QR') ? null : (
              <FieldFile
                label="Certificado QR"
                name="certificate"
                tooltipTitle="Adjuntar archivo (PNG o JPG)"
                allFiles={allFiles}
                handleDeleteFile={handleDeleteFile}
                register={register}
                control={control}
                errors={errors}
              />
            )}
            {!isViewFile('Eficiencia energética') ? null : (
              <FieldFile
                label="Eficiencia energética"
                name="efficiency"
                tooltipTitle="Adjuntar archivo (PNG o JPG)"
                allFiles={allFiles}
                handleDeleteFile={handleDeleteFile}
                register={register}
                control={control}
                errors={errors}
              />
            )}
            {!isViewFile('Inpage') ? null : (
              <FieldFile
                label="Inpage"
                name="inpage"
                tooltipTitle="Adjuntar archivo (PDF)"
                allFiles={allFiles}
                handleDeleteFile={handleDeleteFile}
                register={register}
                control={control}
                errors={errors}
              />
            )}
            {!isViewFile('Foto encastre') ? null : (
              <FieldFile
                label="Foto encastre"
                name="descriptionImage"
                tooltipTitle="Adjuntar archivo (PNG o JPG)"
                allFiles={allFiles}
                handleDeleteFile={handleDeleteFile}
                register={register}
                control={control}
                errors={errors}
              />
            )}
          </ContainerFiles>
          <OthersForms
            items={fieldListMemo}
            errors={errors}
            control={control}
          />
        </>
      )}
    </CardProduct>
  )
}

ProductExtraOptionsForm.propTypes = {
  control: PropTypes.any,
  register: PropTypes.any,
  errors: PropTypes.object,
  allFiles: PropTypes.object,
  setAllFiles: PropTypes.func,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
}

export default ProductExtraOptionsForm
