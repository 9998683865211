import persistentStore from '../persistentStore'

const userServicesSettlement = baseUrl => ({
  getSlugSellerBrands: async ({ slug_seller, token }) => {
    const response = await fetch(`${baseUrl}/brand/${slug_seller}`, {
      headers: {
        authorization: token || persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
    })
    const data = response.json()
    return data
  },
})

export default userServicesSettlement(process.env.REACT_APP_API_SETTLEMENT_PATH)
