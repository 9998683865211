import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import ButtonDownload from '../ButtonDownload/ButtonDownload'

const BoxStyled = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1100px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (max-width: 700px) {
    margin-left: 1%;
    margin-right: 1%;
  }
`
const DownloadContent = styled(Box)`
  display: flex;
`

const Content = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  width: 99%;
`

const FilterContainer = ({ children, onDownloadExcel }) => {
  return (
    <BoxStyled pt="15px" ml="20px" mb="15px" role="menubar">
      <Content>{children}</Content>
      <DownloadContent>
        {onDownloadExcel && <ButtonDownload onClick={onDownloadExcel} />}
      </DownloadContent>
    </BoxStyled>
  )
}
FilterContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
  onDownloadExcel: PropTypes.func,
}
FilterContainer.defaultProps = {
  onDownloadExcel: undefined,
}
export default FilterContainer
