import Button from '@mui/material/Button'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Text from '../../atoms/Typography/Text'
import Spinner from '../../atoms/Spinner'

const Icon = styled('img')`
  margin-left: 30px;
  margin-right: 14px;
`

const StyledButton = styled(Button)`
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  height: 68px;
  background: #ffffff;
  color: ${({ theme }) => theme.palette.text.primary};
  justify-content: flex-start;
  flex: 1;
`

const StyledText = styled(Text)`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
`

const ReverseIcon = styled(Icon)`
  transform: rotate(180deg);
`

const Container = styled('div')`
  display: flex;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #ffffff;
  align-items: center;
  margin-top: 2%;
`

const Column = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`

const StyledSpinner = styled(Spinner)`
  color: #2469ce;
  margin-right: 100px;
`

const ChildrenContainer = styled('div')`
  display: flex;
  height: 68px;
  background: #ffffff;
  width: 100%;
`

const ExportImportTemplate = ({
  icon,
  text,
  reverseIcon,
  handleExport,
  children,
  exportStatus,
  ...props
}) => {
  return (
    <Container>
      {children ? (
        <ChildrenContainer>{children}</ChildrenContainer>
      ) : (
        <Column>
          <StyledButton
            {...props}
            variant="text"
            startIcon={
              reverseIcon ? (
                <ReverseIcon src={icon} />
              ) : (
                <Icon src={icon} alt="download" />
              )
            }
            onClick={handleExport}
          >
            <StyledText>{text}</StyledText>
          </StyledButton>
          {exportStatus === 'loading' ? (
            <StyledSpinner size="25px" />
          ) : null}
        </Column>
      )}
    </Container>
  )
}
ExportImportTemplate.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  reverseIcon: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.any,
  ]),
  handleExport: PropTypes.func,
  exportStatus: PropTypes.string,
}

export default ExportImportTemplate
