import { createSelector } from '@reduxjs/toolkit'
import { productUpdateStatusAdpater } from './productUpdateStatusSlice'

const getStateProducts = state => state.products.detailStatus

export const getProductDetailStatus = createSelector(
  [getStateProducts],
  detailStatus => detailStatus
)

const productDetailSelector =
  productUpdateStatusAdpater.getSelectors(getStateProducts)
export const resultProductDetailStatus = productDetailSelector.selectAll
