import Typography from '@mui/material/Typography'
import styled from 'styled-components'

const Title = styled(props => <Typography variant="h1" {...props} />)`
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  font-weight: 500;
  @media (max-width: 700px) {
    margin-bottom: 0.5rem;
  }
`
Title.displayName = 'TitleTable'
export default Title
