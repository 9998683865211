import capitalizeName from '../../utils/capitalizeName'

const normalizeOrderBilling = ({
  billing: {
    billing_address1,
    billing_address2,
    billing_county,
    billing_region,
  },
  company_name,
  company_giro,
  company_rut,
  customer: { customer_email },
}) => ({
  address: capitalizeName(`${billing_address1} ${billing_address2}`),
  rut: company_rut,
  giro: capitalizeName(company_giro),
  name: capitalizeName(company_name),
  destination_county: capitalizeName(billing_county),
  destination_region: capitalizeName(billing_region),
  mail: customer_email,
})

export default normalizeOrderBilling
