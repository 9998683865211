import styled from 'styled-components'
import { animated } from '@react-spring/web'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  cursor: wait;
`

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
`

export const ImageStyled = styled(animated.img)`
  display: 'block';
  width: 100%;
  height: 100%;
`

export const EmojiBox = styled.div`
  position: absolute;
  top: -10px;
  right: -5px;
  font-size: 40px;
`

export const MessageBox = styled(animated('p'))`
  color: #313234;
  font-size: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 300px;
  height: 50px;
`
