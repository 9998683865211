import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'
import normalizeProducts from './normalizeProducts'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'

const getDirection = (prevSkip, skip) =>
  prevSkip <= skip ? 'next' : 'back'
export const getSortBy = new Map([
  ['title', 'TITLE'],
  ['updatedAt', 'UPDATED_AT'],
  ['vendor', 'VENDOR'],
  ['productType', 'PRODUCT_TYPE'],
  ['createdAt', 'CREATED_AT'],
])

const fetchProducts = createAsyncThunk(
  'products/list',
  async (
    {
      query = '',
      brands = undefined,
      sortBy = undefined,
      status = undefined,
      category = undefined,
      limit = 20,
      skip = 0,
    },
    { rejectWithValue, getState, signal }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })

    try {
      const state = getState().products.list
      const brandList = getState().seller.detail.brandOnlyNameList
      const tagSeller = getState().seller.detail.tag
      const prevSkip = getState().products.list.pagination.skip
      const direction = skip === 0 ? 'next' : getDirection(prevSkip, skip)
      const brand = isEmpty(brands) ? brandList : brands
      const brandsAll = getState().seller.detail.brandsAll

      const data = await productAdminServices.getProducts({
        brands: brand,
        brandsAll,
        title: query || state.query,
        status: status || state.statusList,
        cursor:
          skip === 0
            ? null
            : direction !== 'next'
              ? state.pagination.startCursor
              : state.pagination.endCursor,
        direction,
        limit,
        sortKey: sortBy ? getSortBy.get(sortBy[0].id) : 'UPDATED_AT',
        reverse: sortBy ? sortBy[0].desc : true,
        category,
        tagSeller,
        cancelToken: source.token,
      })

      const { products, pageInfo } = data

      if (
        !products &&
        (query || brands.length > 0 || status || category)
      ) {
        return rejectWithValue('Sin contenido')
      } else if (!products) {
        return []
      }

      const normalize = normalizeProducts(products)?.map(product => ({
        ...product,

        to: {
          pathname: `/productos/${product?.id?.split('Product/')[1]}`,
          state: {
            ...product,

            success: false,
          },
        },
      }))
      if (Array(...normalize).length === 0) {
        return rejectWithValue('Sin contenido')
      }
      return { products: normalize, pagination: { ...pageInfo, skip } }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    condition: ({ sortBy }) => {
      const sortKey = sortBy[0]?.id

      if (!getSortBy.has(sortKey)) {
        return false
      }
    },
  }
)

export default fetchProducts
