import { useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import max from 'lodash/max'
import { useDebounce } from 'use-debounce'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import tableDateFormat from '../../../../domain/adapters/days/tableDateFormat'
import {
  getProducts,
  resultsProducts,
} from '../../../../domain/features/productSearchApi/getProducts'
import fetchProducts, {
  getSortBy,
} from '../../../../domain/features/productSearchApi/fetchProduct'
import { getSellerById } from '../../../../domain/features/seller/getSeller'

import useMediaQuery from '@mui/material/useMediaQuery'
import Paper from '@mui/material/Paper'
import TableTemplate from '../../template/TableTemplate'
import ActionButton from '../../molecules/ActionButton/ActionButton'
import Title from '../../atoms/BaseTable/Typography/Title'
import AddProductButton from '../../molecules/AddProductButton/AddProductButton'
import MenuDropdown from '../../molecules/MenuDropdown'
import SearchProduct from '../../organisms/SearchProduct/SearchProduct'
import HeaderContainer from '../../atoms/HeaderContainer'
import CellNumber from '../../atoms/BaseTable/BaseCell/CellNumber/CellNumber'
import TableFooter from '../../organisms/TableFooter'
import FilterContainer from '../../molecules/FilterContainer/FilterContainer'
import ProductsNotFound from '../ProductsNotFound/ProductsNotFound'
import ButtonDownload from '../../molecules/ButtonDownload/ButtonDownload'
import fetchProductListDownload from '../../../../domain/features/productListDownload/fetchProductListDownload'
import { getProductExportAllById } from '../../../../domain/features/productExportAllById/getProductExportAllById'
import Alert from '../../atoms/Alert/Alert'
import { getProductListDownload } from '../../../../domain/features/productListDownload/getProductListDownload'
import SelectCategory from '../../organisms/SelectCategory/SelectCategory'
import sessionPersistentStore from '../../../../data/sessionPersistentStore'
import SelectBrand from '../../organisms/SelectBrand/SelectBrand'
import SelectState from '../../organisms/SelectState/SelectState'
import ChipManager from '../../organisms/ChipManager'
import ButtonPreviewProduct from '../../molecules/ButtonPreviewProduct'
import { useAuth } from '../../../hooks/useAuth'

const ContainerFlexRow = styled('div')`
  display: flex;
`

const stateDownload = () =>
  new Map([
    [
      'finished',
      'Descargando productos, esto podria demorar varios minutos. ',
    ],
    [
      'loading',
      'Descargando productos, esto podria demorar varios minutos. ',
    ],
  ])

const stateExport = () =>
  new Map([
    [
      'error',
      'Descargando productos, esto podria demorar varios minutos. ',
    ],
    [
      'loading',
      'Descargando productos, esto podria demorar varios minutos. ',
    ],
    ['finished', 'Descarga exitosa. '],
  ])

const Products = () => {
  const dispatch = useDispatch()

  const columns = useMemo(
    () => [
      {
        Header: 'Imagen',
        accessor: 'featuredImage.url',
        Cell: cell => (
          <img
            src={cell?.row?.original?.featuredImage?.url}
            width="31px"
            height="31px"
            alt={cell.row.original.featuredImage.altText || 'sin-imagen'}
          />
        ),
      },
      {
        Header: 'Nombre producto',
        accessor: 'title',
      },
      {
        Header: 'Categoría',
        accessor: 'productType',
      },
      {
        Header: 'Marca',
        accessor: 'vendor',
      },
      {
        Header: 'Precio normal',
        accessor: 'variants[0].compareAtPrice',
        Cell: cell => {
          const values = cell.row.original.variants.map(
            variant => variant.compareAtPrice
          )
          const maxValue = max(values)
          return <CellNumber number={maxValue} />
        },
      },
      {
        Header: 'Precio oferta',
        accessor: 'variants[0].price',
        Cell: cell => {
          const values = cell.row.original.variants.map(
            variant => variant.price
          )
          const maxValue = max(values)
          return <CellNumber number={maxValue} />
        },
      },
      {
        Header: 'Cantidad disponible',
        accessor: 'totalInventory',
      },
      {
        Header: 'Cantidad de variantes',
        accessor: 'count',
      },
      {
        Header: 'Última actualización',
        accessor: 'updatedAt',
        Cell: ({ cell }) => {
          return tableDateFormat(cell?.value)
        },
      },
      {
        Header: 'Fecha creación',
        accessor: 'createdAt',
        Cell: ({ cell }) => {
          return tableDateFormat(cell?.value)
        },
      },
      {
        Header: 'Fecha publicación',
        accessor: 'publishedAt',
        Cell: ({ cell }) => {
          return tableDateFormat(cell?.row?.original?.publishedAt)
        },
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ cell }) => {
          return new Map([
            ['ACTIVE', 'Aprobado'],
            ['DRAFT', 'Pendiente aprobación'],
            ['ARCHIVED', 'Deshabilitado'],
          ]).get(cell.value)
        },
      },
      {
        Header: 'Vista previa',
        accessor: 'link',
        Cell: cell => (
          <ButtonPreviewProduct
            fontSize="medium"
            to={cell?.row?.original?.onlineStorePreviewUrl}
          />
        ),
      },
      {
        Header: 'Acción',
        accessor: 'actions',
        Cell: cell => (
          <ActionButton
            productId={String(cell?.row?.original?.id)}
            status={String(cell?.row?.original?.status)}
          />
        ),
      },
    ],
    []
  )

  const [selectCurrentItem, setSelectItem] = useState(
    sessionPersistentStore.isEmptyStateFilter()
      ? []
      : sessionPersistentStore.getStateFilter()
  )
  const setStatus = useCallback(
    status => {
      setSelectItem(status)
      sessionPersistentStore.setStateFilter(status)
    },
    [setSelectItem]
  )

  const { status: statusSeller } = useSelector(getSellerById)

  const [openProduct, setOpenProduct] = useState(false)

  const { status: statusExportAll } = useSelector(getProductExportAllById)
  const { status: statusDownload } = useSelector(getProductListDownload)
  const handleCloseProduct = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenProduct(false)
  }

  useEffect(
    () =>
      statusExportAll === 'loading' || statusDownload === 'loading'
        ? setOpenProduct(true)
        : null,
    [statusExportAll, statusDownload]
  )

  const [selectedBrand, setSelectedBrand] = useState(
    sessionPersistentStore.isEmptyBrandSelect()
      ? []
      : sessionPersistentStore.getBrandSelect()
  )
  const setBrand = useCallback(
    brands => {
      setSelectedBrand(brands)
      sessionPersistentStore.setBrandSelect(brands)
    },
    [setSelectedBrand]
  )

  const [selectedCategory, setSelectedCategory] = useState(
    sessionPersistentStore.isEmptyCategorySelect()
      ? []
      : sessionPersistentStore.getCategorySelect()
  )

  const setCategory = useCallback(
    categories => {
      setSelectedCategory(categories)
      sessionPersistentStore.setCategorySelect(categories)
    },
    [setSelectedCategory]
  )

  const [pageCurrentIndex, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [sortAction, setSortAction] = useState(null)
  const initSort = [{ id: 'updatedAt', desc: true }]
  const [sortBy, setSort] = useState(initSort)

  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebounce(query, 1000)

  useEffect(() => {
    if (sortBy && sortBy[0] && !getSortBy.get(sortBy[0].id)) {
      setSortAction(sortBy)
    } else {
      setSortAction(null)
      if (statusSeller === 'finished') {
        const promise = dispatch(
          fetchProducts({
            query: debouncedQuery,
            sortBy,
            skip: pageCurrentIndex,
            limit: perPage,
            brands: selectedBrand,
            status: selectCurrentItem,
            category: selectedCategory,
          })
        )
        return () => {
          promise.abort()
        }
      }
    }
  }, [
    dispatch,
    debouncedQuery,
    perPage,
    pageCurrentIndex,
    sortBy,
    selectCurrentItem,
    selectedCategory,
    selectedBrand,
    setSortAction,
    statusSeller,
  ])

  const { status, pagination } = useSelector(getProducts)

  const data = useSelector(resultsProducts(sortAction))

  const { user } = useAuth()

  const isFirstPage = useMemo(() => {
    return !user?.firstLogin && data?.length < 0
  }, [data, user])

  const handleClickOptionOne = useCallback(() => {
    dispatch(
      fetchProductListDownload({
        brands: selectedBrand,
        status: selectCurrentItem,
        category: selectedCategory,
        query: debouncedQuery,
      })
    )
  }, [
    dispatch,
    selectedBrand,
    selectCurrentItem,
    selectedCategory,
    debouncedQuery,
  ])

  const handleClickOptionTwo = useCallback(() => {
    dispatch(
      fetchProductListDownload({
        brands: [],
        status: null,
        category: null,
        query: null,
      })
    )
  }, [dispatch])

  const isMobile = useMediaQuery('(max-width:700px)')

  return (
    <>
      <HeaderContainer>
        <Title>Productos</Title>
        {isFirstPage ? null : (
          <ContainerFlexRow>
            <AddProductButton
              addIcon
              to="/productos/nuevo"
              title="Agregar producto"
            />
            <MenuDropdown
              title="Más acciones"
              options={[
                { label: 'Editar masivamente', to: '/productos/editar' },
                {
                  label: 'Crear masivamente',
                  to: '/productos/nuevo/masivo',
                },
              ]}
            />
            {isMobile && (
              <ButtonDownload
                type="select"
                handleClickOptionOne={handleClickOptionOne}
                handleClickOptionTwo={handleClickOptionTwo}
                showModalOnDownload={true}
              />
            )}
          </ContainerFlexRow>
        )}
      </HeaderContainer>
      {isFirstPage ? (
        <ProductsNotFound />
      ) : (
        <Paper>
          <FilterContainer>
            <SearchProduct query={query} setQuery={setQuery} />
            <SelectState
              label="Estado"
              value={selectCurrentItem}
              onChange={setStatus}
              isfilterright
            />
            <SelectBrand
              label="Marca"
              value={selectedBrand}
              onChange={setBrand}
              isfilterright
              isfilterleft
            />
            <SelectCategory
              label="Categoría"
              value={selectedCategory}
              onChange={setCategory}
              isfilterleft
            />
            {!isMobile && (
              <ButtonDownload
                type="select"
                handleClickOptionOne={handleClickOptionOne}
                handleClickOptionTwo={handleClickOptionTwo}
                showModalOnDownload={true}
              />
            )}
          </FilterContainer>
          <ChipManager
            category={selectedCategory}
            brand={selectedBrand}
            productStatus={selectCurrentItem}
            setBrand={setSelectedBrand}
            setStatus={setSelectItem}
            setCategory={setSelectedCategory}
          />
          <TableTemplate
            manualSortBy
            pathname="/productos"
            isLoading={
              status === 'loading' ||
              statusSeller === 'loading' ||
              status === 'idle' ||
              isEmpty(data)
            }
            isError={status === 'error'}
            data={
              status === 'loading' && isEmpty(data)
                ? Array(perPage).fill({ id: '' })
                : data
            }
            columns={columns}
            onSort={setSort}
            renderFooter={() => (
              <>
                <TableFooter
                  isGraphQL
                  pageCurrentIndex={pageCurrentIndex}
                  hasNextPage={pagination.hasNextPage}
                  hasPreviousPage={pagination.hasPreviousPage}
                  setPage={setPage}
                  listPerPage={[5, 10, 15, 20]}
                  perPage={perPage}
                  handleSelectPerPage={e => {
                    setPerPage(Number(e.target.value))
                  }}
                />
              </>
            )}
          />
        </Paper>
      )}

      <Alert
        open={openProduct}
        onClose={handleCloseProduct}
        title={
          stateExport().get(statusExportAll) ||
          stateDownload().get(statusDownload)
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        isLoading={
          statusExportAll === 'loading' || statusDownload === 'loading'
        }
        severity="info"
        isError={statusExportAll === 'error'}
      />
    </>
  )
}

export default Products
