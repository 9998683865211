import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import { createAsyncThunk } from '@reduxjs/toolkit'

import normalizeOrder from './normalizeOrder'
import subOrderService from '../../../data/services/subOrderService'
import dateApiFormat from '../../adapters/days/dateApiFormat'
import axios from 'axios'

const fetchOrders = createAsyncThunk(
  'orders/filter',
  async (
    {
      startDate,
      endDate,
      startDatePayout,
      endDatePayout,
      status,
      accountingStatement,
      document,
      typeList,
      orderId,
      skip,
      limit,
    },
    { signal, rejectWithValue }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })

    if (limit === -1 || limit === '-1') {
      limit = 0
    }
    const documentType =
      document?.indexOf('Boleta') >= 0
        ? document?.indexOf('Factura') >= 0
          ? undefined
          : { documentType: 'boleta' }
        : document?.indexOf('Factura') >= 0
          ? { documentType: 'factura' }
          : undefined
    const documentName =
      document?.indexOf('Documentos adjuntos') >= 0
        ? document?.indexOf('Documentos por adjuntar') >= 0
          ? undefined
          : { documentName: 1 }
        : document?.indexOf('Documentos por adjuntar') >= 0
          ? { documentName: 0 }
          : undefined

    const { data, pagination } = await subOrderService.orderFilter({
      startDate: startDate ? dateApiFormat(startDate) : undefined,
      endDate: endDate ? dateApiFormat(endDate) : undefined,
      startDatePayout:
        startDatePayout &&
        String(startDatePayout).toLowerCase() !== 'invalid date'
          ? dateApiFormat(startDatePayout)
          : undefined,
      endDatePayout:
        endDatePayout &&
        String(endDatePayout).toLowerCase() !== 'invalid date'
          ? dateApiFormat(endDatePayout)
          : undefined,
      status: status?.join(','),
      accountingStatement: accountingStatement?.join(','),
      type: typeList?.join(','),
      orderId,
      skip: limit < 0 ? undefined : skip,
      limit: limit < 0 ? undefined : limit,
      ...documentType,
      ...documentName,
      cancelToken: source.token,
    })

    const orders = pipe(map(normalizeOrder))(data)
    if (Array(...orders).length === 0) {
      return rejectWithValue('Sin contenido')
    }
    return { orders, pagination }
  },
  {
    condition: (_, { getState }) => {
      const state = getState().seller.detail
      if (state?.status !== 'finished') {
        return false
      }
    },
  }
)
export default fetchOrders
