import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getSellerById } from '../../../../domain/features/seller/getSeller'

import FieldAutoComplete from '../FieldAutoComplete'
import Label from '../../atoms/Label'

const FieldBrand = ({ control, defaultValue }) => {
  const { brandOnlyNameList, status } = useSelector(getSellerById)

  return (
    <>
      <Label htmlFor="Marca" required>
        Marca
      </Label>
      <FieldAutoComplete
        control={control}
        options={brandOnlyNameList}
        defaultValue={defaultValue}
        name="brand"
        placeholder="Selecciona una marca"
        loading={status === 'loading'}
        disabled={status === 'loading'}
        loadingText="Cargando Marcas..."
      />
    </>
  )
}

FieldBrand.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
}
export default FieldBrand
