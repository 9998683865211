import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchLiquidations from './fetchLiquidations'

export const liquidationsAdpater = createEntityAdapter({
  selectId: l => l.id,
  sortComparer: (a, b) => a.payoutDate.localeCompare(b.payoutDate),
})

export const liquidationsSlice = createSlice({
  name: 'liquidations',
  initialState: liquidationsAdpater.getInitialState({
    pagination: {
      currentPagination: 0,
      perPagination: 10,
      paginationTotalCount: 0,
    },
    status: 'idle',
    id: {},
    entities: {},
  }),
  reducers: {
    updateLiquidations: liquidationsAdpater.setAll,
  },
  extraReducers: builder => {
    builder.addCase(fetchLiquidations.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchLiquidations.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchLiquidations.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.pagination = payload.pagination
      liquidationsAdpater.setAll(state, payload.liquidationList)
    })
  },
})

const reducer = liquidationsSlice.reducer
export default reducer
