import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchCategory from './fetchCategory'
import isArray from 'lodash/isArray'
import sortString from '../../utils/sortString'
import categoryList from './categoryList'
import persistentStore from '../../../data/persistentStore'

export const categoryAdapter = createEntityAdapter({
  selectId: ({ id }) => String(id),
})

export const categorySlice = createSlice({
  name: 'category',
  initialState: categoryAdapter.getInitialState({
    status: 'idle',
    entities: {},
    categoryOnlyNameList: persistentStore.isCategory()
      ? Array(...categoryList)
      : Array(...persistentStore.getCategory()),
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCategory.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchCategory.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchCategory.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.categoryOnlyNameList = isArray(payload)
        ? payload
            .map(({ product_type_name }) => product_type_name)
            .sort(sortString)
        : []
      categoryAdapter.setAll(state, payload)
      persistentStore.setCategory(state.categoryOnlyNameList)
    })
  },
})

const reducer = categorySlice.reducer
export default reducer
