import { useDispatch, useSelector } from 'react-redux'
import {
  addStartDayPayout,
  addEndDayPayout,
  getCurrentDatePayout,
} from '../../../../domain/features/ui/rangeOfDaysPayoutSlice'
import InputRangeDate from '../../atoms/InputBase/InputRangeDate/InputRangeDate'

const RangeOfDaysPayout = () => {
  const date = useSelector(getCurrentDatePayout)
  const dispatch = useDispatch()
  const onChange = dates => {
    const [start, end] = dates
    dispatch(addStartDayPayout(start))
    dispatch(addEndDayPayout(end))
  }

  return (
    <InputRangeDate
      withborder={String(true)}
      startDate={date[0]}
      endDate={date[1]}
      onChange={onChange}
      placeholderText="Fecha liquidación"
    />
  )
}

export default RangeOfDaysPayout
