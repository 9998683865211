import pipe from 'lodash/fp/pipe'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import reduce from 'lodash/fp/reduce'
import { normalizeVariantInventoryLevel } from '../variants/getVariantInventoryLevel'
import normalizeGraphQL from '../../features/productSearchApi/normalizeProducts'
import { getMeta } from '../graphql/getKey'

export const checkProductCreate = product => ({
  ...product,
  pathname: `/productos/${product?.id?.split('Product/')[1]}`,
  success: true,
})

const addCheck = data => ({
  ...data,
  checked: false,
})
const normalizeMedia = data => ({
  ...data,
  file: {
    type: data?.mimeType || 'image/jpeg',
    src: data?.preview?.image?.url || '',
    name: data?.preview?.image?.altText || '',
    size: 100,
  },
})
const addAtributtes = allMedia => data => {
  const metafields = getMeta(data?.metafields)

  const media = allMedia
    ? allMedia.filter(
        media =>
          media?.alt === data?.title || media?.alt.includes(data?.title)
      )
    : data?.media
  const mediaIds = media?.map(({ preview }) => preview?.image?.url) || []

  return {
    ...data,
    variantId: data?.id,
    color: data?.selectedOptions[0]?.value || '',
    ownSku: metafields('sku_propio')?.value || '',
    inventoryQuantity: data?.currentAvailable || 0,
    compareAtPrice: data?.compareAtPrice || data?.price || 0,
    mediaIds,
  }
}
const splitValue = (value, label, name) => ({
  metafield: value.split('^')[0],
  value: value.split('^')[1],
  label,
  name,
})
const createValue = (value, label, name, key = '') => ({
  metafield: key,
  value: value,
  label,
  name,
})
const filesMap = value =>
  new Map([
    [
      'manual_de_uso',
      {
        input: splitValue(value, 'Manual de uso', 'manual'),
        name: 'manual',
      },
    ],
    [
      'ficha_tecnica',
      {
        input: splitValue(value, 'Ficha técnica', 'dataSheet'),
        name: 'dataSheet',
      },
    ],
    [
      'certificado_QR',
      {
        input: splitValue(value, 'Certificado SEC QR', 'certificate'),
        name: 'certificate',
      },
    ],
    [
      'eficiencia_energetica',
      {
        input: splitValue(value, 'Eficiencia energética', 'efficiency'),
        name: 'efficiency',
      },
    ],
    [
      'inpage',
      { input: splitValue(value, 'Inpage', 'inpage'), name: 'inpage' },
    ],
    [
      'description_image',
      {
        input: {
          metafield: 'Foto Encastre',
          value,
          name: 'descriptionImage',
          label: 'Foto encastre',
        },
        name: 'descriptionImage',
      },
    ],
  ])
const isExternalVideo = ({ mediaContentType }) =>
  mediaContentType === 'EXTERNAL_VIDEO'

export const pipeMedia = ({ media }) =>
  pipe(map(addCheck), map(normalizeMedia))(media)
const pipeVariant = ({ variants, media }) =>
  pipe(
    normalizeGraphQL,
    map(normalizeVariantInventoryLevel),
    map(addAtributtes(media)),
    map(addCheck)
  )(variants)?.map((v, index) => ({ ...v, id: index }))
const pipeFile = ({ metafields }) =>
  pipe(
    normalizeGraphQL,
    filter(data => {
      return (
        (data?.namespace === 'specification' &&
          data?.key === 'description_image') ||
        data?.namespace === 'material_descargable'
      )
    }),
    map(({ key, value }) => {
      if (key === 'eficiencia_energetica') {
        return {
          input: createValue(
            value,
            'Eficiencia Energetica',
            'efficiency',
            'eficiencia_energetica'
          ),
          name: 'efficiency',
        }
      }
      if (key === 'manual_de_uso') {
        return {
          input: createValue(
            value,
            'Manual de uso',
            'manual',
            'manual_de_uso'
          ),
          name: 'manual',
        }
      }
      if (key === 'ficha_tecnica') {
        return {
          input: createValue(
            value,
            'Ficha técnica',
            'dataSheet',
            'ficha_tecnica'
          ),
          name: 'dataSheet',
        }
      }
      if (key === 'certificado_QR') {
        return {
          input: createValue(
            value,
            'Certificado SEC QR',
            'certificate',
            'certificado_QR'
          ),
          name: 'certificate',
        }
      }
      if (key === 'inpage') {
        return {
          input: createValue(
            value,
            'Eficiencia Energetica',
            'inpage',
            'inpage'
          ),
          name: 'inpage',
        }
      }
      if (key === 'eficiencia_energetica') {
        return {
          input: createValue(
            value,
            'Eficiencia Energetica',
            'efficiency',
            'eficiencia_energetica'
          ),
          name: 'efficiency',
        }
      }
      return filesMap(value).get(key)
    }),
    reduce((acc, { name, input }) => {
      return { ...acc, [name]: input }
    }, {})
  )(metafields)

const normalizeProduct = data => {
  if (!data) {
    return {}
  }
  const media = [...normalizeGraphQL(data?.media)]
  return {
    ...data,
    video: media?.filter(isExternalVideo)[0]?.embedUrl || '',
    media: pipeMedia({ media }),
    variants: pipeVariant({ ...data, media }),
    metafields: normalizeGraphQL(data?.metafields),
    images: normalizeGraphQL(data?.images),
    files: pipeFile(data),
  }
}
export default normalizeProduct
