import { createSelector } from '@reduxjs/toolkit'
import { productDetailAdapter } from './productDetailSlice'

const getStateProducts = state => state.products.detail

export const getProductDetail = createSelector(
  [getStateProducts],
  detail => detail
)

const productDetailSelector =
  productDetailAdapter.getSelectors(getStateProducts)
export const resultProductDetail = productDetailSelector.selectAll
