import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import auth, { onIdTokenChanged } from '../../data/firebase/auth'
import persistentStore from '../../data/persistentStore'

const ProtectedRoute = ({ children }) => {
  useEffect(() => {
    const unsubcribe = onIdTokenChanged(
      auth,
      user => user && persistentStore.setToken(user.accessToken)
    )
    return unsubcribe
  }, [])

  const location = useLocation()
  const token = persistentStore.getToken()

  if (!token) {
    return <Navigate to="/" replace state={{ from: location }} />
  }

  return children ? children : <Outlet />
}
ProtectedRoute.propTypes = { children: PropTypes.node }
export default ProtectedRoute
