import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import FieldPrice from '../../atoms/FieldPrice'

const FieldPriceController = ({
  control,
  name,
  defaultValue,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, ref, ...fields },
        fieldState: { error },
      }) => (
        <FieldPrice
          {...{ name, ...props }}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          {...fields}
        />
      )}
    />
  )
}

FieldPriceController.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  helperText: PropTypes.string,
}

export default FieldPriceController
