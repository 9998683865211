import { createAsyncThunk } from '@reduxjs/toolkit'
import subOrderService from '../../../data/services/subOrderService'
import dateApiFormat from '../../adapters/days/dateApiFormat'
import axios from 'axios'

const fetchOrderListDownload = createAsyncThunk(
  'order/export',
  async (
    {
      startDate,
      endDate,
      startDatePayout,
      endDatePayout,
      status,
      accountingStatement,
      typeList,
      orderId,
      seller,
    },
    { signal, rejectWithValue }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })

    try {
      const data = await subOrderService.exportOrderAllData({
        startDate: startDate ? dateApiFormat(startDate) : undefined,
        endDate: endDate ? dateApiFormat(endDate) : undefined,
        startDatePayout: startDatePayout
          ? dateApiFormat(startDatePayout)
          : undefined,
        endDatePayout: endDatePayout
          ? dateApiFormat(endDatePayout)
          : undefined,
        status: status?.join(','),
        accountingStatement: accountingStatement?.join(','),
        seller,
        type: typeList?.join(','),
        orderId,
        cancelToken: source.token,
      })

      if (data) {
        const resJson = await data
        const blob = resJson.file

        if (blob) {
          const newBlob = new Blob([new Uint8Array(resJson.file.data)])

          const blobUrl = window.URL.createObjectURL(newBlob)

          const link = document.createElement('a')
          link.href = blobUrl
          link.setAttribute('download', 'pedidos.xlsx')
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(newBlob)
        }

        return resJson
      } else {
        throw new Error('file not found')
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchOrderListDownload
