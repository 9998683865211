import PropTypes from 'prop-types'
import FieldCountryAutoComplete from '../FieldCountryAutoComplete'
import Label from '../../atoms/Label'
import countries from '../../../../domain/mappers/countries'

const FieldCountry = ({ control, defaultValue }) => {
  return (
    <>
      <Label htmlFor="País de fabricación" required>
        País de fabricación
      </Label>
      <FieldCountryAutoComplete
        control={control}
        options={countries.map(({ label }) => String(label))}
        name="manufacturedCountry"
        placeholder="Selecciona un país"
        defaultValue={defaultValue}
      />
    </>
  )
}

FieldCountry.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
}
export default FieldCountry
