import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { APIProvider } from '@vis.gl/react-google-maps'
import { store } from '../domain/store/store'
import ThemeProvider from './theme/ThemeProvider'
import Routes from './routes/Routes'
import { ProvideWorker } from './hooks/useWorker'
import useServiceWorker from './hooks/useServiceWorker'

export const WrapperApp = ({ children }) => (
  <Provider store={store}>
    <APIProvider
      apiKey="AIzaSyBEtSuYTfNoH-qJ4VTHda5QrpLlXfo9nsQ"
      region="CL"
      language="es"
      libraries={['geocoding', 'market']}
    >
      {children}
    </APIProvider>
  </Provider>
)
WrapperApp.propTypes = { children: PropTypes.node }

const App = () => {
  const worker = useServiceWorker()

  return (
    <Provider store={store}>
      <ProvideWorker value={worker}>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
      </ProvideWorker>
    </Provider>
  )
}

export default App
