import { debounce } from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'
import fetchProductExportById from '../productExportById/fetchProductExportById'

const recursiveFetch = debounce(async (dispatch, bulkId) => {
  let rejected
  const data = await dispatch(fetchProductExportById({ bulkId }))
  rejected = data.meta.requestStatus
  if (rejected === 'rejected') {
    return await recursiveFetch(dispatch, bulkId)
  }
  return true
}, 2000)

const fetchProductExport = createAsyncThunk(
  'product/export',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const brandList = getState().seller.detail.brandOnlyNameList
      const response = await productAdminServices.export({
        brands: brandList,
      })
      if (response.error) {
        return rejectWithValue('Operación bulk falló')
      }
      recursiveFetch(dispatch, response.id)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchProductExport
