import { Button, CircularProgress } from '@mui/material'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSpring, animated } from '@react-spring/web'
import Text from '../../atoms/Typography/Text'
import { useSelector } from 'react-redux'
import { isOpenDrawer } from '../../../../domain/features/ui/drawerSlice'
import useMediaQuery from '@mui/material/useMediaQuery'

const Container = styled(animated('div'))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1400;
  background-color: #dbdbdb;
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    height: auto;
    justify-content: flex-start;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 0px;
    padding-right: 0px;
  }
`
Container.displayName = 'StickyBarAnimation'

const SubContainer = styled('div')`
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-left: 10%;
  @media (max-width: 1100px) {
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
    padding-left: 5%;
  }
`

const ButtonSendStyled = styled(Button)`
  width: 240px;
  height: 38px;
  border-radius: 5px;
  margin-right: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  box-shadow: none;
  @media (max-width: 1100px) {
    margin: 0px;
    margin-top: 3%;
    margin-right: 10px;
    width: auto;
  }
`

const ButtonContainer = styled('div')`
  .MuiButtonBase-root:disabled {
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'not-allowed'};
    pointer-events: auto;
  }
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    width: auto;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`

const Spinner = styled(CircularProgress)`
  color: #f6f6f7;
`

const StyledText = styled(Text)`
  font-size: 25px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  width: 100%;
`

const StickyBar = ({
  isSubmitting,
  isOpen,
  onCancel,
  onSubmit,
  disabled,
  isError,
}) => {
  const open = useSelector(isOpenDrawer)
  const isMobile = useMediaQuery('(max-width:1100px)')
  const animation = useSpring({
    to: {
      marginLeft: isMobile ? '0px' : open ? '235px' : '60px',
      height: isOpen ? '96px' : '0px',
      opacity: isOpen ? 1 : 0,
    },
    from: {
      height: !isOpen ? '96px' : '0px',
      opacity: 0,
    },
    native: true,
  })

  return (
    <Container style={animation}>
      <SubContainer>
        <StyledText>Cambios no guardados</StyledText>
        <ButtonContainer isloading={String(isSubmitting)}>
          <ButtonSendStyled
            onClick={onCancel}
            disabled={isSubmitting}
            variant="contained"
            color="third"
          >
            Descartar
          </ButtonSendStyled>

          <ButtonSendStyled
            type="submit"
            onClick={onSubmit}
            variant="contained"
            color={isError ? 'error' : 'primary'}
            disabled={isSubmitting || disabled}
            endIcon={isSubmitting ? <Spinner size="1.2rem" /> : null}
          >
            Guardar Cambios
          </ButtonSendStyled>
        </ButtonContainer>
      </SubContainer>
    </Container>
  )
}

StickyBar.propTypes = {
  isSubmitting: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
}

StickyBar.defaultProps = {
  isSubmitting: false,
  isOpen: false,
  onCancel: undefined,
  onSubmit: undefined,
  disabled: false,
  isError: false,
}
export default StickyBar
