import client from '../client/client'
import persistentStore from '../persistentStore'

const subOrderService = (baseUrl, endpoint) => ({
  orderDetail: async ({ parentId = undefined, cancelToken }) => {
    try {
      const response = await client(
        `${baseUrl}/${endpoint}/by-id/${parentId}`,
        {
          cancelToken,
        }
      )

      return response.data
    } catch (error) {
      return error
    }
  },
  fileUpdate: async ({
    document,
    orderId = undefined,
    signal = undefined,
  }) => {
    const data = new FormData()
    data.append('document', document)
    data.append('orderId', orderId)
    const response = await fetch(`${baseUrl}/${endpoint}/add-document`, {
      method: 'POST',
      headers: {
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      signal,
      body: data,
    })
    return await response.json()
  },
  documentDownload: async ({ orderId, signal = undefined }) => {
    const response = await fetch(
      `${baseUrl}/${endpoint}/get-document?orderId=${orderId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME,
        },
        signal,
      }
    )
    const { data } = await response.json()
    return data
  },

  documentDelete: async ({ orderId, signal = undefined }) => {
    const response = await fetch(
      `${baseUrl}/${endpoint}/delete-document?orderId=${orderId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME,
        },
        signal,
      }
    )
    const { data } = await response.json()
    return data
  },
  orderFilter: async ({
    startDate = undefined,
    endDate = undefined,
    startDatePayout = undefined,
    endDatePayout = undefined,
    skip = undefined,
    limit = 25,
    status = undefined,
    accountingStatement = undefined,
    orderId = undefined,
    type = undefined,
    documentType = undefined,
    documentName = undefined,
    cancelToken,
  }) => {
    let queryParams = `limit=${limit}`

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }
    if (startDatePayout) {
      queryParams += `&fromDatePayout=${startDatePayout}`
    }

    if (endDatePayout) {
      queryParams += `&toDatePayout=${endDatePayout}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate?.replace('00:00:00', '23:59:59')}`
    }

    if (orderId) {
      queryParams += `&id=${orderId}`
    }

    if (status) {
      queryParams += `&status=${encodeURIComponent(status)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (type) {
      queryParams += `&type=${encodeURIComponent(type)}`
    }
    if (documentType) {
      queryParams += `&documentType=${encodeURIComponent(documentType)}`
    }

    if (documentName !== undefined) {
      queryParams += `&documentName=${documentName}`
    }

    if (skip) {
      queryParams += `&skip=${skip}`
    }

    const response = await client(
      `${baseUrl}/${endpoint}/by-seller?${queryParams}`,
      { cancelToken }
    )

    return response.data
  },

  exportOrderAllData: async ({
    startDate = undefined,
    endDate = undefined,
    startDatePayout = undefined,
    endDatePayout = undefined,
    status = undefined,
    accountingStatement = undefined,
    type = undefined,
    seller = undefined,
    orderId = undefined,
    cancelToken,
  }) => {
    let queryParams = `seller=${encodeURIComponent(seller)}`

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }

    if (endDate) {
      queryParams += `&toDate=${endDate?.replace('00:00:00', '23:59:59')}`
    }
    if (startDatePayout) {
      queryParams += `&fromDatePayout=${startDatePayout}`
    }

    if (endDatePayout) {
      queryParams += `&toDatePayout=${endDatePayout}`
    }

    if (orderId) {
      queryParams += `&id=${orderId}`
    }

    if (status) {
      queryParams += `&status=${encodeURIComponent(status)}`
    }

    if (accountingStatement) {
      queryParams += `&accountingStatement=${encodeURIComponent(
        accountingStatement
      )}`
    }

    if (type) {
      queryParams += `&type=${encodeURIComponent(type)}`
    }

    const response = await client(
      `${baseUrl}/${endpoint}/download?${queryParams}`,
      { cancelToken }
    )

    return response.data
  },
})

export default subOrderService(
  process.env.REACT_APP_API_SETTLEMENT_PATH,
  'suborder'
)
