import { useState } from 'react'
import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  getIdToken,
} from 'firebase/auth'
import auth from '../../../../data/firebase/auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import { yupResolver } from '@hookform/resolvers/yup'

import { schema } from '../../../../domain/schemas/passwordFormSchema'

import InputPasswordController from '../../molecules/InputPasswordController/InputPasswordController'
import InputEmailController from '../../molecules/InputEmailController/InputEmailController'
import Title from '../../atoms/Typography/Title'
import clock from '../../../assets/clock.svg'
import Text from '../../atoms/Typography/Text'
import userServices from '../../../../data/services/userServices'

const Spinner = styled(CircularProgress)`
  color: #f6f6f7;
`

const Container = styled('form')`
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 40%;
  @media only screen and (max-width: 1010px) {
    width: 100%;
  }
`

const ButtonContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`

const ButtonStyled = styled(Button)`
  height: 49px;
  width: 213.93px;
  border-radius: 5px;
`

const SubText = styled('p')`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #828487;
  width: 100%;
  margin-top: -10%;
`

const SubContainer = styled('div')`
  color: #e72020;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 55vh;
  margin-top: -56px;
`

const FormContainer = styled('div')`
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  margin-top: -15%;
`

const FormTitle = styled(Title)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
  white-space: nowrap;
  padding-bottom: 25%;
`

const TimeText = styled(Text)`
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.palette.text.primary};
`

const Icon = styled('img')`
  margin-right: 1%;
`

const TimeContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
`

const PasswordForm = () => {
  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
  })
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const [searchParams] = useSearchParams()
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()

  const setPasswordHandler = async data => {
    const { password, email } = data
    const actionCode = searchParams.get('oobCode')

    return confirmPasswordReset(auth, actionCode, password)
      .then(() => {
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password).then(
          async userCredential => {
            const token = await getIdToken(userCredential.user, true)
            await userServices.firstLogin({
              uid: userCredential.user.uid,
              token,
            })

            return navigate('/onboarding/1')
          }
        )
      })
      .catch(() => {
        return navigate(`/expirado?email=${email}`)
      })
      .finally(() => setLoading(false))
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <Container onSubmit={handleSubmit(setPasswordHandler)}>
      <FormContainer>
        <FormTitle>¡Bienvenido!</FormTitle>
        <SubContainer>
          <SubText>
            Ingresa la nueva contraseña de tu cuenta Seller Center
          </SubText>
        </SubContainer>
        <InputEmailController
          label="Ingresa tu correo electrónico"
          errors={errors}
          showError={false}
          control={control}
          disabled={true}
          email={searchParams.get('email')}
        />
        <InputPasswordController
          label="Contraseña"
          showError={false}
          control={control}
          errors={errors}
          showPassword={values.showPassword}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
        />
        <InputPasswordController
          label="Reingresa tu contraseña"
          showError={false}
          control={control}
          name="passwordConfirmation"
          errors={errors}
          showPassword={values.showConfirmPassword}
          handleClickShowPassword={handleClickShowConfirmPassword}
          handleMouseDownPassword={handleMouseDownPassword}
        />
        <TimeContainer>
          <Icon src={clock} alt="clock" />
          <TimeText>
            Recuerda que este link expira a minutos de haber sido enviado.
          </TimeText>
        </TimeContainer>
      </FormContainer>

      <ButtonContainer>
        <ButtonStyled
          onClick={handleSubmit(setPasswordHandler)}
          variant="contained"
          endIcon={
            isLoading || isSubmitting ? <Spinner size="1.2rem" /> : null
          }
        >
          Comenzar
        </ButtonStyled>
      </ButtonContainer>
    </Container>
  )
}

export default PasswordForm
