import styled from 'styled-components'
import Text from '../Typography/Text'

const Tag = styled(Text)`
  background-color: ${({ status, theme }) =>
    status || theme.palette.warning.main};
  border-radius: 15px;
  padding: 0.6rem;
  height: 20px;
  width: 95px;
  margin-left: 1rem;
  width: ${({ isproduct }) => (isproduct === 'true' ? '145px' : 'auto')};
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: help;
  @media (max-width: 1100px) {
    margin-top: 0.5rem;
  }
`
export default Tag
