import isString from 'lodash/isString'

const capitalizeName = title => {
  const excludeWords = [
    'de',
    'del',
    'y',
    'o',
    'a',
    'en',
    'el',
    'la',
    'los',
    'las',
    'un',
    'una',
    'unos',
    'unas',
    'con',
  ]

  if (!isString(title)) {
    return ''
  }

  const capitalizeWord = (word, index) =>
    excludeWords.includes(word) && index !== 0
      ? word
      : word.charAt(0).toUpperCase() + word.slice(1)

  const words = title.toLowerCase().split(' ')
  const capitalizedWords = words.map(capitalizeWord)

  const result = capitalizedWords.join(' ')
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)

  return finalResult
}

export default capitalizeName
