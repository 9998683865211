export const createFilesArray = (
  manual,
  certificate,
  efficiency,
  dataSheet,
  inpage,
  descriptionImage
) => {
  const files = []
  if (manual) {
    files.push({
      file: manual[0],
      metafield: 'manual',
      name: manual[0]?.name,
    })
  }
  if (certificate) {
    files.push({
      file: certificate[0],
      metafield: 'certificate',
      name: certificate[0]?.name,
    })
  }
  if (efficiency) {
    files.push({
      file: efficiency[0],
      metafield: 'efficiency',
      name: efficiency[0]?.name,
    })
  }
  if (dataSheet) {
    files.push({
      file: dataSheet[0],
      metafield: 'dataSheet',
      name: dataSheet[0]?.name,
    })
  }
  if (inpage) {
    files.push({
      file: inpage[0],
      metafield: 'inpage',
      name: inpage[0]?.name,
    })
  }
  if (descriptionImage) {
    files.push({
      file: descriptionImage[0],
      metafield: 'descriptionImage',
      name: descriptionImage[0]?.name,
    })
  }

  return files
}
