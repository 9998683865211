import client from '../client/client'
import { requestFile } from '../client/request'
import persistentStore from '../persistentStore'

const statusBase = new Map([
  ['Aprobado', 'ACTIVE'],
  ['Deshabilitado', 'ARCHIVED'],
  ['Pendiente aprobación', 'DRAFT'],
])
const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index
}
const productAdminServices = (baseUrl, endpoint) => ({
  getProducts: async function ({
    tagSeller,
    brands,
    brandsAll,
    title,
    status,
    limit = 20,
    cursor = null,
    direction = 'next',
    sortKey = 'UPDATED_AT',
    reverse = true,
    category = undefined,
    cancelToken = undefined,
  }) {
    let brandList
    if (brands.length === brandsAll.length) {
      brandList = `tag:'${tagSeller}'`
    } else {
      const brandsNotSelected = brandsAll
        .filter(brand => !brands.some(br => br === brand.brandName))
        .map(({ brandName }) => `( NOT vendor:'${brandName}')`)
        .filter(onlyUnique)
        .join(' AND ')

      brandList = `tag:'${tagSeller}' AND ${brandsNotSelected}`
    }

    const statusList = status
      ? Array(...status)
          .map(value => `(status:${statusBase.get(value)})`)
          .join(' OR ')
      : undefined
    const categoryList = category
      ? Array(...category)
          .map(
            product_type_name => `(product_type:'${product_type_name}')`
          )
          .join(' OR ')
      : undefined

    const body = JSON.stringify({
      query: `${brandList}${categoryList ? ` AND ${categoryList}` : ''}${
        title ? ` AND (title:*${title}* OR sku:*${title}*)` : ''
      }${status ? ` AND ${statusList}` : ''}`,
      direction,
      cursor,
      quantity: limit,
      sortKey,
      reverse,
    })
    const response = await client(`${baseUrl}/${endpoint}/list_mkp`, {
      data: body,
      method: 'post',
      cancelToken,
    })

    return response.data
  },
  createProduct: async body => {
    const response = await client(`${baseUrl}/${endpoint}/create`, {
      method: 'post',
      data: JSON.stringify(body),
    })
    return response.data
  },
  updateProduct: async body => {
    const response = await client(`${baseUrl}/${endpoint}/update`, {
      method: 'post',
      data: JSON.stringify(body),
    })
    return response.data
  },
  uploadFiles: async data => {
    const response = await requestFile(
      `${baseUrl}/${endpoint}/upload-files`,
      {
        method: 'post',
        data,
      }
    )
    return response.data
  },
  validate: async data => {
    const response = await fetch(`${baseUrl}/${endpoint}/import/valid`, {
      method: 'POST',
      headers: {
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      body: data,
    })
    return response
  },
  validateCreate: async data => {
    const response = await fetch(
      `${baseUrl}/${endpoint}/import/create/valid`,
      {
        method: 'POST',
        headers: {
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME,
        },
        body: data,
      }
    )
    return response
  },
  import: async data => {
    const response = await fetch(`${baseUrl}/${endpoint}/import`, {
      method: 'POST',
      headers: {
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      body: data,
    })

    return response.json()
  },
  importCreate: async data => {
    const response = await fetch(`${baseUrl}/${endpoint}/import/create`, {
      method: 'POST',
      headers: {
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      body: data,
    })

    return response.json()
  },
  exportById: async bulkId => {
    const response = await fetch(
      `${baseUrl}/${endpoint}/export/${bulkId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME,
        },
      }
    )
    return response
  },
  exportAllById: async bulkId => {
    const response = await fetch(
      `${baseUrl}/${endpoint}/export/all/${bulkId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME,
        },
      }
    )
    return response
  },
  export: async ({ brands }) => {
    const brandList = brands
      .map(brandName => `(vendor:'${brandName}')`)
      .join(' OR ')

    const response = await fetch(`${baseUrl}/${endpoint}/export`, {
      method: 'POST',
      body: JSON.stringify({
        query: `${brandList}`,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
    })

    return response.json()
  },
  exportCreate: async ({ categories }) => {
    const response = await fetch(`${baseUrl}/${endpoint}/export/create`, {
      method: 'POST',
      body: JSON.stringify({
        categories: categories,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
    })

    return response
  },
  exportProductAllData: async ({
    brands,
    status,
    category,
    brandsAll,
    title,
    tag,
  }) => {
    let brandList
    if (brands.length === brandsAll.length) {
      brandList = `tag:'${tag}'`
    } else {
      const brandsNotSelected = brandsAll
        .filter(brand => !brands.some(br => br === brand.brandName))
        .map(({ brandName }) => `( NOT vendor:'${brandName}')`)
        .filter(onlyUnique)
        .join(' AND ')

      brandList = `tag:'${tag}' AND ${brandsNotSelected}`
    }

    const statusList =
      status && status.length
        ? Array(...status)
            .map(value => `(status:${statusBase.get(value)})`)
            .join(' OR ')
        : null
    const categoryList =
      category && category.length
        ? Array(...category)
            .map(
              product_type_name => `(product_type:${product_type_name})`
            )
            .join(' OR ')
        : null

    const response = await fetch(`${baseUrl}/${endpoint}/export/all`, {
      method: 'POST',
      body: JSON.stringify({
        query: `${brandList}${categoryList ? ` AND ${categoryList}` : ''}${
          title ? ` AND title:*${title}*` : ''
        }${statusList ? ` AND ${statusList}` : ''}`,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
    })

    return response.json()
  },
  getProductDetailById: async ({ id }) => {
    const response = fetch(`${baseUrl}/${endpoint}/detail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME,
      },
      body: JSON.stringify({
        id,
      }),
    })

    return response
  },
  getProductBySku: async ({ listSku, cancelToken }) => {
    const response = await client(`${baseUrl}/${endpoint}/sku`, {
      method: 'post',
      data: JSON.stringify({
        listSku,
      }),
      cancelToken,
    })

    return response.data
  },
})

export default productAdminServices(
  process.env.REACT_APP_API_PRODUCT_PATH,
  'product'
)
