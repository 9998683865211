import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  addOneType,
  allTypes,
  removeAllType,
  removeOneType,
} from '../../../../domain/features/ui/typeSlice'

import SelectWithChips from '../../molecules/SelectWithChips'
import { typeList } from './reasonList'

const Select = styled(SelectWithChips)`
  max-width: 20rem;
  min-width: auto;
`

const SelectType = () => {
  const accountingStatement = useSelector(allTypes)

  const dispatch = useDispatch()
  const handleChange = useCallback(
    event => {
      return event.target.value !== 'borrar'
        ? dispatch(addOneType(event.target.value))
        : dispatch(removeAllType())
    },
    [dispatch]
  )

  const handleDelete = useCallback(
    value => {
      dispatch(removeOneType(value))
    },
    [dispatch]
  )

  return (
    <Select
      label="Tipo"
      isCheckbox
      isallfilter={true}
      value={accountingStatement}
      onChange={handleChange}
      list={Object.values(typeList)}
      handleDeleteChip={handleDelete}
      handleDeleteAll={value => dispatch(removeAllType(value))}
    />
  )
}

export default SelectType
