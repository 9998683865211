export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{1})(\d{4})(\d{4})$/)
  if (match) {
    var intlCode = !match[1] ? '+56' : ''
    return [intlCode, match[2], ' ', match[3], ' ', match[4]].join(
      ''
    )
  }
  return phoneNumberString
}
