import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import { bool, string, object, func } from 'prop-types'

const InputStyled = styled(TextField)`
  .MuiOutlinedInput-root {
    cursor: ${({ isloading }) => (isloading === 'true' ? 'wait' : 'text')};
    height: 36px;
    font-size: 13px;
    border-color: ${({ theme }) => theme.palette.primary.light};
    & fieldset {
      border-color: ${({ theme }) => theme.palette.primary.light};
    }
  }
  .MuiFormLabel-root {
    line-height: 21.09px;
    font-size: 19px;
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 400, normal;
    left: -15px;
  }
  .Mui-required {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .MuiOutlinedInput-root.Mui-error {
    color: ${({ error, theme }) =>
      !error ? theme.palette.primary.light : theme.palette.error.dark};
  }

  .MuiFormHelperText-root {
    padding-top: 6px;
    font-weight: 400, normal;
    font-size: 11px;
    line-height: 12.89px;
    margin-left: 0px;
  }
  .customtextfield {
    & input::placeholder {
      font-size: 13px;
    }
  }
  .Mui-disabled {
    cursor: not-allowed;
    color: #d1d0d0;
    border-color: #d1d0d0;
    pointer-events: auto;
  }
`

const Input = ({
  errors,
  name,
  helperText,
  onChange,
  onBlur,
  ...props
}) => {
  return (
    <InputStyled
      fullWidth
      variant="outlined"
      InputLabelProps={{ shrink: false }}
      error={errors && errors[name]}
      helperText={
        errors && errors[name] ? errors[name].message : helperText
      }
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
  )
}

Input.propTypes = {
  label: string,
  required: bool,
  error: bool,
  placeholder: string,
  helperText: string,
  errors: object,
  name: string,
  onChange: func,
  onBlur: func,
  InputProps: object,
}

export default Input
