import PropTypes from 'prop-types'
import { memo } from 'react'
import Text from '../../../Typography/Text'

const CellNumber = memo(({ number }) => {
  return <Text>${Number(number).toLocaleString('es-CL')}</Text>
})
CellNumber.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
CellNumber.defaultProps = {
  number: 0,
}
CellNumber.displayName = 'CellNumber'
export default CellNumber
