import sessionStore from '../../../data/sessionPersistentStore'
import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'

const initialState = {
  dates: sessionStore.isEmptyRangeDatePayout()
    ? [undefined, undefined]
    : sessionStore.getRangeDatePayout(),
}

const rangeOfDaysPayoutSlice = createSlice({
  name: 'datePayout',
  initialState,
  reducers: {
    addStartDayPayout(state, { payload }) {
      const [, end] = state.dates
      state.dates = [payload, end]
    },
    addEndDayPayout(state, { payload }) {
      const [start] = state.dates
      state.dates = [start, payload]
    },
    addRangeOfDaysPayout(state, { payload }) {
      sessionStore.setRangeDatePayout(dates)
      state.dates = payload
    },
    resetRangeOfDaysPayout(state) {
      sessionStore.removeRangeDatePayout()
      state.dates = [undefined, undefined]
    },
  },
})

export const {
  addRangeOfDaysPayout,
  addStartDayPayout,
  addEndDayPayout,
  resetRangeOfDaysPayout,
} = rangeOfDaysPayoutSlice.actions

const getDaySelector = state => state.ui.rangeOfDayPayout

export const getCurrentDatePayout = createSelector(
  [getDaySelector],
  state => state.dates
)

const reducer = rangeOfDaysPayoutSlice.reducer
export default reducer
