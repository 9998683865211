import { Outlet } from 'react-router-dom'
import Drawer from '../../components/template/Drawer/Drawer'

import Header from '../../components/template/Header'

import Margin from './Margin'
import TopPadding from './TopPadding'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import fetchSeller from '../../../domain/features/seller/fetchSeller'
import useRefreshToken from '../../hooks/useRefreshToken'
import ButtonTop from '../../components/atoms/ButtonTop/ButtonTop'

const Layout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const promise = dispatch(fetchSeller())
    return () => {
      promise.abort()
    }
  }, [dispatch])

  useRefreshToken()
  return (
    <>
      <Header />
      <Drawer />
      <Margin>
        <TopPadding />
        <Outlet />
      </Margin>
      <ButtonTop />
    </>
  )
}

export default Layout
