import client from '../client/client'

const categoryServices = (baseUrl, endpoint) => ({
  getCategories: async ({ cancelToken }) => {
    const response = await client(`${baseUrl}/${endpoint}`, {
      cancelToken,
    })

    return response.data
  },
})

export default categoryServices(
  process.env.REACT_APP_API_PRODUCT_PATH,
  'product-types'
)
