import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { removeAllDocument } from '../../../../domain/features/ui/documentSlice'

import SelectWithChips from '../../molecules/SelectWithChips'
import {
  addOneDocument,
  allDocument,
  removeOneDocument,
} from '../../../../domain/features/ui/documentSlice'

const Select = styled(SelectWithChips)`
  max-width: 20rem;
  min-width: auto;
`

const SelectDocument = ({ isallfilter }) => {
  const document = useSelector(allDocument)

  const dispatch = useDispatch()
  const handleChange = useCallback(
    event => {
      return event.target.value !== 'borrar'
        ? dispatch(addOneDocument(event.target.value))
        : dispatch(removeAllDocument())
    },
    [dispatch]
  )

  const handleDelete = useCallback(
    value => {
      dispatch(removeOneDocument(value))
    },
    [dispatch]
  )

  return (
    <Select
      label="Documento tributario"
      isCheckbox
      isallfilter={isallfilter}
      value={document}
      onChange={handleChange}
      list={[
        'Boleta',
        'Factura',
        'separator',
        'Documentos adjuntos',
        'Documentos por adjuntar',
      ]}
      handleDeleteChip={handleDelete}
      handleDeleteAll={value => dispatch(removeAllDocument(value))}
    />
  )
}
SelectDocument.propTypes = {
  isallfilter: PropTypes.bool,
}
SelectDocument.defaultProps = {}
export default SelectDocument
