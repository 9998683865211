import { createAsyncThunk } from '@reduxjs/toolkit'
import locationService from '../../../data/services/locationService'
import axios from 'axios'

const fetchLocations = createAsyncThunk(
  'location/list',
  async (_, { rejectWithValue, signal }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    try {
      const locations = await locationService.getLocationList({
        cancelToken: source.token,
      })

      return locations
    } catch (error) {
      return rejectWithValue(JSON.stringify(error))
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState().location.list
      if (state?.status === 'finished' || state?.status === 'loading') {
        return false
      }
    },
  }
)
export default fetchLocations
