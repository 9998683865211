import PropTypes from 'prop-types'

import Select from '../../atoms/Select/Select'

const SelectWithChips = ({
  label,
  list,
  handleDeleteChip,
  handleAddChip,
  isallfilter,
  ...props
}) => {
  return (
    <Select
      isallfilter={isallfilter}
      labelId="select-mutiplkeye-chip-label"
      id="select-mutiple-chip"
      multiple
      label={label}
      list={list}
      handleDelete={handleDeleteChip}
      handleAdd={handleAddChip}
      {...props}
    />
  )
}
SelectWithChips.propTypes = {
  label: PropTypes.string,
  list: PropTypes.array,
  handleDeleteChip: PropTypes.func,
  handleAddChip: PropTypes.func,
  isallfilter: PropTypes.bool,
}
SelectWithChips.defaultProps = {
  list: [],
  isCheckbox: false,
  isallfilter: false,
}
export default SelectWithChips
