import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import IconButtonMUI from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { handleDrawerOpen } from '../../../../domain/features/ui/drawerSlice'

const IconButton = styled(IconButtonMUI)`
  padding: 0;
  padding-right: 5px;
  color: ${({ theme }) => theme.palette.info.main};
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: #cccccc !important;
  }
`
const MenuButton = ({ ...props }) => {
  const dispatch = useDispatch()
  return (
    <IconButton
      color="inherit"
      aria-label="open-drawer"
      {...props}
      onClick={() => dispatch(handleDrawerOpen())}
    >
      <MenuIcon fontSize="large" />
    </IconButton>
  )
}

export default MenuButton
