import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTransition, animated, useSpring } from '@react-spring/web'

import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'

import sessionPersistentStore from '../../../../data/sessionPersistentStore'

import dateFormat from '../../../../domain/adapters/days/dateFormat'
import {
  removeAllAccountingStatement,
  removeOneAccountingStatement,
} from '../../../../domain/features/ui/accountingStatementSlice'
import {
  removeAllStatus,
  removeOneStatus,
} from '../../../../domain/features/ui/dispatchedSlice'
import { resetRangeOfDaysPayout } from '../../../../domain/features/ui/rangeOfDaysPayoutSlice'
import { resetRangeOfDays } from '../../../../domain/features/ui/rangeOfDaysSlice'
import {
  removeAllType,
  removeOneType,
} from '../../../../domain/features/ui/typeSlice'

import Chip from '../../atoms/Chip'
import {
  removeAllDocument,
  removeOneDocument,
} from '../../../../domain/features/ui/documentSlice'

const StyledChip = styled(animated(Chip))`
  display: inline-flex;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme?.palette?.background?.default};
  border-color: ${({ theme }) => theme?.palette?.primary?.main};
`

const Content = styled('div')`
  display: flex;
  align-items: baseline;
  padding: 1rem;
  padding-top: 10px;
`

const DeleteAll = styled(animated(Button))`
  text-decoration: underline;
  color: ${({ theme }) => theme?.palette?.secondary?.main};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
`

const ChipManager = ({
  date,
  payoutDate,
  type,
  status,
  accountingStatement,
  category,
  brand,
  productStatus,
  document,
  setBrand,
  setStatus,
  setCategory,
}) => {
  const dispatch = useDispatch()
  const [chips, setChips] = useState([])

  const deleteAll = () => {
    removeDate()
    removePayoutDate()
    removeAllAccountingStatementAction()
    removeAllDocumentAction()
    removeAllStatusAction()
    removeAllTypeAction()
    removeAllBrand()
    removeAllCategory()
    removeAllProductStatus()
    setChips([])
  }
  const addChips = (name, label, deleteAction) => {
    setChips(current => [
      ...current,
      {
        key: current.length + 1,
        label: `${name}: ${label}`,
        onDelete: deleteAction,
        value: label,
      },
    ])
  }
  const onDelete = chipToDelete => () => {
    setChips(chips => chips.filter(chip => chip.key !== chipToDelete.key))
    chipToDelete.onDelete()
  }

  const removeDate = () => {
    dispatch(resetRangeOfDays())
  }
  const removeType = type => () => {
    dispatch(removeOneType(type))
  }

  const removeStatus = status => () => {
    dispatch(removeOneStatus(status))
  }

  const removeAccountingStatement = status => () => {
    dispatch(removeOneAccountingStatement(status))
  }

  const removeDocument = status => () => {
    dispatch(removeOneDocument(status))
  }
  const removeAllTypeAction = () => {
    dispatch(removeAllType())
  }

  const removeAllStatusAction = () => {
    dispatch(removeAllStatus())
  }

  const removeAllAccountingStatementAction = () => {
    dispatch(removeAllAccountingStatement())
  }
  const removeAllDocumentAction = () => {
    dispatch(removeAllDocument())
  }
  const removePayoutDate = () => {
    dispatch(resetRangeOfDaysPayout())
  }

  const removeBrand = br => () => {
    setBrand(brands => brands.filter(b => br !== b))
  }
  const removeCategory = ca => () => {
    setCategory(cat => cat.filter(c => ca !== c))
  }
  const removeProductStatus = st => () => {
    setStatus(sta => sta.filter(s => st !== s))
  }

  const removeAllBrand = () => {
    setBrand ? setBrand([]) : null
    sessionPersistentStore.setBrandSelect([])
  }
  const removeAllCategory = () => {
    setCategory ? setCategory([]) : null
    sessionPersistentStore.setCategorySelect([])
  }
  const removeAllProductStatus = () => {
    setStatus ? setStatus([]) : null
    sessionPersistentStore.setStateFilter([])
  }
  useEffect(() => {
    setChips([])
    if (date?.length > 0 && date[0] !== undefined)
      addChips(
        'Fecha',
        `${dateFormat(date[0])} - ${dateFormat(date[1])}`,
        removeDate
      )
    type?.map(t => addChips('Tipo', t, removeType(t)))
    status?.map(s => addChips('Estado despacho', s, removeStatus(s)))
    accountingStatement?.map(s =>
      addChips('Estado contable', s, removeAccountingStatement(s))
    )
    if (payoutDate?.length > 0 && payoutDate[0] !== undefined)
      addChips(
        'Fecha Liquidación',
        `${dateFormat(payoutDate[0])} - ${dateFormat(payoutDate[1])}`,
        removePayoutDate
      )

    document?.map(s =>
      addChips('Documento tributario', s, removeDocument(s))
    )
    productStatus?.map(s => addChips('Estado', s, removeProductStatus(s)))

    brand?.map(s => addChips('Marca', s, removeBrand(s)))
    category?.map(s => addChips('Categoría', s, removeCategory(s)))
    // eslint-disable-next-line
  }, [
    date,
    type,
    status,
    accountingStatement,
    payoutDate,
    category,
    brand,
    productStatus,
    document,
  ])

  const transitions = useTransition(chips, {
    key: ({ label }) => label,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500, tension: 220, friction: 120 },
    exitBeforeEnter: true,
    native: true,
  })

  const fade = useSpring({
    to: { opacity: isEmpty(chips) ? 0 : 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
    delay: 400,
    native: true,
  })

  return (
    <Collapse in={!isEmpty(chips)}>
      <Content>
        {transitions((springs, item, _, index) => (
          <StyledChip
            key={`${item?.label}-${index}`}
            label={item?.label}
            onDelete={onDelete(item)}
            style={springs}
          />
        ))}
        <DeleteAll onClick={deleteAll} style={fade}>
          Borrar Filtros
        </DeleteAll>
      </Content>
    </Collapse>
  )
}
ChipManager.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.date),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.any),
  ]),
  payoutDate: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.date),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.any),
  ]),
  type: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.arrayOf(PropTypes.string),
  accountingStatement: PropTypes.arrayOf(PropTypes.string),
  category: PropTypes.arrayOf(PropTypes.string),
  brand: PropTypes.arrayOf(PropTypes.string),
  productStatus: PropTypes.arrayOf(PropTypes.string),
  document: PropTypes.arrayOf(PropTypes.string),
  setBrand: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  setStatus: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  setCategory: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
}
export default ChipManager
