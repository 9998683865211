import persistentStore from '../../data/persistentStore'
import useInterval from './useInterval'
import auth from '../../data/firebase/auth'

const useRefreshToken = () => {
  useInterval(async () => {
    const token = await auth.currentUser.getIdToken(true)
    persistentStore.setToken(token)
  }, 600000)
}

export default useRefreshToken
