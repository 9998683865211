/* eslint-disable linebreak-style */
import PropTypes from 'prop-types'

import Logo from '../../atoms/Logo'
import Carousel from '../../molecules/Carousel/Carousel'
import {
  Center,
  Container,
  DescriptionText,
  LeftContainer,
  LogoContainer,
  OnboardingImage,
  RightContainer,
  StyledButton,
  TitleText,
} from './onboarding.styles'
const OnboardingImg = require('../../../assets/onboarding/onboarding3.png')

const Onboarding3 = ({ navigate }) => {
  const handleNavigate = () => {
    navigate('/onboarding/4')
  }
  return (
    <Center>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Container>
        <LeftContainer>
          <OnboardingImage
            src={OnboardingImg}
            alt="onboarding2"
          />
        </LeftContainer>
        <RightContainer>
          <TitleText>Mis productos</TitleText>
          <DescriptionText>
            Puedes visualizar y gestionar tus productos y agregar nuevos a
            través de la ventana "Productos”.
          </DescriptionText>
        </RightContainer>
      </Container>
      <StyledButton variant="contained" onClick={handleNavigate}>
        continuar
      </StyledButton>
      <Carousel />
    </Center>
  )
}

Onboarding3.propTypes = {
  navigate: PropTypes.func,
}

export default Onboarding3
