import sessionStore from '../../../data/sessionPersistentStore'
import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initDates = [
  dayjs(new Date()).startOf('month').toISOString(),
  dayjs(new Date()).toISOString(),
]

const initialState = {
  dates: sessionStore.isEmptyRangeDate()
    ? initDates
    : sessionStore.getRangeDate(),
}

const rangeOfDaysSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    addStartDay(state, { payload }) {
      const [, end] = state.dates
      state.dates = [dayjs(payload).toISOString(), end]
    },
    addEndDay(state, { payload }) {
      const [start] = state.dates
      state.dates = [
        start,
        payload ? dayjs(payload).endOf('day').toISOString() : null,
      ]
    },
    addRangeOfDays(state, { payload }) {
      sessionStore.setRangeDate(payload)
      state.dates = payload.map(date =>
        date ? dayjs(date).toISOString() : null
      )
    },
    resetRangeOfDays(state) {
      sessionStore.removeRangeDate()
      state.dates = [undefined, undefined]
    },
  },
})

export const { addRangeOfDays, addStartDay, addEndDay, resetRangeOfDays } =
  rangeOfDaysSlice.actions

const getDaySelector = state => state.ui.rangeOfDay

export const getCurrentDate = createSelector([getDaySelector], state =>
  state.dates?.map(date => (date ? new Date(date) : undefined))
)

const reducer = rangeOfDaysSlice.reducer
export default reducer
