import { createSelector } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const getFirebaseAuth = state => state.auth

export const getAuth = createSelector([getFirebaseAuth], auth => auth)

export const useAuth = () => {
  const user = useSelector(getAuth)

  return useMemo(() => ({ ...user }), [user])
}
