import { bool, func } from 'prop-types'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Input from '../../atoms/Input'

const height = '42px'
const Search = styled(Input)`
  margin-right: 1rem;
  display: flex;
  flex: 1;
  .MuiOutlinedInput-root {
    padding: 0;
    max-width: 639px;
  }
  .MuiInputBase-input {
    padding-right: 12px;
    padding-left: 19px;
    padding-bottom: 0px;
    padding-top: 0px;
    height: ${height};
    color: black;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    cursor: pointer;
    font-size: 1.2em;
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-size: 10px 10px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACtSURBVHgBbZBBCsIwEEVnpl5NVETsNUS6qNB1pmuhIqV4DbuQUtzYE3ii/pgIhRDyIWFC3vz5DDf37ksiz0txqimhpu0MQLnMQG8BbdqHSUEWpO702XscPuvNjplIt/sDjcNrCiHnVlflWXnpvt5aFRHDIkoECiH/z+GoBfZ1CHlJCGYrsan6/46DeydrMcWZORU8yuzXplkK8gq2YdjdAtg8hhZVZeEMUGPG8QcpyXjHSYAGSAAAAABJRU5ErkJggg==');
  }

  @media (max-width: 700px) {
    max-width: auto;
    min-width: 100%;
    width: auto;
    margin-bottom: 1.5rem;
  }
`

const IconButton = styled(Button)`
  height: 36px;
  width: 36px;
  padding: 0px;
  margin: 0px;
  border-radius: 5px;
  min-width: 48px;
  ${Search}:hover & {
    transition: border-left-color 10ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-left-color: #313234;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
  }
`

const SearchIconStyled = styled(SearchIcon)`
  fill: #8d9195;
  ${IconButton}:hover & {
    fill: #313234;
  }
`

const SearchInput = ({ isLoading, onClick, ...props }) => (
  <Search
    {...props}
    fullWidth
    type="search"
    id="search"
    placeholder="Buscar producto"
    aria-label="buscar producto por su titulo"
    InputProps={{
      startAdornment: (
        <InputAdornment position="end" style={{ margin: 0 }}>
          <IconButton
            data-testid="SearchInput__IconButton"
            onClick={onClick}
          >
            {isLoading ? (
              <CircularProgress
                size="1.2rem"
                alt="loading"
                data-testid="SearchInput__CircularProgress--loading"
              />
            ) : (
              <SearchIconStyled
                alt="search"
                aria-label="buscar producto por su titulo"
              />
            )}
          </IconButton>
        </InputAdornment>
      ),
    }}
    onKeyDown={event => {
      if (event.key === 'Enter') {
        onClick()
      }
    }}
  />
)
SearchInput.propTypes = {
  isLoading: bool,
  onClick: func,
}

export default SearchInput
