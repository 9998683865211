import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import reducers from './reducers'

export const store = configureStore({
  reducer: reducers,

  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: true,
})

setupListeners(store.dispatch)

if (window.Cypress) {
  window.store = store
}
