import axios from 'axios'

import persistentStore from '../persistentStore'
import auth from '../firebase/auth'

const request = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    app: process.env.REACT_APP_APPLICATION_NAME || 'sellerCenter',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
})

export const requestFile = axios.create({
  headers: {
    app: process.env.REACT_APP_APPLICATION_NAME,
  },
})

requestFile.interceptors.request.use(async function (config) {
  const token = await auth.currentUser.getIdToken(true)
  config.headers.authorization = token

  persistentStore.setToken(token)

  return config
})

request.interceptors.request.use(async function (config) {
  const token = await auth.currentUser.getIdToken(true)
  config.headers.authorization = token

  persistentStore.setToken(token)

  return config
})

export default request
